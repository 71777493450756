import { Injectable } from '@angular/core';
import {Marca, Product, TipoProducto} from './product.service';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";

// export interface Cart {
//   cantidad: number;
//   idProduct: number;
//   variacion?: string;
// }
export interface Cart {
  id: string;
  tipo?: TipoProducto;
  marca?: Marca;
  imagen?: string;
  variante?: string;
  cantidad?: number;
  producto?: Product;
}
export interface Tipo {
  id: number;
  tipo: string;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // @ts-ignore
  private _infoCart$: BehaviorSubject<number> = new BehaviorSubject((new Date().getTime()));

  get infoCart$(): BehaviorSubject<number> {
    return this._infoCart$;
  }

  set infoCart$(value: BehaviorSubject<number>) {
    this._infoCart$ = value;
  }

  newInfoCart(): void {
    const now = (new Date().getTime());
    this.setItem('cartDate', now);
    this.infoCart$.next(now);
  }

  checkLastCarrito(): void{
    const date = this.getItem('cartDate');
    const cleanCart = new Date(environment.cleanCart);
    if(!date || date < cleanCart) {
      this.removeItem('carrito');
    }
    this.newInfoCart();
  }

  constructor() { }

  getCartStorage(): Array<Cart>{
    let cartStorage: Array<Cart> = [];
    cartStorage =  this.getItem('carrito');
    if (cartStorage === null) {
      cartStorage = [];
    }
    return cartStorage;
  }

  setCarritoStorage(carrito): void {
    this.setItem('carrito', carrito);
  }

  setPurchaseOrder(Order): void {
    this.setItem('order', Order);
  }

  getPurchaseOrder(){
    let purchaseOrderStorage: Array<any> = [];
    purchaseOrderStorage =  JSON.parse(localStorage.getItem('order'));
    if (purchaseOrderStorage === null) {
      purchaseOrderStorage = [];
    }
    return purchaseOrderStorage;
  }

  removeItem(item: string){
    localStorage.removeItem(item);
  }

  setItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

}
