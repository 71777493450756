import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { apiBaseUrl } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        
        if (this.shouldIntercept(req)) {
            request = this.cloneRequest(req);
        }

        return next.handle(request).pipe(catchError((err: any) => {
            if (err.status === 401) {

                this.authService.cleanUser();
                this.router.navigate(['/auth/sign-in']);

                return throwError(err);
            } else {
                return throwError(err);
            }
        })
        );
    }

    private cloneRequest(req: HttpRequest<any>): HttpRequest<any> {
        if (this.authService && this.authService.getIdToken()) {
            const token = this.authService.getIdToken();
            
            return req.clone({
                setHeaders: {
                    authorization: token,
                }
            });
        }
        return req;
    }

    /**
     * Determinates if is necessary to intercept the request
     */
    private shouldIntercept(req: HttpRequest<any>): boolean {
        const api = apiBaseUrl;
        
        return req.url.indexOf(api) !== -1;
    }

}
