<div class="modal-content card gold-border bg-dark">
  <ng-container *ngIf="course">
    <div class="modal-header d-block">
      <h5 class="modal-title" [innerText]="course.nombre"></h5>
      <p class="m-0 text-primary">Ordenar Imágenes.</p>
    </div>
    <div class="modal-body" *ngIf="items">

      <ul dndDropzone (dndDragover)="onDragover($event)" [dndHorizontal]="true"
          (dndDrop)="onDrop($event, items)" class="images">


        <li dndPlaceholderRef class="dndPlaceholder"></li>
        <li [dndDraggable]="img" *ngFor="let img of items; let idx = index" class="box-image"
            [dndEffectAllowed]="img.effectAllowed" (dndMoved)="onDraggableMoved($event, items, img)"
            (dndEnd)="onDragEnd($event, items)">
          <div dndDragImageRef>
            <img [src]="img.content.realpath" width="150" height="150">
          </div>
        </li>

      </ul>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancelar</button>
      <button type="button" class="btn btn-outline-primary" (click)="save()">Guardar</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!course">
    <p class="mb-0 py-4" style="text-align: center">Cargando..</p>
  </ng-container>
</div>
