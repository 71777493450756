import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Curso} from '../../form/form-my-courses/interface-my-courses';

@Component({
  selector: 'app-course-videos',
  templateUrl: './course-videos.component.html',
  styleUrls: ['./course-videos.component.scss']
})
export class CourseVideosComponent implements OnInit, OnChanges {

  @Input() curso: Curso;
  showLoading = false;
  videos: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.videos = this.curso ? this.curso.videos?.trim().split(',').filter(x => x !== '') : [];
  }
  onLoad(): void {
    this.showLoading = false;
  }

}
