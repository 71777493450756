import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../services/product.service';
import { Seller } from '../../cart/main/cart-main.component';
import { Router } from '@angular/router';
import { debuglog } from 'util';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../auth/auth.service';
import {SendWhatsppConfig} from "../send-whatsapp/send-whatsapp.component";
import {Usuario} from "../../services/auth.service";
import {StorageService} from "../../services/storage.service";
import {Province} from "../../form/form.service";
import {getUserInfoWhatsapp} from "../utils";
import {ShoppingCartService} from "../../services/shopping-cart.service";

@Component({
  selector: 'app-seller-card',
  templateUrl: './seller-card.component.html',
  styleUrls: ['./seller-card.component.scss']
})
export class SellerCardComponent implements OnInit {


  @Input() provincias: Province[] = [];
  @Input() seller: Seller;
  @Output() savePressed: EventEmitter<any> = new EventEmitter();

  closeResult = '';
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private shoppingCartService: ShoppingCartService,
    ) { }

  currentUser?: Usuario;
  ngOnInit(): void {
    this.currentUser = this.authService.loadStorage('userProfile');
  }


  sendBuy(content, contentModalLogin, contentModalEditData): void{

    const idToken = this.authService.loadStorage('idToken');
    const userProfile = this.authService.loadStorage('userProfile');
    if (idToken){
      this.open(userProfile.comprador ? content : contentModalEditData);
    }else{
      this.open(contentModalLogin);
    }
  }

  open(content): void{
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title' });
  }

  closeModal(): void{
    this.modalService.dismissAll();
  }

  sendPurchase(): void{
    this.closeModal();
    this.savePressed.emit(this.seller);
  }

  goToProfile(): void{
    this.closeModal();
    this.router.navigate(['profile'], { queryParams: { url: 'cart'}});
  }

  goToLogin(): void{
    this.closeModal();
    this.router.navigate(['auth/sign-in'], { queryParams: { url: 'cart'  }});
  }

  goToRegister(): void{
    this.closeModal();
    this.router.navigate(['auth/sign-up/customer'], { queryParams: { url: 'cart' }});
  }


  getConfigWhatsapp(): SendWhatsppConfig {
    const message = this.getWhatsappMessage();

    return {
      title: 'Atención',
      css: 'w-100',
      description: 'Abriremos la aplicación de Whatsapp para que pueda contactarse directamente con ' + this.seller.name,
      message,
      phone: this.seller.phone,
      callback: this.sendCart.bind(this)
    } as SendWhatsppConfig
  }

  private getWhatsappMessage(): string {

    const products = this.storageService.getCartStorage();
    let total = 0;
    let message = `Hola ${this.seller.name}, te contacto desde Todo-Odonto para consultarte por un carrito en el que estoy interesado y me gustaría que me pases los precios actualizados:  \n`;

    let showTotal = true;
    const consultar = '(Queria saber si vende este producto) \n';

    products.sort((a,b) => {
      return (this.getMyPrice(a.producto) && !this.getMyPrice(b.producto)) ? -1 : 1
    })


    products.forEach((x) => {
      message += `✅ ${x.cantidad} x ${x.producto.nombre}\n`;
      const hasPrice = this.getMyPrice(x.producto);
      if (x.variante) {
        message += `(${x.variante})\n`;
      }

      if(hasPrice?.[0]) {
        total += hasPrice[0];
        message += `${x.cantidad} x $${hasPrice[0]} ${hasPrice[1].comentario || ''}\n`;
      } else {
        showTotal = false;
        message += consultar;
      }

      message += '\n'
    })


    if(showTotal) {
      message += `➡ ️TOTAL: $${total}\n\n`;
    }

    if(this.currentUser) {
      message += getUserInfoWhatsapp(this.currentUser, this.provincias);
    }

    message += '\n\nAguardo su respuesta\n¡Muchas Gracias!'

    return message;
  }

  private getMyPrice(product: Product) {
    const vp =  product.vendedores?.find((x: any) => x.id === this.seller.idSeller) as any;
    if(vp) {
      const p = (vp.oferta && vp.oferta > 0) ? vp.oferta : vp.precio;
      return [p, vp];
    }
    return null;
  }


  async sendCart(): Promise<void> {
    const message = this.getWhatsappMessage();
    try {
      let items = this.storageService.getCartStorage();
      const cart = {
        vendedor: this.seller.name,
        whatsapp: this.seller.phone,
        user: {
          id: this.currentUser.id,
          nombre: this.currentUser.nombre,
          apellido: this.currentUser.apellido,
          telefono: this.currentUser.telefono,
          email: this.currentUser.email
        },
        message: message,
        web: 'TODO-ODONTO',
        items: items.map((x) => {
          const hasPrice = this.getMyPrice(x.producto);
          return {
            id: x.producto.id,
            producto: x.producto.nombre,
            tipoProducto: x.producto.tipoProducto?.nombre,
            marca: x.producto.marca?.nombre,
            cantidad: x.producto,
            precio: hasPrice[1].precio,
            oferta: hasPrice[1].oferta,
            comentario: hasPrice[1].comentario,
            variante: x.producto?.toString(),
          }
        })
      }

      const r = await this.shoppingCartService.saveCart(this.seller.idSeller, cart).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

}
