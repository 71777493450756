import {Component, OnInit} from '@angular/core';
import {StorageService} from '../services/storage.service';
import {NotificationsService} from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[];

  constructor(
    private notificationsService: NotificationsService,
    private storageService: StorageService,
  ) {
    this.notifications = [];
  }

  ngOnInit(): void {

  }

}
