import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CurseService} from '../../services/curse.service';
import {ToastrService} from 'ngx-toastr';
import {baseUrlPublic, environment} from '../../../environments/environment';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DATE_FORMAT_COURSE, formatDate} from '../../shared/utils/date.utils';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {AuthService} from '../../auth/auth.service';
import {COMMON} from '../../shared/utils/common-texts.utils';
import {ModalSharedComponent} from '../../shared/modal-shared/modal-shared.component';
import {Imagen, Product} from '../../services/product.service';
import {Location} from "@angular/common";
import {SendWhatsppConfig} from "../../shared/send-whatsapp/send-whatsapp.component";
import {getUserInfoWhatsapp} from "../../shared/utils";
import {Usuario} from "../../services/auth.service";
import {FormService, Province} from "../../form/form.service";
import {ExternalLinks} from "../../services/external-links.service";

@Component({
  selector: 'app-detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss']
})
export class DetailCourseComponent implements OnInit {
  idCourse: string;
  idProvincia: string;
  isVisible: boolean;
  course: any;
  relateds: any[];
  links: ExternalLinks[];
  featured: any;
  questions: any;

  defaultNoImage = environment.defaultNoImage;
  apiKey = environment.firebaseConfig.apiKey;
  calificaciones: any[];
  calificacion = COMMON.calificaciones.calificaciones;
  toShared: string;
  imagen: string;
  imagenes: Array<Imagen>;


  whatsappConfig: SendWhatsppConfig;


  currentUser?: Usuario;
  provincias: Province[];

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastrService,
    private router: Router, private authService: AuthService,
    private shoppingCartService: ShoppingCartService,
    private modalService: NgbModal,
    private formService: FormService,
    private curseService: CurseService) {

    this.activatedRoute.params.subscribe((params: any) => {
      this.idCourse = parseInt(params.idCourse, 0)?.toString();
      if (this.idCourse) {
        this.loading();
      }
    });

    this.getProvinces();
  }
  getProvinces(): void{
    this.formService.getProvinces().subscribe((resp: Array<Province>) => {
      this.provincias = resp;
    });
  }

  ngOnInit(): void {
    this.currentUser = this.authService.loadStorage('userProfile');
  }

  loading(): void {
    this.imagenes = [];
    this.imagen = '';
    this.curseService.getCourseById(this.idCourse).subscribe(
      (response: any) => {
        if (response) {
          console.log(response.curso);
          this.course = response.curso;
          this.relateds = this.course.productos;
          this.links = response.links;
          if (this.course.imagenes && this.course.imagenes.length) {
            this.course.imagenes.map((item: any) => {
              this.imagenes.push({
                selected: false,
                urlImg: item.realpath
              });
              this.imagenes[0].selected = true;
              this.imagen = this.imagenes[0].urlImg;
            });
          }
          this.toShared = 'Mirá el curso ' + this.course.nombre + ' en Todo-Odonto ' + baseUrlPublic + 'courses/' + this.course.slug;
          this.featured = response.destacados;
          this.questions = response.preguntas;
          this.calificaciones = response.calificaciones;

          this.whatsappConfig = {
            css: 'w-100',
            title: 'Atención',
            description: 'Abriremos la aplicación de Whatsapp para que pueda contactarse directamente con ' + this.course.vendedor.razonSocial,
            message: this.getWhatsappMessage(),
            phone: this.course.vendedor.telefono,
            callback: this.sendCart.bind(this)
          }

        }
      }, (error: HttpErrorResponse) => {
        this.toastService.error(`${COMMON.curso.mensaje_curso_no_encontrado}`, `${COMMON.curso.titulo_curso_no_encontrado}`);
      }
    );
  }

  async sendCart(): Promise<void> {
    const message = this.getWhatsappMessage();
    try {
      const cart = {
          vendedor: this.course.vendedor.razonSocial,
          whatsapp: this.course.vendedor.telefono,
          user: null,
          message: message,
          web: 'TODO-ODONTO',
          curso: {
            id: this.course.id,
            nombre: this.course.nombre,
            cantidad: 1,
            precio: this.course.precio,
          }
        };
      if(this.currentUser) {
        cart.user = {
          id: this.currentUser.id,
          nombre: this.currentUser.nombre,
          apellido: this.currentUser.apellido,
          telefono: this.currentUser.telefono,
          email: this.currentUser.email
        };
      }
      const r = await this.shoppingCartService.saveCart(this.course.vendedor.id, cart).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  openSharedModal(): void {
    const modalRef = this.modalService.open(ModalSharedComponent);
    modalRef.componentInstance.toShared = this.toShared;
  }


  sendBuy(content, contentModalLogin, contentModalEditData): void {
    const idToken = this.authService.loadStorage('idToken');
    const userProfile = this.authService.loadStorage('userProfile');
    // papelon a corregir con backend

    if (idToken) {
      this.open(userProfile.comprador ? content : contentModalEditData);
    } else {
      this.open(contentModalLogin);
    }
  }

  selectImage(imagen: Imagen): void {
    this.imagenes.forEach((img: Imagen) => {
      img.selected = false;
    });
    imagen.selected = true;
    this.imagen = imagen.urlImg;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  sendPurchase(): void {
    const userProfile = this.authService.loadStorage('userProfile');
    // enviar orden ir a pagina de envio exitos
    const course = {
      cursoId: this.course.id,
      cant: 1
    };

    const params = {
      vendedor: this.course.vendedor.id,
      orden: [course],
      idProvincia: this.idProvincia = userProfile ? userProfile.idProvinciaCliente : 45,
    };

    this.shoppingCartService.orderCourse(params).subscribe((response: any) => {
      this.modalService.dismissAll();
      this.router.navigate(['cart/thank-purchase'], {queryParams: {typePurchase: 'course'}});
    }, (error: HttpErrorResponse) => {
      this.toastService.error(`${COMMON.curso.mensaje_gurdado_fallido}`, `${COMMON.curso.titulo_guardado_fallido}`);

    });
  }

  goToProfile(): void {
    this.closeModal();
    this.router.navigate(['profile'], {queryParams: {url: 'courses/' + this.idCourse}});
  }

  goToLogin(): void {
    this.closeModal();
    this.router.navigate(['auth/sign-in'], {queryParams: {url: 'courses/' + this.idCourse}});
  }

  goToRegister(): void {
    this.closeModal();
    this.router.navigate(['auth/sign-up/customer'], {queryParams: {url: 'courses/' + this.idCourse}});
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  navigateBack(): void {
    this.location.back();
  }


  private getWhatsappMessage(): string {
    let message = `Hola ${this.course.vendedor.razonSocial}, te contacto desde Todo-Odonto para consultarte por el curso \n\n`;
    message += `➡ *${this.course.nombre}* `;
    if(this.course.dictantes) {
      message += `con la participación de ${this.course.dictantes}\n\n`;
    }

    if(this.currentUser) {
      message += getUserInfoWhatsapp(this.currentUser, this.provincias, false);
    }

    message += '\n\nAguardo su respuesta\n¡Muchas Gracias!'

    return message;
  }
}
