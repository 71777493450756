import {Component, Input, OnInit} from '@angular/core';
import {Faqs, FaqService} from './faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Input() faqs: any[];

  constructor(private faqService: FaqService) {
  }

  ngOnInit(): void {
    if (!this.faqs) {
      this.faqService.getFaqs().subscribe((resp: Array<Faqs>) => {
        if (resp.length > 0) {
          this.faqs = resp.sort((a, b) => a.orden - b.orden);
        }
      });
    }
  }

}
