import {Injectable} from '@angular/core';
import {FirebaseApp} from '@angular/fire/app';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/compat';
import {arrayUnion, Firestore, Timestamp, where} from 'firebase/firestore';
import {map, timestamp} from 'rxjs/operators';


export interface ChatMessage {
  chatConversationId?: string;
  senderId: number;
  message: string;
  time: Timestamp;
  leido: boolean;
  attachmentFileName?: string;
  notificado: boolean;
}

export interface ChatConversation {
  id?: string;
  senderId: number;
  senderNameSurname: string;
  senderImage: string;
  receiverId: number;
  receiverNameSurname: string;
  receiverImage: string;
  orderNumber: number;
  chatMessages?: ChatMessage[];
  latestMessage?: ChatMessage;
  time: Timestamp;
  users: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chatMessagesCollectionPath: string;
  chatsCollectionPath: string;
  currentChatConversationId: string;
  orderNumber: number;
  senderId: string;
  receiverId: string;

  constructor(private db: AngularFirestore) {
    this.chatMessagesCollectionPath = 'chatMessages';
    this.chatsCollectionPath = 'chats';
  }

  // Starts a new chat conversation
  async newChatConversation(chat: ChatConversation): Promise<any> {
    const {chatMessages, ...chatConversation} = chat;
    const id = this.db.createId();

    this.currentChatConversationId = id;

    return this.db.doc(`${this.chatsCollectionPath}/${id}`).set(chatConversation);
  }

  // Gets chatConversation by chatConversationId
  getChatConversationById(id: string): any {
    return this.db.doc<ChatConversation>(`${this.chatsCollectionPath}/${id}`).get();
  }

  // Gets chatMessages by chatConversationId
  getChatMessagesById(id: string): any{
    return this.db.collection<ChatMessage>(this.chatMessagesCollectionPath, ref => ref.where('chatConversationId', '==', id)).get();
  }

  // Gets all the user's conversations (used for sidebar)
  getChatConversationsByUserId(userId: number): any {
    const chatsCollection = this.db.collection<ChatConversation>(this.chatsCollectionPath, (ref) =>
      ref.where('users', 'array-contains', userId)
      .orderBy('time', 'desc'));

    return chatsCollection.snapshotChanges(['added', 'modified']);
  }

  // Gets all chatMessages for a chatConversation (used for displaying the current chat conversation)
  getChatsMessagesByChatConversationId(chatConversationId: string): any {
    const chatsMessagesCollection = this.db.collection(this.chatMessagesCollectionPath, ref =>
      ref.where('chatConversationId', '==', chatConversationId));

    return chatsMessagesCollection.snapshotChanges();
  }

  markAsReadLastMessage(chatConversationId: string, latestMessage: any): void {
    const chatConversationDoc = this.db.doc(`${this.chatsCollectionPath}/${chatConversationId}`);
    chatConversationDoc.update({latestMessage});
  }


  // Adds messages sent by user
  addChatMessage(chatMessage: ChatMessage): any {
    const emptyPromise = new Promise(() => {});
    const time = this.dateToTimestamp();
    const {chatConversationId, ...chatMessageData} = chatMessage;
    const chatConversationDoc = this.db.doc(`${this.chatsCollectionPath}/${chatConversationId}`);

    this.currentChatConversationId = chatConversationId;
    chatConversationDoc.get().subscribe((resp: any) => {
      if (resp.exists) {
        console.log(resp.data());

        const chatConversationResp: ChatConversation = resp.data();

        chatConversationResp.latestMessage = chatMessageData;
        chatConversationResp.time = time;
        console.log(chatConversationResp);

        chatConversationDoc.set(chatConversationResp, {merge: true}).then(() => {
          const chatMessageDoc = this.db.collection(this.chatMessagesCollectionPath, ref => ref.where('chatConversationId', '==', this.currentChatConversationId));

          return chatMessageDoc.get().subscribe((resp: any) => {

            if (!resp.empty) {
              const chatMessageResp = resp.docs.map(doc => {
                const id = doc.id;
                const data = doc.data();
                return {id, ...data};
              })[0];

              chatMessageResp.chatMessages ? chatMessageResp.chatMessages.push(chatMessageData) : chatMessageResp.chatMessages = [chatMessageData];
              console.log(chatMessageResp);
              return this.db.doc(`${this.chatMessagesCollectionPath}/${chatMessageResp.id}`).set(chatMessageResp, {merge: true});
            } else {
              const chatMessageDoc = {
                chatConversationId: this.currentChatConversationId,
                chatMessages: [chatMessageData]
              }
              return this.db.collection(this.chatMessagesCollectionPath).add(chatMessageDoc);
            }
          });
        })
      } else return emptyPromise;
    });
  }

  dateToTimestamp(date?: Date) {
    return date && date instanceof Date ? Timestamp.fromDate(date) : Timestamp.fromDate(new Date());
  }

  getDateTime(timestamp: Timestamp) {
    const dateToday = new Date();
    const chatDate = timestamp.toDate();
    const timeDifference = dateToday.getTime() - chatDate.getTime();
    const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference > 0 ? `${chatDate.getDate()}/${chatDate.getMonth() + 1}/${chatDate.getFullYear()}` : `${chatDate.getHours()}:${chatDate.getMinutes().toString().padEnd(2, '0')}`;
  }

  // Returns the Conversation Id
  getCurrentChatConversationId(): string {
    return this.currentChatConversationId;
  }

}

