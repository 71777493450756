<div class="modal-header">
  <h4 class="modal-title">Agregar Link a Publicación</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group my-2">
      <label for="titulo">Título</label>
      <input id="titulo" formControlName="titulo" type="text" class="form-control" placeholder="Ingrese el título" />
      <div *ngIf="form.get('titulo')?.invalid && form.get('titulo')?.touched" class="text-danger">
        El título es obligatorio
      </div>
    </div>

    <div class="form-group my-2">
      <label for="link">Link</label>
      <input id="link" formControlName="link" type="text" class="form-control" placeholder="Ingrese el Link" />
      <div *ngIf="form.get('link')?.invalid && form.get('link')?.touched" class="text-danger">
        El Link es obligatorio
      </div>
    </div>


    <div class="form-group my-2">
      <label for="descripcion">Descripción</label>
      <textarea id="descripcion" formControlName="descripcion" class="form-control" placeholder="Descripción (opcional)"></textarea>
    </div>

    <div class="form-group my-2">
      <label for="imagen">Imagen</label>
      <br>
      <input accept="image/*" id="imagen" type="file" (change)="onFileChange($event)" class="form-control-file" />
      <div *ngIf="form.get('imagen')?.invalid && form.get('imagen')?.touched" class="text-danger">
        La imagen es obligatoria
      </div>
    </div>

    <p class="text-end">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid || loading">Guardar</button>
    </p>
  </form>
</div>
