import {Pipe, PipeTransform} from '@angular/core';
import {SafeHtml, DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML',
})
export class SafeHTMLPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(items): SafeHtml {
    if (items) {
      const styles = '<style>.safe *{ color: white } </style>';
      return this.sanitizer.bypassSecurityTrustHtml(styles + items);
    } else {
      return this.sanitizer.bypassSecurityTrustHtml('<div></div>');
    }
  }
}
