<div class="button-container"  *ngIf="categorias && categorias.length"
     (mousedown)="onMouseDown($event)"
     (mouseleave)="onMouseLeave()"
     (mouseup)="onMouseUp()"
     (mousemove)="onMouseMove($event)"
     #buttonContainer>
  <div class="buttons">
    <button
      *ngFor="let p of categorias"
      class="btn" (click)="getCategory(p)"
      [disabled]="!!p.disabled"
      [ngClass]="{'seleccionada': categoriaSeleccionada === p}"
    [innerText]="p.nombre">
    </button>

  </div>
</div>


