import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  userData = new BehaviorSubject({});
  isLoggedIn = new BehaviorSubject(false);

  constructor() {
    this.userData.next({});
    this.isLoggedIn.next(false);
  }

  // Custom prompt/alert with timeOut
  async presentTimeOutCustomPrompt(title: string, type: any, message: string, timeOut: number) {
    await Swal.fire({
      position: 'center',
      icon: type,
      title,
      text: message,
      showConfirmButton: false,
      timer: timeOut,
      background: '#0e1316',
      color: '#3494da',
    });
  }

  // Custom prompt/alert
  async presentCustomInputPrompt(title: string, type: any, message: string) {
    return await Swal.fire({
      position: 'center',
      icon: type,
      title,
      text: message,
      input: 'text',
      showConfirmButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ingresar en modo pirata? :D',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3494da',
      cancelButtonColor: '#3e3f3f',
      background: '#0e1316',
      color: '#3494da'
    });
  }

  presentLoading() {
    return Swal.fire({ title: 'Cargando', didOpen: () => Swal.showLoading()});
  }

  closeLoading() {
    Swal.close();
  }

}
