import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications.component';



@NgModule({
    declarations: [
        NotificationsComponent
    ],
    exports: [
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        NotificationsRoutingModule
    ]
})
export class NotificationsModule { }
