import {Component, OnInit} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {Router} from '@angular/router';
import {SidebarService} from "./sidebar.service";

import * as $ from 'jquery';
import {SharedService} from '../shared.service';
import {Subscription} from 'rxjs';
import {isMobile} from "../utils";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

  public menuItems: any[];
  private subscriptions: Subscription[];
  private showProfile: boolean;

  isMobile = isMobile();

  constructor(
    public sidebarservice: SidebarService,
    private sharedService: SharedService,
    private router: Router) {

    this.subscriptions = [];
    this.showProfile = false;

  }


  toggleSidebar(): void{
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if(this.isMobile) {
      $(".wrapper").addClass("nav-collapsed");
      // unpin sidebar when hovered
      $('.wrapper').removeClass('nav-collapsed');
      $('.sidebar-wrapper').unbind( 'hover');
      $(".sidebar-wrapper").hover(
        function () {
          $(".wrapper").addClass("sidebar-hovered");
        },
        function () {
          $(".wrapper").removeClass("sidebar-hovered");
        }
      );
    }

  }

  hiddeMenu(): void{
    if(this.isMobile) {

      if ($('.wrapper').hasClass('nav-collapsed')) {
        // unpin sidebar when hovered
        $('.wrapper').removeClass('nav-collapsed');
        $('.sidebar-wrapper').unbind('hover');
      }
    }
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }


  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    $.getScript('./assets/js/app-sidebar.js');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
