<div class="row">
<div class="menu">
  <ul ngbNav #nav="ngbNav" [activeId]="current" class="nav-tabs ">
    <ng-container *ngFor="let tab of tabs; index as i">
      <li [ngbNavItem]=i [ngClass]="{ 'active': current === i }" class="pd-l-10">
        <!-- (click)="onNavChange(m)" -->
        <a ngbNavLink (click)="tabClicked(i)">{{tab}}</a>
      </li>
    </ng-container>
  </ul>
</div>
</div>
