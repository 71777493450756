import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Categoria} from "../../services/product.service";

@Component({
  selector: 'app-button-scroll',
  templateUrl: './button-scroll.component.html',
  styleUrls: ['./button-scroll.component.scss']
})
export class ButtonScrollComponent implements OnInit {
  @ViewChild('buttonContainer') buttonContainer: ElementRef;

  private isMouseDown = false;
  private startX: number;
  private scrollLeft: number;
  categoriaSeleccionada: Categoria | null = null;

  @Output() addProdcutCart: EventEmitter<any> = new EventEmitter();
  @Input() categorias: Array<any>;

  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.pageX - this.buttonContainer.nativeElement.offsetLeft;
    this.scrollLeft = this.buttonContainer.nativeElement.scrollLeft;
  }

  onMouseLeave() {
    this.isMouseDown = false;
  }

  onMouseUp() {
    this.isMouseDown = false;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    event.preventDefault();
    const x = event.pageX - this.buttonContainer.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 3; // Ajusta la velocidad de desplazamiento
    this.buttonContainer.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  getCategory(p) {
    this.categoriaSeleccionada = p;
    this.addProdcutCart.emit(p);
  }

  setCategory(parametro: any) {
    this.categoriaSeleccionada = parametro;
  }

  ngOnInit(): void {
    /*
        if (this.categorias?.length > 0 && !this.categoriaSeleccionada) {
      this.categoriaSeleccionada = this.categorias[0];
    }
     */
  }
}

