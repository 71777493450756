import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-odonto-accordion',
  templateUrl: './odonto-accordion.component.html',
  styleUrls: ['./odonto-accordion.component.scss']
})
export class OdontoAccordionComponent implements OnInit {

  @Input() name: string;
  @Input() expanded = false;

  @Input() key = 'id';
  @Input() value = 'nombre';

  uuid: string;

  @Output() onSelect = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.uuid = Md5.hashStr(this.name + (new Date()).toString());
  }

  selected(item: any): void {
    this.onSelect.emit(item);
  }
}
