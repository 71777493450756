import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { apiBaseUrl } from 'src/environments/environment';
import { ApiService } from 'src/app/core/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Carousel {
  id: string;
  orden?: string;
  titulo: string;
  imagen: string;
  link?: string;
  externo?: boolean;
  descripcion?: string;
  visible?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  private url;

  constructor(private apiService: ApiService) {
    this.url = apiBaseUrl;
  }

  getBanners(): Observable<Array<Carousel>> {
    return this.apiService.get(this.url, '/odontologo/banners')
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  getBannersByType(type: string, params?: any): Observable<Array<Carousel>> {
    let path = `/odontologo/banners?tipo=${type}`;
    const seller = params?.seller;
    const text = params?.text;
    if(seller > 0) {
      path += `&vendedor=${seller}`;
    }
    if(text > 0) {
      path += `&texto=${seller}`;
    }
    return this.apiService.get(this.url, path)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }
}
