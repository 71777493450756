<ng-template #content>
  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title" [innerText]="title"></h5>
    </div>
    <div class="modal-body">
      <p [innerText]="description"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="close()" [innerText]="cancel"></button>
      <button type="button" class="btn btn-primary" (click)="confirm()" [innerText]="submit"></button>
    </div>
  </div>
</ng-template>
