import { Injectable } from '@angular/core';
import {getDownloadURL, listAll, ListResult, ref, uploadBytesResumable, UploadTask} from '@firebase/storage';
import { storage } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor() {}

  uploadFile(filePath: string, fileName: string, file: any): UploadTask {
    const storageRef = ref(storage, `${filePath}/${fileName}`);
    /* const metadata = {
      contentType: 'image/jpeg',
    }; */
    return uploadBytesResumable(storageRef, file);
  }

  downloadFile(filePath: string, fileName: string): Promise<string> {
    const storageRef = ref(storage, `${filePath}/${fileName}`);
    return getDownloadURL(storageRef);
  }

  getUploadedFileNames(filePath: string): Promise<ListResult> {
    // Create a reference under which you want to list
    const listRef = ref(storage, filePath);
    // Find all the prefixes and items.
    return listAll(listRef);
  }
}
