<div class="row pd-l-t" *ngIf="titulo">
  <h1> {{titulo}}</h1>
</div>

<app-loading *ngIf="spinner"></app-loading>

<ng-container *ngIf="!spinner">
  <app-alert *ngIf="!isVendedorAprobado" [showAlertSeller]=!isVendedorAprobado></app-alert>

  <ng-container *ngIf="isVendedor">
    <app-filter
      (searchFilter)="filter(content, $event)"
      (deleteChip)="deleteSelected(content, $event)"
      [marcas]="brands"
      [tipoProductos]="typesProducts"
      [categorias]="categorias"
      [chips]="chips"
      #chipsUpdate
    ></app-filter>
    <!--<div class="container">-->

    <div class="fixed-bottom floating-box">
      <button type="button" class="btn btn-primary px-5" [disabled]="!registerProductForm.valid"
              (click)="saveProduct()">
        Guardar Cambios
      </button>
    </div>


    <div class="row ">
      <div class="col-2 d-none d-md-block">
        <div class="card">
          <div *ngIf="chips && chips.length">
            <h6>{{filtros_seleccionados}}</h6>
            <div *ngFor="let chip of chips let i=index;" class="mr-b" title="{{chip.nombre}}">
              <h6 *ngIf="chip.keyType === 'tipoProducto'">{{chip.tipo}} {{seleccionado}}</h6>
              <h6 *ngIf="chip.keyType === 'marca'">{{chip.tipo}} {{seleccionada}}</h6>
              <div class="chip-filter" *ngIf="chip.keyType !== 'texto'">
                <span class="name">{{chip.nombre}}</span>
                <span class="closebtn" *ngIf="i == (chips.length-1) || chip.tipo === 'precio'"
                      (click)="deleteSelected(content, chip)"
                      onclick="this.parentElement.style.display='none'">×</span>
              </div>
            </div>
            <hr/>
          </div>
          <div class="card-body">
            <div class="mr-b">
              <div *ngIf="false" class="mb-4 mt-4">
                <div class="d-flex " (click)="selected(content, withPrices.key, withPrices)">
                  <input class="form-check-input check-size" type="checkbox" [value]="withPrices.id"/>
                  <span class="ms-1 font-12" [innerText]="withPrices.nombre|uppercase"></span>
                </div>
              </div>
            </div>

            <!-- Categorias -->
            <app-sidebar-collapse-filter
              *ngIf="categorias?.length"
              name="Categorías"
              [items]="categorias"
              (onSelect)="selected(content, 'categoria', $event)">
            </app-sidebar-collapse-filter>

            <!-- Tipo de productos -->
            <app-sidebar-collapse-filter
              *ngIf="typesProducts?.length"
              name="Tipo de Producto"
              [items]="typesProducts"
              (onSelect)="selected(content,'tipoProducto', $event)">
            </app-sidebar-collapse-filter>

            <!-- Marcas -->
            <app-sidebar-collapse-filter
              *ngIf="brands?.length"
              name="Marcas"
              [items]="brands"
              (onSelect)="selected(content,'marca', $event)">
            </app-sidebar-collapse-filter>

          </div>
        </div>
      </div>
      <div class="col-10">
        <div class="pt-4 col-12 px-1 button-header hidden-desktop">
          <div style="flex-direction: column">
            <p class="mb-0 ps-2">Aplicar aumento porcentual a los productos actualmente marcados como en venta.</p>
            <div class="d-flex">
              <input type="number" [(ngModel)]="increase" name="increase"
                     class="form-control mr-r-5 mr-l-10"
                     placeholder="% de Incremento"/>

              <select class="form-control" [(ngModel)]="increaseType">
                <option [ngValue]="undefined" disabled>Todos los productos</option>
                <option *ngFor="let c of tipoCotizaciones" [ngValue]="c" [innerText]="c.nombre"></option>
              </select>
              <button type="submit" class="btn btn-secondary px-5 mr-l-10" [disabled]="!increase || increase<1"
                      (click)="addIncrement(contentIncrement)">Aplicar
              </button>
            </div>

          </div>
          <div class="text-end">
            <!--
                        <button type="submit" class="btn btn-primary px-5" [disabled]="!registerProductForm.valid"
                    (click)="saveProduct()">Guardar
            </button>
-->
          </div>
        </div>
        <div class="col-12 increase-mobile hidden-mobile py-4">
          <p class="mb-0 ps-2">Aplicar aumento porcentual a los productos actualmente marcados como en venta.</p>
          <select class="form-control" [(ngModel)]="increaseType">
            <option [ngValue]="undefined" disabled>Todos los productos</option>
            <option *ngFor="let c of tipoCotizaciones" [ngValue]="c" [innerText]="c.nombre"></option>
          </select>

          <input type="number" [(ngModel)]="increase" name="increase"
                 class="form-control mr-r-5 mr-l-10"
                 placeholder="% de Incremento"/>

          <button type="submit" class="btn btn-secondary px-5 mr-l-10" [disabled]="increase<1"
                  (click)="addIncrement(contentIncrement)">Aplicar
          </button>
        </div>
        <form [formGroup]="registerProductForm" class="hidden-desktop">
          <div formArrayName="product">

            <ng-container *ngIf="products && products.length">
              <div class="card bg-dark mb-4 p-3 gold-border" [formGroupName]="pointIndex"
                   *ngFor="let item of getitems.controls; let idx = index; let pointIndex = index">
                <div class="card-body">
                  <div class="row ">
                    <div class="col col-12">
                      <div class="mt-2 mb-2 form-check form-switch" style="float: right">
                        <input class="form-check-input" type="checkbox" formControlName="enabled"
                               (change)="enabled(idx, item)"/>
                        <label
                          class="form-check-label">{{ item.value.enabled ? 'Vendo este producto' : 'No vendo o no tengo stock'}}</label>
                      </div>
                    </div>

                    <div class="col col-1" *ngIf="item.value.imagenMarca">
                      <a [href]="item.value.imagenMarca" target="_blank">
                        <img [src]="item.value.imagenMarca"
                             class="img-thumbnail mb-2 me-2" [alt]="item.value.name">
                      </a>
                    </div>
                    <div class="col col-1">
                      <a *ngIf="item.value.image" [href]="item.value.image" target="_blank">
                        <img [src]="item.value.image"
                             class="img-thumbnail mb-2 me-2" [alt]="item.value.name">
                      </a>
                    </div>

                    <div class="col col-4">

                      <h5 class="mt-0">{{ item.value.name }}</h5>
                      <p class="mb-0 font-13 text-secondary"
                         *ngIf="item.value.nombreMarca"> {{ item.value.nombreMarca }}</p>
                      <p class="mb-0 font-13 text-secondary"
                         *ngIf="item.value.tipoProducto"> {{ item.value.tipoProducto }}</p>


                      <div class="mt-2" [hidden]="!item.value.requerirMarca || !item.value.enabled">
                        <label class="form-label">¿Qué marca es?</label>
                        <input class="form-control" formControlName="marca"/>
                      </div>


                    </div>
                    <div class="col col-6" [hidden]="!item.value.enabled">
                      <div class="row">
                        <div *ngIf="item.value.variantes && item.value.variantes?.length > 0 && item.value.enabled"
                             class="col col-12 mt-2 mb-2">
                          <label class="form-label">Variantes</label>
                          <ng-multiselect-dropdown
                            [placeholder]="'Seleccione las variantes'"
                            formControlName="variantesSelect"
                            [settings]="dropdownSettings"
                            [data]="item.value.variantes">
                          </ng-multiselect-dropdown>
                        </div>

                        <ng-container formGroupName="cotizacion">
                          <div class="col col-4">
                            <label class="form-label">Moneda</label>
                            <div class="input-group">
                              <select class="form-control" formControlName="cotizacion" (change)="setValorFinal(item)">
                                <option *ngFor="let c of cotizaciones" [value]="c.id"
                                        [innerText]="c.tipoCotizacion?.nombre"></option>
                              </select>
                            </div>
                          </div>

                          <div class="col col-4">
                            <label class="form-label">Precio</label>
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input type="number" class="form-control border-start-0"
                                     [placeholder]="getTipoCotizacionItem(item)"
                                     formControlName="precio" (blur)="setValorFinal(item)"/>
                            </div>
                            <span
                              class="outStock"
                              *ngIf="(item.get('cotizacion').get('precio').touched ||
                            item.get('cotizacion').get('precio').dirty) && item.get('cotizacion').get('precio').errors">
                            Este campo es requerido
                          </span>
                          </div>
                          <div class="col col-4">
                            <label class="form-label">¿En Oferta?</label>
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input type="number" class="form-control border-start-0" formControlName="oferta"
                                     [placeholder]="getTipoCotizacionItem(item)"
                                     (blur)="setValorFinal(item)"/>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="item.get('cotizacion').value.tipoCotizacion !== 1">
                          <div class="col col-4 mt-2">
                            <label class="form-label">Precio Final</label>
                            <div class="input-group"><span class="input-group-text">$</span>
                              <input readonly type="number" class="form-control border-start-0"
                                     placeholder="Precio Final"
                                     formControlName="precio" (blur)="updateOferta(item)"/>
                            </div>
                            <span
                              *ngIf="(item.get('precio').touched || item.get('precio').dirty) && item.get('precio').errors"
                              class="outStock">Este campo es requerido</span>
                          </div>
                          <div class="col col-4 mt-2">
                            <label class="form-label">Oferta Final</label>
                            <div class="input-group"><span class="input-group-text">$</span>
                              <input readonly type="number" class="form-control border-start-0" formControlName="oferta"
                                     placeholder="Oferta Final"/>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col col-12 mt-2">
                          <label class="form-label">¿Querés hacer algun comentario de los precios?</label>
                          <input class="form-control" placeholder="Ej: Precios por 5 Unidades."
                                 formControlName="comentario" list="comentarios"/>
                          <datalist id="comentarios">
                            <option value="Consultar precio y stock">
                            <option value="Oferta válida hasta: ">
                            <option value="Oferta comprando XX unidades">
                          </datalist>
                        </div>

                        <div class="col col-6 mt-2">
                          <label class="form-label">¿Nuevo Lanzamiento?</label>
                          <div class="input-group">
                            <select class="form-control" formControlName="lanzamiento">
                              <option [value]="false">NO</option>
                              <option [value]="true">Nuevo Lanzamiento</option>
                            </select>
                          </div>
                        </div>
                        <div class="col col-6 mt-2">
                          <label class="form-label">¿Recomendado?</label>
                          <div class="input-group">
                            <select class="form-control" formControlName="destacado">
                              <option [value]="false">NO</option>
                              <option [value]="true">RECOMENDADO</option>
                            </select>
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </ng-container>

          </div>
          <div class="ta-c" *ngIf="!products || !products.length">
            <p *ngIf="!chips && !chips.length">{{noexistenProductos}}</p>
            <p *ngIf="chips && chips.length">{{noHayResultadosFiltro}}</p>
          </div>
        </form>

        <form [formGroup]="registerProductForm" class="hidden-mobile">
          <div formArrayName="product">

            <ng-container *ngIf="products && products.length">
              <div class="card bg-dark mb-4 p-3 gold-border" [formGroupName]="pointIndex"
                   *ngFor="let item of getitems.controls; let idx = index; let pointIndex = index">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="mt-2 mb-2 form-check form-switch" style="float: right">
                        <input class="form-check-input" type="checkbox" formControlName="enabled"
                               (change)="enabled(idx, item)"/>
                        <label
                          class="form-check-label">{{ item.value.enabled ? 'Vendo este producto' : 'No vendo o no tengo stock'}}</label>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 texto">
                      <h5 class="mt-0 name">{{ item.value.name }}</h5>
                      <div class="marca">
                        <div class="col col-2 me-2" *ngIf="item.value.imagenMarca">
                          <a [href]="item.value.imagenMarca" target="_blank">
                            <img [src]="item.value.imagenMarca"
                                 class="img-thumbnail mb-2 me-2" [alt]="item.value.name">
                          </a>
                        </div>
                        <div class="col col-2">
                          <a *ngIf="item.value.image" [href]="item.value.image" target="_blank">
                            <img [src]="item.value.image"
                                 class="img-thumbnail mb-2 " [alt]="item.value.name">
                          </a>
                        </div>
                      </div>
                      <p class="mb-0 font-13 text-secondary"
                         *ngIf="item.value.nombreMarca"> {{ item.value.nombreMarca }}</p>
                      <p class="mb-0 font-13 text-secondary"
                         *ngIf="item.value.tipoProducto"> {{ item.value.tipoProducto }}</p>

                    </div>
                    <div class="mt-2" [hidden]="!item.value.requerirMarca || !item.value.enabled">
                      <label class="form-label">¿Qué marca es?</label>
                      <input class="form-control" formControlName="marca"/>
                    </div>

                    <div class="col col-12" [hidden]="!item.value.enabled">
                      <div class="row">
                        <div *ngIf="item.value.variantes && item.value.variantes?.length > 0 && item.value.enabled"
                             class="col col-12 mt-2 mb-2">
                          <label class="form-label">Variantes</label>
                          <ng-multiselect-dropdown
                            [placeholder]="'Seleccione las variantes'"
                            formControlName="variantesSelect"
                            [settings]="dropdownSettings"
                            [data]="item.value.variantes">
                          </ng-multiselect-dropdown>
                        </div>

                        <ng-container formGroupName="cotizacion">
                          <div class="col col-12">
                            <label class="form-label">Moneda</label>
                            <div class="input-group">
                              <select class="form-control" formControlName="cotizacion" (change)="setValorFinal(item)">
                                <option *ngFor="let c of cotizaciones" [value]="c.id"
                                        [innerText]="c.tipoCotizacion?.nombre"></option>
                              </select>
                            </div>
                          </div>

                          <div class="col col-6 mt-1">
                            <label class="form-label">Precio</label>
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input type="number" class="form-control border-start-0"
                                     [placeholder]="getTipoCotizacionItem(item)"
                                     formControlName="precio" (blur)="setValorFinal(item)"/>
                            </div>
                            <span
                              class="outStock"
                              *ngIf="(item.get('cotizacion').get('precio').touched ||
                            item.get('cotizacion').get('precio').dirty) && item.get('cotizacion').get('precio').errors">
                            Este campo es requerido
                          </span>
                          </div>
                          <div class="col col-6 mt-1">
                            <label class="form-label">¿En Oferta?</label>
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input type="number" class="form-control border-start-0" formControlName="oferta"
                                     (blur)="setValorFinal(item)" [placeholder]="getTipoCotizacionItem(item)"/>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="item.get('cotizacion').value.tipoCotizacion !== 1">
                          <div class="col col-6 mt-1">
                            <label class="form-label">Precio Final</label>
                            <div class="input-group"><span class="input-group-text">$</span>
                              <input readonly type="number" class="form-control border-start-0"
                                     placeholder="Precio Final"
                                     formControlName="precio" (blur)="updateOferta(item)"/>
                            </div>
                            <span
                              *ngIf="(item.get('precio').touched || item.get('precio').dirty) && item.get('precio').errors"
                              class="outStock">Este campo es requerido</span>
                          </div>
                          <div class="col col-6 mt-1">
                            <label class="form-label">Oferta Final</label>
                            <div class="input-group"><span class="input-group-text">$</span>
                              <input readonly type="number" class="form-control border-start-0"
                                     formControlName="oferta" placeholder="Oferta Final"/>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col col-12 mt-2">
                          <label class="form-label">¿Querés hacer algun comentario de los precios?</label>
                          <input class="form-control" placeholder="Ej: Precios por 5 Unidades."
                                 formControlName="comentario" list="comentarios_2"/>
                          <datalist id="comentarios_2">
                            <option value="Consultar precio y stock">
                            <option value="Oferta válida hasta: ">
                            <option value="Oferta comprando XX unidades">
                          </datalist>
                        </div>

                        <div class="col col-6 mt-2">
                          <label class="form-label">¿Nuevo Lanzamiento?</label>
                          <div class="input-group">
                            <select class="form-control" formControlName="lanzamiento">
                              <option [value]="false">NO</option>
                              <option [value]="true">Nuevo Lanzamiento</option>
                            </select>
                          </div>
                        </div>
                        <div class="col col-6 mt-2">
                          <label class="form-label">¿Recomendado?</label>
                          <div class="input-group">
                            <select class="form-control" formControlName="destacado">
                              <option [value]="false">NO</option>
                              <option [value]="true">RECOMENDADO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="ta-c" *ngIf="!products || !products.length">
            <p *ngIf="!chips && !chips.length">{{noexistenProductos}}</p>
            <p *ngIf="chips && chips.length">{{noHayResultadosFiltro}}</p>
          </div>
          <!--
        <div class="col-12 fixed-bottom box-guardar">
                      <button type="submit" class="btn btn-primary px-5" [disabled]="!registerProductForm.valid"
                  (click)="saveProduct()">Guardar
          </button>
          </div>
-->
        </form>

      </div>

    </div>

  </ng-container>

  <!--</div>-->
  <ng-template #content let-modal>

    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title">Tenés cambios sin guardar</h5>
      </div>
      <div class="modal-body">
        <p>¿Queres guardar y continuar?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeModal()">CONTINUAR SIN GUARDAR</button>
        <button type="button" class="btn btn-dark" (click)="modal.close()">GUARDAR Y CONTINUAR</button>
      </div>
    </div>
    <!--  end mobile-->
  </ng-template>

  <ng-template #contentIncrement let-modal>

    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title">Confirmar Aumento</h5>
      </div>
      <div class="modal-body">
        <p> Aumentaremos en {{increase}} % el valor de todos los productos
          habilitados {{increaseType ? ('en ' + increaseType.nombre) : '' }} que se encuentran listados. </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeModal()">CANCELAR</button>
        <button type="button" class="btn btn-dark" (click)="modal.close()">CONFIRMAR</button>
      </div>
    </div>
    <!--  end mobile-->
  </ng-template>

</ng-container>
