import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiBaseUrl } from 'src/environments/environment';
import { ApiService } from '../core/api.service';

export interface Faqs {
  id: number,
  orden: number,
  pregunta: string,
  respuesta: string
}
@Injectable({
  providedIn: 'root'
})
export class FaqService {

  private url: string;

  constructor(private apiService: ApiService) {
    this.url = apiBaseUrl;
  }

  getFaqs(): Observable<Array<Faqs>> {
    return this.apiService.get(this.url, '/odontologo/preguntas')
    .pipe(
      map((response) => {
        return response.payload;
      }));
  }
}
