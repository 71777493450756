import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormRoutingModule } from './form-routing.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormElementsComponent } from './form-elements/form-elements.component';
import { InputGroupsComponent } from './input-groups/input-groups.component';
import { FormWizardComponent } from './form-wizard/form-wizard.component';

import { Select2Component } from './select2/select2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormRegisterComponent } from './form-register/form-register.component';
import { FormProductsRegisterComponent } from './form-products-register/form-products-register.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SharedModule } from '../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CoursesModule } from '../courses/courses.module';
import {TagInputModule} from "ngx-chips-angular";


@NgModule({
  declarations: [
    FormElementsComponent,
    InputGroupsComponent,
    FormRegisterComponent,
    FormWizardComponent,
    FormProductsRegisterComponent,
    Select2Component,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    FormRoutingModule,
    ReactiveFormsModule,
    EditorModule,
    AngularEditorModule,
    NgbModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    TagInputModule,
  ],
  exports: [
    FormRegisterComponent,
    FileUploadComponent,
  ]
})
export class FormModule { }
