import * as moment from 'moment';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_SAVE = 'YYYY-MM-DD';
export const DATE_FORMAT_COURSE = 'D-M-YYYY';
export const DATE_PUBLIC_FORMAT = 'DD/MM/YYYY';

// export const DATE_FORMAT_COURSE = 'DD/MM/YYYY HH:mm';
export const getTimestamp = (): string => Date.now().toString();

export const formatDate = (date: Date | string, format = DATE_FORMAT_SAVE): string => (typeof date === 'string') ?
        moment(date, DATE_FORMAT_SAVE).format(format) : moment(date).format(format);

export const fromNow = () => moment().format('YYYY-MM-DD');
