<app-odonto-confirm
  *ngIf="modalChanges"
  (destroy)="closeConfirmChanges($event)"
  title="Tenés cambios sin guardar"
  description="¿Queres guardar y continuar?"
  cancel="CONTINUAR SIN GUARDAR"
  submit="GUARDAR Y CONTINUAR"
></app-odonto-confirm>

<div class="box-marcas">
  <ng-container *ngFor="let m of marcas">
    <div class="marca" *ngIf="m.selected" [class.selected]="m.id == marca?.id">
      <img class="cursor-pointer" (click)="setMarca(m)" [src]="m.imagen" [alt]="m.nombre" (error)="logoError($event)">
    </div>
  </ng-container>
</div>

<div class="row">
  <div class="col col-12 col-md-3">
    <div class="p-1">
      <p class="mb-0 ps-2">
        Buscar productos
      </p>
      <div class="p-1">
        <app-searchbar placeholder="Buscar.." (searchByText)="setTexto($event)"></app-searchbar>
      </div>
    </div>

  </div>

  <div class="col col-12 col-md-6" *ngIf="productos?.length">
    <ng-container [ngTemplateOutlet]="increaseRef"></ng-container>
  </div>

</div>

<div class="row">
  <div class="col col-12" *ngIf="!productos?.length">
    <p class="mt-2 mb-2 text-center">Sin resultados</p>
  </div>
</div>


<div class="row">

  <div class="col col-12" *ngFor="let item of productos; let idx = index;">

    <ng-container *ngIf="item.producto">
      <div class="card bg-dark mb-4 mt-4 p-3 gold-border">
        <div class="card-body">
          <div class="row">

            <div class="col col-6 col-md-1">
              <a [href]="item.producto.marca?.imagen" target="_blank">
                <img
                  (error)="logoError($event)"
                  [src]="item.producto.marca?.imagen"
                  class="img-thumbnail mb-2 me-2"
                  [alt]="item.producto.marca?.nombre"/>
              </a>
            </div>
            <div class="col col-6 col-md-1">
              <a [href]="item.producto.imagen" target="_blank">
                <img
                  (error)="logoError($event)"
                  [src]="item.producto.imagen"
                  class="img-thumbnail mb-2 me-2"
                  [alt]="item.producto.imagen">
              </a>
            </div>
            <div class="col col-12 col-md-4">

              <h5 class="mt-0" [innerText]="item.producto.nombre"></h5>
              <p
                *ngIf="item.producto.marca?.nombre"
                class="mb-0 font-13 text-secondary"
                [innerText]="item.producto.marca.nombre"></p>
              <p
                *ngIf="item.producto.tipoProducto"
                class="mb-0 font-13 text-secondary"
                [innerText]="item.producto.tipoProducto.nombre"></p>
            </div>

            <div class="col col-12 col-md-6">


              <div class="row">
                <div *ngIf="item.producto.variantes && item.producto.vars?.length"
                     class="col col-12 mt-2 mb-2">
                  <label class="form-label">Variantes</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Seleccione las variantes'"
                    [(ngModel)]="item.variantes"
                    (ngModelChange)="changes = true"
                    [settings]="dropdownSettings"
                    [data]="item.producto.vars">
                  </ng-multiselect-dropdown>
                </div>

                <ng-container>
                  <div class="col col-12 col-md-4">
                    <label class="form-label">Moneda</label>
                    <div class="input-group">
                      <select class="form-control" [(ngModel)]="item.tipoCotizacion"
                              (ngModelChange)="setValorFinal(item)">
                        <option *ngFor="let c of cotizaciones" [value]="c.tipoCotizacion.id"
                                [innerText]="c.tipoCotizacion.nombre"></option>
                      </select>
                    </div>
                  </div>

                  <div class="col col-12 col-md-4">
                    <label class="form-label">Precio</label>
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input type="number" class="form-control border-start-0"
                             [placeholder]="getTipoCotizacionItem(item)"
                             [(ngModel)]="item.cotizacion.precio" (ngModelChange)="setValorFinal(item)"/>
                    </div>
                    <span class="outStock" *ngIf="!item.precio">
                    Este campo es requerido
                  </span>
                  </div>
                  <div class="col col-12 col-md-4">
                    <label class="form-label">¿En Oferta?</label>
                    <div class="input-group">
                      <span class="input-group-text">$</span>
                      <input type="number" class="form-control border-start-0" [(ngModel)]="item.cotizacion.oferta"
                             [placeholder]="getTipoCotizacionItem(item)"
                             (ngModelChange)="setValorFinal(item)"/>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="item.tipoCotizacion !== 1">
                  <div class="col col-12 col-md-4 mt-2">
                    <label class="form-label">Precio Final</label>
                    <div class="input-group"><span class="input-group-text">$</span>
                      <input readonly type="number" class="form-control border-start-0"
                             placeholder="Precio Final"
                             [(ngModel)]="item.precio" (ngModelChange)="updateOferta(item)"/>
                    </div>
                    <span
                      *ngIf="!item.precio"
                      class="outStock">Este campo es requerido</span>
                  </div>
                  <div class="col col-12 col-md-4 mt-2">
                    <label class="form-label">Oferta Final</label>
                    <div class="input-group"><span class="input-group-text">$</span>
                      <input readonly type="number" class="form-control border-start-0" [(ngModel)]="item.oferta"
                             placeholder="Oferta Final"/>
                    </div>
                  </div>
                </ng-container>

                <div class="col col-12 mt-2">
                  <label class="form-label">¿Querés hacer algun comentario de los precios?</label>
                  <input class="form-control" placeholder="Ej: Precios por 5 Unidades."
                         [(ngModel)]="item.comentario" list="comentarios"/>
                  <datalist id="comentarios">
                    <option value="Consultar precio y stock">
                    <option value="Oferta válida hasta: ">
                    <option value="Oferta comprando XX unidades">
                  </datalist>
                </div>

                <div class="col col-12 col-md-6 mt-2">
                  <label class="form-label">¿Nuevo Lanzamiento?</label>
                  <div class="input-group">
                    <select class="form-control" [(ngModel)]="item.lanzamiento">
                      <option [value]="false">NO</option>
                      <option [value]="true">Nuevo Lanzamiento</option>
                    </select>
                  </div>
                </div>
                <div class="col col-12 col-md-6 mt-2">
                  <label class="form-label">¿Recomendado?</label>
                  <div class="input-group">
                    <select class="form-control" [(ngModel)]="item.destacado">
                      <option [value]="false">NO</option>
                      <option [value]="true">RECOMENDADO</option>
                    </select>
                  </div>
                </div>
              </div>


            </div>

          </div>


        </div>
      </div>
    </ng-container>

  </div>

</div>

<ng-template #increaseRef>
  <div class="p-1">
    <p class="mb-0 ps-2">
      Aplicar aumento porcentual a los productos actualmente marcados como en venta.
    </p>
    <div class="d-flex">
      <div class="p-1">
        <input type="number"
               min="0"
               [(ngModel)]="increase"
               class="form-control"
               placeholder="% de Incremento"/>
      </div>
      <div class="p-1">
        <select class="form-control" [(ngModel)]="increaseType">
          <option [ngValue]="undefined" disabled>Todos los productos</option>
          <option *ngFor="let c of tipoCotizaciones" [ngValue]="c" [innerText]="c.nombre"></option>
        </select>
      </div>
      <div class="p-1">
        <button
          type="button" class="btn btn-secondary"
          [disabled]="!increase || increase <= 0"
          (click)="addIncrement(contentIncrement)">
          Aplicar
        </button>

      </div>


    </div>

  </div>
</ng-template>

<ng-template #contentIncrement let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Confirmar Aumento</h5>
    </div>
    <div class="modal-body">
      <p> Aumentaremos en {{increase}} % el valor de todos los productos
        habilitados {{increaseType ? ('en ' + increaseType.nombre) : '' }} que se encuentran listados. </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">CANCELAR</button>
      <button type="button" class="btn btn-dark" (click)="modal.close()">CONFIRMAR</button>
    </div>
  </div>
</ng-template>

<div class="fixed-bottom floating-box">
  <button type="button" class="btn btn-primary px-5" (click)="submit()">
    Guardar Cambios
  </button>
</div>
