import { Component, Input, OnInit } from '@angular/core';
import { baseUrl, environment } from '../../../environments/environment';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { COMMON } from '../utils/common-texts.utils';
@Component({
  selector: 'app-to-share',
  templateUrl: './to-share.component.html',
  styleUrls: ['./to-share.component.scss']
})
export class ToShareComponent implements OnInit {

  @Input() title: string;
  @Input() descriptionShared: string;
  @Input() descriptionCopy: string;
  @Input() toShared: string;
  @Input() showText: boolean;
  @Input() sharedElement: string;
  // whatsapp = environment.whatsapp;
  whatsapp = environment.whatsappShared;
  href: string;
  constructor(private clipboardService: ClipboardService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.descriptionCopy = this.descriptionCopy ? this.descriptionCopy : COMMON.whatsapp.producto_detalle_copy_shared;
    this.descriptionShared = this.descriptionShared ? this.descriptionShared : COMMON.whatsapp.producto_detalle_shared;
    this.whatsapp += this.toShared;
    this.href = 'mailto:?subject=Mirá Todo-Odonto.com.ar&body=';
    this.href += this.toShared;
  }

  copyText(): void  {
    this.clipboardService.copyFromContent(this.toShared);
    this.toastService.success('Compartilo con tus amigos/colegas pegando el texto.', 'Ya lo podés usar!');
  }

}
