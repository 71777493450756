<div class="p-1" *ngIf="items && items.length">
  <ng-container *ngFor="let g of groups">
    <h4 class="mb-0"> <b>{{g|format2date|date:'EEEE'|uppercase}} {{g}}</b></h4>
    <div class="items">
      <ng-container *ngFor="let item of items" >
        <div class="card card-item pointer bg-dark" *ngIf="item.inicio === g"
             (click)="redirection(item)">
          <img [src]="item.imagen ? item.imagen : defaultNoImage" class="img-fluid" [alt]="item.imagen">
          <p class="mt-1 mb-1 text-3l-dots p-2">{{item.nombre}}</p>
          <p class="mt-1 mb-1 text-3l-dots p-2">{{item.inicio|format2date|date:'EEEE'|uppercase}} {{item.inicio}} {{item.hora}}</p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

