import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-related-to-product',
  templateUrl: './related-to-product.component.html',
  styleUrls: ['./related-to-product.component.scss']
})
export class RelatedToProductComponent implements OnInit {

  @Input() items;
  @Input() isLink: boolean;
  @Input() isCardCustomer: boolean;
  showLoading = false;
  defaultNoImage = environment.defaultNoImage;

  constructor(private sanitized: DomSanitizer) { }

  safeUrl(url: string): SafeResourceUrl {
    this.showLoading = true;
    return this.sanitized.bypassSecurityTrustResourceUrl(url);
  }

  onLoad(): void {
    this.showLoading = false;
  }

  ngOnInit(): void {
  }



}
