<div *ngIf="banners && banners.length > 0 && mode === 'slider'">
  <div class="row" [ngClass]="boxcss">
    <div class="col" style="padding: 0">

      <ngb-carousel *ngIf="banners" [interval]="interval" >
        <ng-template ngbSlide *ngFor="let banner of banners; let i = index">
          <div class="row">
            <div *ngFor="let internalBanner of banner.arrayOfBanners" class="col carousel-inner-2 {{name}} "  [class.col-md-3]="quantity === 4" [class.col-md-4]="quantity === 3">
              <a href="{{ internalBanner.link }}" [target]="internalBanner.externo ? '_blank' : '_self'">
                <img [ngClass]="customCss" class="img-thumbnail" [src]="baseUrlImg + internalBanner.imagen" [alt]="internalBanner.titulo"/>
                <div class="shadow text-center float-text" *ngIf="internalBanner.titulo && showDescription ">
                  <h5 >{{ internalBanner.titulo }}</h5>
                  <p *ngIf="internalBanner.descripcion ">{{ internalBanner.descripcion }}</p>
                </div>
              </a>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>


<!--<div *ngIf="banners && banners.length > 0 && mode === 'slider-prueba'">-->
<!--  <div class="row">-->
<!--    <div class="col" style="padding: 0">-->

<!--      <ngb-carousel *ngIf="banners" [interval]="interval" >-->
<!--        <ng-template ngbSlide *ngFor="let banner of banners; let i = index">-->
<!--          <div class="row">-->
<!--            <div *ngFor="let internalBanner of banner.arrayOfBanners" [class.col-md-1]="quantity === 8">-->
<!--              <a href="{{ internalBanner.link }}" [target]="internalBanner.externo ? '_blank' : '_self'">-->
<!--                <img [ngClass]="customCss" class="img-thumbnail-necesita" [src]="baseUrlImg + internalBanner.imagen" [alt]="internalBanner.titulo"/>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </ngb-carousel>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div *ngIf="banners && banners.length > 0 && mode === 'block' && cols">
  <!-- tipo block -->
  <div class="row">
    <a href="{{ banner.link }}" [target]="banner.externo ? '_blank' : '_self'" *ngFor="let banner of banners"
       class="p-block p-hover {{name}}" [ngClass]="cols">
      <div class="card br b-0" style="width: 100%;">
        <div>
          <img [src]="baseUrlImg + banner.imagen" class="block-image" title="{{banner.titulo}}"/>
        </div>
        <div class="card-img-overlay" *ngIf="hover && showDescription">
<!--        <div class="card-img-overlay" *ngIf="hover">-->
          <h5 class="card-title" *ngIf="banner.titulo">{{ banner.titulo }}</h5>
          <p *ngIf="banner.descripcion " class="card-text">{{ banner.descripcion }}</p>
        </div>
        <div class="card-texts" *ngIf="!hover">
          <h5 class="card-title" *ngIf="banner.titulo"><b>{{ banner.titulo }}</b></h5>
          <p *ngIf="banner.descripcion" class="card-text">{{ banner.descripcion }}</p>
        </div>
      </div>
    </a>
  </div>
</div>

<ng-container *ngIf="banners && banners.length > 0 && mode === 'scroll' && cols">
  <div class="row infinite-scroll">
    <div class="col {{name}}" [ngClass]="colsBlock" *ngFor="let banner of banners; let idx = index">
      <a href="{{ banner.link }}" [target]="banner.externo ? '_blank' : '_self'">
        <div class="card" [ngStyle]="{'background-color': colors[idx]}">
          <img [src]="baseUrlImg + banner.imagen" [alt]="banner.titulo"/>
          <div class="body p-2">
            <h5 class="mt-1 text-3l-dots" *ngIf="banner.titulo"><b>{{ banner.titulo }}</b></h5>
            <p class="text-3l-dots" *ngIf="banner.descripcion ">{{ banner.descripcion }}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="banners && banners.length > 0 && mode === 'business' && cols">
  <div class="row infinite-scroll business ">
    <div class="col col-4 {{name}}" [ngClass]="colsBlock" *ngFor="let banner of banners; let idx = index">
      <a href="{{ banner.link }}" [target]="banner.externo ? '_blank' : '_self'">
        <img [src]="baseUrlImg + banner.imagen" [alt]="banner.titulo"/>
      </a>
    </div>
  </div>
</ng-container>



<div *ngIf="banners && banners.length > 0 && mode === 'NECESITA' && cols">
  <div class="p-1">
    <div class="infinite-scroll-2">
      <div *ngFor="let banner of banners" class=" card-necesita  pointer {{name}}" (click)="goToBanner(banner)"
      >
        <img [src]="baseUrlImg + banner.imagen" [alt]="banner.titulo"  class="img-fluid img-highlight" [alt]="banner.titulo">
                <div class=" text-center float-text ">
                  <p >{{ banner.titulo }}</p>
              </div>
      </div>
    </div>
  </div>
</div>

<!--style="max-height: 140px; align-content: start;"-->
<ng-container *ngIf="banners && banners.length > 0 && mode === 'entidades' && cols">
  <div class="row infinite-scroll business {{lines}} " >
    <div class="col col-4 {{name}}" [ngClass]="colsBlock" *ngFor="let banner of banners; let idx = index">
      <a href="{{ banner.link }}" [target]="banner.externo ? '_blank' : '_self'">
        <img [src]="baseUrlImg + banner.imagen" [alt]="banner.titulo"/>
      </a>
    </div>
  </div>
</ng-container>



<!-- recomendados -->
<ng-container *ngIf="banners && banners.length > 0 && mode === 'recomendados' && cols">
  <div class="row recomendados {{name}}" [class.lg]="banners.length>8">
    <a href="{{ banner.link }}" [target]="banner.externo ? '_blank' : '_self'" *ngFor="let banner of banners"
       class="p-block p-hover" [ngClass]="cols">
      <div class="card br b-0" style="width: 100%;">
        <div>
          <img [src]="baseUrlImg + banner.imagen" class="block-image" title="{{banner.titulo}}"/>
        </div>
<!--        <div class="card-texts">-->
<!--          <h5 class="card-title" *ngIf="banner.titulo"><b>{{ banner.titulo }}</b></h5>-->
<!--          <p *ngIf="banner.descripcion" class="card-text">{{ banner.descripcion }}</p>-->
<!--        </div>-->
      </div>
    </a>
  </div>
</ng-container>
