<div class="card radius-15 mb-2">
    <div class="card-body">
        <div class="card-title">
            <h4 class="mb-0">Archivos Adjuntos</h4>
        </div>
        <hr />
        <div *ngFor="let file of uploadedFiles">
            <p (click)="downloadFile(file.fullPath)">{{ file.name }}</p>
        </div>
    </div>
</div>
<div class="card radius-15 mb-2">
    <div class="card-body">
        <div class="card-title">
            <h4 class="mb-0">Enviar nuevo archivo</h4>
        </div>
        <hr />

        <input (change)="fileUpload($event)" class="form-control classInputFile" type="file" id="formFileMultiple" accept=".xlsx,.xls,image/*,.doc,audio/*,.docx,video/*,.ppt,.pptx,.txt,.pdf" multiple>

        <div *ngIf="selectedFiles">
            <div *ngFor="let file of selectedFiles; let i=index" class="list-group list-group-flush">
                <div class="mt-3 d-flex">
                    <div class="flex-grow-1 ms-2">
                        <span class="font-22 text-primary"> <i class="fadeIn animated bx bx-check" style="color: #7fff00;"></i>
                        </span>
                        <h6 class="ms-2 mb-0 chat-title" style="display: inline;">{{ file.name }}</h6>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="align-self-center mt-3 mb-3">
        <button (click)="backToChats(false)" class="btn btn-danger ">Cancelar</button>
        <button (click)="uploadFiles()" class="btn btn-primary" [disabled]="canUpload.getValue()" [attr.disabled]="canUpload.getValue()">Confirmar</button>
    </div>
</div>
