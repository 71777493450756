import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {baseUrl} from "../../../environments/environment";

@Component({
  selector: 'app-iframe-url',
  templateUrl: './iframe-url.component.html',
  styleUrls: ['./iframe-url.component.scss']
})
export class IframeUrlComponent implements OnInit {

  url?: string;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.url = undefined;
      setTimeout(() => {
        console.log('params', params)
        this.url = `/assets/files/${params.url}.pdf`;
      }, 500)
    });
  }

  ngOnInit(): void {
  }

}
