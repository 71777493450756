import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Product, Variante} from "../../../services/product.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {COMMON} from "../../utils/common-texts.utils";
import {Cart, StorageService} from "../../../services/storage.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-product-cart',
  templateUrl: './add-product-cart.component.html',
  styleUrls: ['./add-product-cart.component.scss']
})
export class AddProductCartComponent implements OnInit, OnChanges {

  @Input() product: Product;

  cantidad = 1;
  variantes: string[] = []
  cantidades: number[] = []

  dropdownSettings = {
    singleSelection: false,
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    searchPlaceholderText: 'Buscar',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  constructor(
    private modalService:NgbModal,
    private toastService: ToastrService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initVars();
  }

  toggleVariante(variante: string, event: any): void {
    event.stopPropagation();
    console.log(variante);
    const index = this.variantes.indexOf(variante, 0);
    if (index > -1) {
      this.variantes.splice(index, 1);
    } else if(index < 0) {
      this.variantes.push(variante);
    }
  }

  ngOnChanges(): void {
    this.initVars();
  }

  initVars(): void {
    this.variantes = [];
    this.cantidades = [];
    if(typeof this.product.variantes === "string") {
      this.product.vars = (this.product.variantes?.split(',')||[]).filter(x => x.trim() !== '');

      this.product.vars.forEach(x => {
        this.cantidades[x] = 1;
      })

    } else {
      this.product.vars = [];
    }
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  addMultiple(content: any): void {
    this.variantes.forEach(x => {
      if(this.cantidades[x] > 0) {
        this.setCart(this.cantidades[x], x);
      }
    })
    this.initVars();
    this.open(content)
  }

  addUnique(content: any): void {
    this.setCart(this.cantidad, '')
    this.cantidad = 0;
    this.open(content);
  }

  private setCart(cantidad: number, vari = '') {
    const variante = vari?.trim();
    let allCart: Array<Cart> = [];
    allCart = this.storageService.getCartStorage();
    const productExists = allCart.filter((cart: Cart) => Number(cart.id) === this.product.id && cart.variante === variante);

    if (productExists.length > 0) {
      productExists[0].cantidad += cantidad;
    } else {
      allCart.push({
        id: this.product.id.toString(),
        tipo: this.product.tipoProducto,
        marca: this.product.marca,
        imagen: this.product.imagen,
        variante: variante,
        cantidad: cantidad,
        producto: this.product,
      });
    }
    this.storageService.setCarritoStorage(allCart);
    this.storageService.newInfoCart();
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
    });
  }

  redirect(action) {
    this.closeModal();
    this.router.navigate([action]);
  }


}
