import { Component, Input, OnInit } from '@angular/core';
import { Seller } from '../../cart/main/cart-main.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() showAlertSeller: boolean;
  @Input() showMessageCourse: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
