
<div class="row">
  <div class="col" style="padding: 0">
    <ngb-carousel [interval]="2000"> <!-- Cambio de imagen cada 5 segundos -->
      <ng-template ngbSlide *ngFor="let card of banners">
        <div class="card">
          <img class="card-img-top" [src]="card.image" [src]="baseUrlImg + card.imagen" [alt]="card.titulo" alt="Card Image">

        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<!--<ngb-carousel [interval]="5000" [showNavigationArrows]="false" [showNavigationIndicators]="false">-->
<!--  <ng-template ngbSlide *ngFor="let chunk of banners">-->
<!--    <div class="card-group">-->
<!--      <div class="card" *ngFor="let card of chunk">-->
<!--        <img class="card-img-top" [src]="card.image" alt="Card Image">-->
<!--        <div class="card-body">-->
<!--          <h5 class="card-title">{{ card.title }}</h5>-->
<!--          <p class="card-text">{{ card.description }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</ngb-carousel>-->
