<div class="card bg-dark gold-border" *ngIf="product">
  <img [src]="product.imagen? product.imagen : 'assets/images/icons/chair.png' " class="card-img-top-2" alt="">
  <div class="card-body" >
    <div >
      <h6 class="card-title cursor-pointer" [title]="product.nombre">{{product.nombre}}</h6>
      <p *ngIf="product.marca" class=" mb-1 cursor-pointer ">{{product.marca.nombre}}</p>
      <p class="mb-0 font-13 text-secondary" *ngIf="product.variante"> Variante: {{product.variante}}</p>
    </div>
    <br>
    <div>
      <p>Precio total: $ {{ product.cantidad * product.oferta }}</p>
    </div>
  </div>
  <div  class="card-footer justific mb-2">
    <div class="input-group input-spinner">
      <button class="btn btn-white" type="button" (click)="subtract(product)"> −</button>
      <input type="text" class="form-control text-center" [(ngModel)]="product.cantidad" disabled >
      <button class="btn btn-white" type="button" (click)="plus(product)"> +</button>
    </div>

  </div>
</div>
