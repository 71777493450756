<div class="modal-content card gold-border bg-dark">
  <ng-container *ngIf="step == 1">
    <div class="modal-body text-center py-4">
      <h3>¡Listo!</h3>
      <p class="mb-0">Ahora los administradores verificarán y publicarán el producto.</p>
      <p class="mb-0">¡Muchas gracias!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss()">Cerrar</button>
    </div>
  </ng-container>

  <ng-container *ngIf="form && step == 0">
    <div class="modal-header " style="display: block">
      <h5 class="modal-title">Recomendar un Producto</h5>
      <p class="m-0">El mismo será verificado por nuestros administradores.</p>
    </div>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label required">Nombre del producto</label>
            <div class="input-group">
              <input formControlName="nombre" class="form-control" placeholder="Ingresé el nombre">
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label">Sitio web del fabricante</label>
            <div class="input-group">
              <input formControlName="web" class="form-control" placeholder="Sitio web del fabricante">
            </div>
          </div>

          <div class="col-md-6">
            <label class="form-label">Marca</label>
            <div class="input-group">
              <ng-multiselect-dropdown
                *ngIf="marcas?.length && marcasReady"
                style="width: 100%"
                [placeholder]="'Seleccione una Marca'"
                formControlName="marca"
                [settings]="singleSettings"
                [data]="marcas">
              </ng-multiselect-dropdown>
              <p class="mb-0 text-end w-100 text-primary pointer" (click)="defaultMarca()">
                No se la Marca o no está en la lista.
              </p>
            </div>
          </div>


          <div class="col-12 col-md-12">
            <label class="form-label">Variantes</label>
            <div class="input-group">
              <tag-input style="width: 100%"
                         [addOnBlur]="true"
                         secondaryPlaceholder="Ej: Color Rojo"
                         placeholder="Ej: Color Rojo"
                         [addOnPaste]="true"
                         [pasteSplitPattern]="','"
                         [theme]="'dark'"
                         [formControlName]="'variantes'"
                         name="items"></tag-input>
            </div>
          </div>

          <div class="col-md-12">
            <label class="form-label">Descripción</label>
            <div class="input-group">
              <angular-editor formControlName="descripcion" [config]="editorConfig"
                              style="width: 100%;"></angular-editor>
            </div>
          </div>

          <div class="col-12">
            <label class="form-label">Imágenes</label>
            <div class="mt-4 mt-lg-0 border border-3 p-1 rounded">
              <div class="imagen-center">
                <div class="imageuploadify well">
                  <div class="imageuploadify-overlay">
                    <i class="fa fa-picture-o"></i>x
                  </div>
                  <div class="imageuploadify-images-list text-center">
                    <br>
                    <div class="imageuploadify-container" style="margin-left: 24px;" *ngFor="let image of files">
                      <button type="button" class="btn btn-danger bx bx-x" (click)="remove(image)"></button>
                      <img [src]="image|safeURL">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 button-file mb-2 text-center mt-3">
                <input #file type="file" accept="image/*" formControlName="imagenes" multiple
                       (change)="fileChange($event)" style="display: none">
                <a #upload (click)="file.click()" class="btn btn-outline-primary radius-15">Cargar imagen</a>
              </div>
            </div>

          </div>
        </div>
        <div class="py-4">
          <ng-container *ngFor="let r of requireds">
            <p class="text-danger mb-0 px-4" *ngIf="!form.get(r.key)?.value">* Falta completar el campo <b
              [innerText]="(r.text ? r.text : r.key)|uppercase"></b></p>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancelar</button>
        <button type="submit" class="btn btn-outline-primary" [disabled]="!form.valid || loading>0">Recomendar</button>
      </div>
    </form>

  </ng-container>
</div>
