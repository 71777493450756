<div class="input-group mb-3">
  <div class="input-group-prepend">
      <span class="input-group-text bg-transparent">
        <i class='bx bx-home'></i>
      </span>
  </div>
  <input
    type="text"
    ngx-google-places-autocomplete
    *ngIf="autocomplete"
    (onAddressChange)="handleAddressChange($event)"
    [options]="optionsAC"
    [placeholder]="placeholder"
    class="form-control border-start-0 search "
    [disabled]="isDisabled || position.locked"
    [value]="init"
  />
  <div class="input-group-append" (click)="setLock()">
      <span class="input-group-text bg-transparent" *ngIf="position.locked">
        <i class='bx bx-trash-alt'></i>
      </span>
  </div>
</div>
