<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-lg-9 col-xl-12">
            <form class="float-lg-star">
              <div class="row row-cols-lg-auto g-2">
                <div class="col-10">
                  <div class="position-relative">
                    <input type="text" class="form-control ps-5 " name="inputSearch"
                           placeholder="Buscar por nombre"
                           [ngModel]="nombre" (ngModelChange)="buscar($event)"
                    >
                    <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                  </div>
                </div>
                <div class="col-2 d-lg-none">
                  <div class="btn-group" role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-white dropdown-toggle dropdown-toggle-nocaret px-1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class='bx bx-slider'></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="btnGroupDrop1" >
                      <div *ngIf="chips && chips.length">
                        <div *ngFor="let chip of chips let i=index;"  class="mr-b">
                          <h6 *ngIf="chip.keyType === 'avales'">{{chip.tipo}} seleccionada</h6>
                          <h6 *ngIf="chip.keyType === 'modalidad'">{{chip.tipo}} seleccionada</h6>
                          <h6 *ngIf="chip.keyType === 'vendedor'">{{chip.tipo}} seleccionado</h6>
                          <h6 *ngIf="chip.keyType === 'especialidad'">{{chip.tipo}} seleccionados</h6>
                          <div class="chip-filter" *ngIf="chip.keyType !== 'texto'">
                            <span class="name">{{chip.nombre}}</span>
                            <!--            <span class="closebtn"  (click)="deleteChip(chip.id)" onclick="this.parentElement.style.display='none'">×</span>-->
                            <span class="closebtn"  (click)="deleteSelected(chip)" onclick="this.parentElement.style.display='none'">×</span>
                          </div>
                        </div>
                      </div>

                      <!-- Especialidades -->
                      <app-sidebar-collapse-filter
                        *ngIf="specialty?.length"
                        name="Especialidades"
                        [items]="specialty"
                        (onSelect)="selected('especialidad', $event)">
                      </app-sidebar-collapse-filter>

                      <!-- Modalidades -->
                      <app-sidebar-collapse-filter
                        *ngIf="modalidades?.length"
                        name="Modalidades"
                        [items]="modalidades"
                        (onSelect)="selected('modalidad', $event)">
                      </app-sidebar-collapse-filter>

                      <!-- Avales -->
                      <app-sidebar-collapse-filter
                        *ngIf="avales?.length"
                        name="Aval"
                        [items]="avales"
                        (onSelect)="selected('aval', $event)">
                      </app-sidebar-collapse-filter>


                      <!-- Vendedores -->
                      <app-sidebar-collapse-filter
                        *ngIf="vendedores?.length"
                        name="Vendedores"
                        [items]="vendedores"
                        value="razonSocial"
                        (onSelect)="selected('vendedor', $event)">
                      </app-sidebar-collapse-filter>
                    </ul>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

