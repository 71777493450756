import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserProfileService, Usuario } from '../user-profile/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class SellerGuard implements CanActivate {

  constructor(private userProfileService: UserProfileService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

      return new Promise((resolve, reject) => {
        this.userProfileService.getUserProfile().subscribe((resp: Array<Usuario>) => {

              if ( resp.length > 0 ) {

                let isSeller: boolean = (<any>resp[0]).vendedor;
                console.log(isSeller);
                if ( isSeller ) {
                  resolve(true);
                } else {
                  this.router.navigate(['/login']);
                  resolve(false);
                }

              } else {
                this.router.navigate(['/login']);
                resolve(false);
              }

            }, ( error: any ) => {
              this.router.navigate(['/login']);
              resolve(false);
            })
      });

  }

}
