<div class="card mb-3">
  <div class="row g-0">
    <div class="col col-2 col-md-1 order-1">
      <img [src]="product.imagen" class="img-fluid rounded-start" [alt]="product.nombre">
    </div>
    <div class="col col-10 col-md-11 order-md-2">
      <div class="card-body">
        <div class="content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
