import { Injectable } from '@angular/core';
import { SpinnersComponent } from '../components/spinners/spinners.component';
import { SharedService } from '../shared/shared.service';

// import { LoadingController } from '@ionic/angular';

export interface LoaderState {
  show: boolean;
}

@Injectable({ providedIn: 'root' })
export class LoaderService {
  isLoading: boolean = false;

  constructor(
    public spinnersComponent: SpinnersComponent,
    public sharedService: SharedService,
  ) { }

   async show(id: string) {
   this.sharedService.presentLoading();
  //   const loading = await this.spinnersComponent.show({
  //     id,
  //     spinner: 'lines',
  //     animated: false,
  //     mode: 'ios'
  //   });
  //   this.isLoading = true;
  //   return loading.present();
  // }
  //
  // async hide(id: string) {
  //   await this.loadingController.dismiss(null, null, id);
  //   this.isLoading = false;
   }

  async hide(id: string) {
    this.sharedService.closeLoading();
    // await this.loadingController.dismiss(null, null, id);
    this.isLoading = false;
  }


}
