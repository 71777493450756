<div class="row">

  <div class="menu col-md-12" *ngIf="destacados && destacados.length">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeItemFeature" class="nav-tabs">
      <li [ngbNavItem]=i [ngClass]="{ 'active': activeItemFeature === i }"  *ngFor="let m of destacados; index as i" >
        <a ngbNavLink (click)="onFeaturedChange(m)">{{m.categoria}}</a>
      </li>
    </ul>
  </div>

  <div class="menu" *ngIf="product" [ngClass]="product && product.link && product.folleto? 'col-md-9': product && product.link || product.folleto? 'col-md-10': 'col-md-12'">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeItem" class="nav-tabs">
      <li [ngbNavItem]=i [ngClass]="{ 'active': activeItem === i }"  *ngFor="let m of menu; index as i" >
        <a ngbNavLink (click)="onNavChange(m)" >{{m.title}}</a>
      </li>
    </ul>
  </div>

  <div *ngIf="showMenuRight"
       [ngClass]="product && product.link && product.folleto? 'col-md-3': product && product.link || product.folleto?'col-md-2': ''" class=" mr-t-1" id="contenedor" >
    <a *ngIf="product && product.link" [href]="product.link" target="_blank">
      <span class="title-link" >Ver sitio oficial</span>
    </a>
    <div class="linea-vertical" *ngIf="product && product.folleto && product.link"></div>
    <a *ngIf="product && product.folleto" [href]="product.folleto" target="_blank">
      <span class="title-link" >Ver prospecto</span>
    </a>
  </div>

</div>
<div  *ngIf="show" [innerHTML]="show|safeHTML">{{show}}</div>
<div  *ngIf="videos?.length || showLinks?.length || courses?.length">

  <app-related-to-product *ngIf="showVideo && showVideo.length"
                          [items] = showVideo
                          [isLink] = true></app-related-to-product>

  <app-related-to-product *ngIf="links && links.length && showLinks && showLinks.length"
                          [items] = links
                          [isLink] = true></app-related-to-product>

  <app-related-to-product
    [isCardCustomer]="false"
    [isLink]="false"
    *ngIf="courses?.length && showCourses" [items]="courses">
  </app-related-to-product>

</div>


<br>
<div class="row product-grid p-3" *ngIf="showFeature">
  <app-related-to-product [items] = showFeature.items [isCardCustomer]="isCardCustomer"></app-related-to-product>


</div>








