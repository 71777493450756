import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Marca, Product, ProductService} from "../../services/product.service";
import {
  Cotizacion, ProductoCotizacion,
  ProductsRegister,
  TipoCotizacion
} from "../../form/form-products-register/interface-products-register";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment";
import {FormArray} from "@angular/forms";
import {COMMON} from "../../shared/utils/common-texts.utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

interface VPProducto {
  producto: Product;

  comentario?: string;
  cotizacion?: ProductoCotizacion;
  destacado: boolean;
  disponible: boolean;
  lanzamiento: boolean;
  oferta?: number;
  precio?: number;
  prodId: number;
  variantes?: string[];

  //front
  tipoCotizacion?: number
}

@Component({
  selector: 'app-vp-precios',
  templateUrl: './vp-precios.component.html',
  styleUrls: ['../vp-styles.scss', './vp-precios.component.scss']
})
export class VpPreciosComponent implements OnInit {


  productos: VPProducto[];

  changes = false;
  modalChanges = false;

  @Input() cotizaciones: Cotizacion[];
  @Input() tipoCotizaciones: TipoCotizacion[];

  loading = false;
  marca: Marca;
  texto?: string;

  increase: number;
  increaseType?: TipoCotizacion;

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    searchPlaceholderText: 'Buscar',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  @Input() marcas: Marca[];
  @Input() info: ProductsRegister;

  constructor(
    private productService: ProductService,
    private toastService: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.changeFilters();
  }

  submit(): void {
    const data = {
      items: this.productos.map((x: any) => {
        x.variantes = (Array.isArray(x.variantes)) ? x.variantes.join(',') : x.variantes;
        return x;
      })
    };
    this.productService.postSellProducts(data as any)
      .subscribe((info: any) => {
        console.log(info);
        this.changes = false;
        this.toastService.success("Guardado correctamente");
      })
  }

  logoError(event: any) {
    event.target.src = environment.defaultNoImage;
  }

  setMarca(marca: Marca): void {
    this.marca = marca;
    this.changeFilters();
  }

  setTexto(texto: string): void {
    this.texto = texto;
    this.changeFilters();
  }

  changeFilters(): void {
    if(this.changes) {
      this.modalChanges = true;
    } else {
      this.getProductos();
    }
  }

  closeConfirmChanges(confirm: boolean): void {
    this.changes = false;
    this.modalChanges = false;
    // guardar y continuar.
    if(confirm) {
      this.submit();
      setTimeout(() => {
        this.getProductos();
      }, 200);
    } else {
      this.getProductos();
    }
  }

  getProductos(): void {
    this.loading = true;
    const params: any = {
      precio: true
    };
    if (this.marca) {
      params.marca = this.marca.id;
    }
    if (this.texto) {
      params.texto = this.texto;
    }
    this.productService.getProductosVendedor(params)
      .subscribe((info: any) => {
        this.loading = false;
        this.initForm(info.productos);
      }, error => {
        console.error(error);
        this.loading = false;
      })
  }

  initForm(productos: Product[]): void {
    this.productos = productos.map(x => {
      x.vars = (x.variantes?.split(',') || []).filter(x => x.trim() !== '');
      const tipoCotizacion = x.vendeproducto.cotizacion?.tipoCotizacion;

      const cotizacion = {
        id: x.vendeproducto.cotizacion?.id || 0,
        tipoCotizacion: tipoCotizacion,
        precio: x.vendeproducto.cotizacion?.precio || x.vendeproducto.precio,
        oferta: x.vendeproducto.cotizacion?.oferta || 0,
        cotizacion: x.vendeproducto.cotizacion?.id || 0,
      };

      const variantes = ((!x.vendeproducto.disponibles) ? [] :
        (Array.isArray(x.vendeproducto.disponibles) ? x.vendeproducto.disponibles : x.vendeproducto.disponibles.split(',')))
        .filter(x => x && x.trim() != '')

      return {
        producto: x,
        comentario: x.vendeproducto.comentario,
        lanzamiento: x.vendeproducto.lanzamiento,
        destacado: x.vendeproducto.destacado,
        variantes,
        precio: x.vendeproducto.precio,
        oferta: x.vendeproducto.oferta,
        tipoCotizacion: tipoCotizacion?.id || 1,
        cotizacion: cotizacion,
        disponible: true,
        prodId: x.id,
      }
    });
  }

  getTipoCotizacionItem(item: VPProducto): string {
    const type = item.tipoCotizacion;
    return `(${(this.tipoCotizaciones || []).find((x => x.id === type))?.nombre || ''})`;
  }

  updateOferta(item: any): void {
    if (item.oferta === '' || item.oferta === 0 || item.oferta === '0') {
      item.oferta = item.precio;
    }
  }

  setValorFinal(item: any): void {
    this.changes = true;
    if (item.tipoCotizacion) {
      const cc = this.cotizaciones.find(x => x.tipoCotizacion.id === parseInt(item.tipoCotizacion));
      if (cc) {
        const valor = cc.valor;

        if (!item.cotizacion) {
          item.cotizacion = {
            id: cc.id,
          };
        }

        item.cotizacion.cotizacion = cc.id;
        item.cotizacion.tipoCotizacion = cc.tipoCotizacion;

        if (item.precio && (item.oferta === '' || item.oferta === 0 || item.oferta === '0')) {
          item.cotizacion.oferta = item.cotizacion.precio;
        }

        item.precio = !valor ? null : valor * item.cotizacion.precio;
        item.oferta = item.precio

        if (item.cotizacion.oferta) {
          item.oferta = !valor ? null : valor * item.cotizacion.oferta;
        }
      }
    }
  }


  addIncrement(contentIncrement): void {
    this.modalService.open(contentIncrement, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.productos.map((item) => {

        const increaseType = this.increaseType?.id;
        const itemType = item.cotizacion.tipoCotizacion?.id;
        const sameType = (!this.increaseType) || (!itemType && increaseType === 1) || (itemType === increaseType);

        if (sameType) {
          item.oferta = this.getIncreaseValue(item.oferta || 0);
          item.precio = this.getIncreaseValue(item.precio || 0);
          item.cotizacion.precio = this.getIncreaseValue(item.cotizacion.precio || 0);
          item.cotizacion.oferta = this.getIncreaseValue(item.cotizacion.oferta || 0);
        }
      });
      this.toastService.success(`${COMMON.producto.registro.mensaje_incremento} ${this.increase} %`, `${COMMON.producto.registro.titulo_mensaje_incremento}`, {timeOut: 6000});
      this.increase = 0;
    }, (reason) => {
      this.modalService.dismissAll();
    });
  }

  private getIncreaseValue(value: number): number {
    return Number(((this.increase * value / 100) + value).toFixed(2))
  }

}
