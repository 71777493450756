import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-form-image',
  templateUrl: './form-image.component.html',
  styleUrls: ['./form-image.component.scss']
})
export class FormImageComponent implements OnInit {

  defaultImage = environment.defaultAvatar;
  image?: string|ArrayBuffer;
  @Input() init?: string;
  @Input() title?: string;
  @Output() onChange = new EventEmitter<string|ArrayBuffer>();

  constructor() { }

  ngOnInit(): void {
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.onChange.emit(myReader.result);
    };
    myReader.readAsDataURL(file);
  }


}
