import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiBaseUrl} from 'src/environments/environment';
import {ApiService} from '../core/api.service';
import {ProductosUpdate} from '../form/form-products-register/interface-products-register';
import {Category} from "../categories/categories.service";

export interface ExternalLinks {
  id: number;
  orden: number;
  titulo: string;
  descripcion: string;
  link: string;
  video: boolean;
  imagen: string;
  idioma: string;
  expiracion?: string;
}


export interface CategoryLinks {
  id: number;
  orden: number;
  nombre: string;
  imagen: string;
  ctaDescription?: string;
  ctaLink?: string;
  ctaTitulo?: string;
  visible?: boolean;
  columnas: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExternalLinksService {

  categoryPath = '/odontologo';
  linksPath = '/links';

  constructor(private apiService: ApiService) {

  }

  getExternalLinks(parmas?): Observable<Array<ExternalLinks>> {
    return this.apiService.get(apiBaseUrl, `${this.categoryPath}/links`, parmas)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }


  getExternalLinksWithParams(searchTerm?: string, specialties?: string[], categories?: string[]): Observable<Array<ExternalLinks>> {
    let params = [];

    if (searchTerm) {
      params.push('texto=' + searchTerm);
    }

    if (specialties.length > 0) {
      params.push(`especialidad=${specialties.join(',')}`);
    }

    if (categories.length > 0) {
      params.push(`categorias=${categories.join(',')}`);
    }

    const parameters = params.join('&');

    return this.apiService.get(apiBaseUrl, `${this.categoryPath}/links?${parameters}`)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  getCategories(params?): Observable<Array<CategoryLinks>> {
    return this.apiService.get(apiBaseUrl, `${this.categoryPath}/categoria_links`, params)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  posLink(params): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.linksPath}/guardalink`, params)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  guardarLinkCurso(data: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.linksPath}/guardar-link-curso`, data)
      .pipe(
        map((response) => {
          if (response.status?.value === 200) {
            return response.payload;
          }
          throw new Error();
        })
      );
  }

  getLinksByCurso(id: number): Observable<ExternalLinks[]> {
    return this.apiService.get(apiBaseUrl, `${this.linksPath}/by-curso/${id}`)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  eliminarLinkDeCurso(data: any): Observable<ExternalLinks[]> {
    return this.apiService.post(apiBaseUrl, `${this.linksPath}/delete-link`, data)
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }


}
