<div *ngIf="product.vars?.length; else sinVariantes">

  <label class="form-label mr-t-10">Variantes</label>
  <div>
      <label  *ngFor="let v of product.vars;" class="form-check form-check-inline" (click)="toggleVariante(v, $event)">
        <input class="form-check-input" type="checkbox" [checked]="!!variantes.includes(v)">
        <span click-stop-propagation [innerText]="v"></span>
      </label>
  </div>

  <!--
  <ng-multiselect-dropdown
    style="width: calc(100% - 200px);"
    [placeholder]="'Seleccione las variantes'"
    [settings]="dropdownSettings"
    [(ngModel)]="variantes"
    [data]="product.vars">
  </ng-multiselect-dropdown>
  -->

  <div *ngFor="let v of product.vars!">
    <div class="row pt-3" *ngIf="variantes.includes(v)">
      <div class="col col-6">
        <div class="input-group">
          <input type="text" class="form-control text-center" disabled [value]="v">
        </div>
      </div>
      <div class="col col-6">
        <div class="input-group input-spinner" style="max-width: 200px">
          <button class="btn btn-white me-0" type="button" (click)="cantidades[v]=cantidades[v]-1"> −</button>
          <input type="number" min="0" step="1" class="form-control text-center" [(ngModel)]="cantidades[v]">
          <button class="btn btn-white" type="button" (click)="cantidades[v]=cantidades[v]+1"> +</button>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-3">
    <button [disabled]="variantes == 0" [ngbTooltip]="'Agregar al carrito'" class="btn btn-outline-primary"
            (click)="addMultiple(content2)">
      Agregar <i class="bx bx-cart"></i>
    </button>
    <button [disabled]="variantes == 0" class="btn btn-danger" [ngbTooltip]="'Limpiar variantes'"
            (click)="initVars()">
      <i class="bx bx-trash"></i>
    </button>
  </div>


</div>
<ng-template #sinVariantes>
  <div class="row py-2">
    <div class="col col-12 col-md-5 mb-4 mb-md-1">
      <div class="input-group input-spinner" style="max-width: 200px; margin: auto">
        <button class="btn btn-white me-0" type="button" (click)="cantidad=cantidad-1"> −</button>
        <input type="number" min="0" step="1" class="form-control text-center" [(ngModel)]="cantidad">
        <button class="btn btn-white" type="button" (click)="cantidad=cantidad+1"> +</button>
      </div>
    </div>
    <div class="col col-12 col-md-6">
      <button [disabled]="cantidad == 0" [ngbTooltip]="'Agregar al carrito'" class="btn btn-outline-primary"
              (click)="addUnique(content2)">
        Agregar <i class="bx bx-cart"></i>
      </button>
      <button [disabled]="cantidad == 0" class="btn btn-danger" [ngbTooltip]="'Resetear cantidad'"
              (click)="cantidad = 0">
        <i class="bx bx-trash"></i>
      </button>
    </div>
  </div>
</ng-template>


<ng-template #content2 let-modal>

  <div class="modal-content ">
    <div class="modal-body">
      <div class="row icon-alig">
        <img class="me-2" src="assets/images/icons/icon-check.svg"
             width="16" alt="check">
      </div>
      <p>EL PRODUCTO SE HA AGREGADO AL CARRITO DE COMPRA.</p>
      <div class="d-felx">
        <!--        <button type="button" class="btn btn-outline-primary">Seguir comprando</button>-->
        <button type="button" class="btn btn-outline-primary-modal gold-border" (click)="redirect('product/')"><span>Seguir explorando</span>
        </button>
        <button type="button" class="btn btn-outline-primary-modal gold-border mr-l-10" (click)="redirect('cart/')">
          <span>ir al Carrito</span>
        </button>
        <button type="button" class="btn btn-outline-primary-modal gold-border mr-l-10" (click)="closeModal()">
          <span>Cerrar</span>
        </button>
      </div>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
