import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Marca, Product, ProductService} from "../../services/product.service";
import {ProductsRegister} from "../../form/form-products-register/interface-products-register";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment";

interface VPProducto {
  producto: Product;
  idVendeproducto?: number,
  idProducto: number,
  selected: boolean,
  comentario?: string,
}

@Component({
  selector: 'app-vp-productos',
  templateUrl: './vp-productos.component.html',
  styleUrls: ['../vp-styles.scss', './vp-productos.component.scss']
})
export class VpProductosComponent implements OnInit {

  changes = false;
  modalChanges = false;

  marca: Marca;
  loading = false;
  productos: VPProducto[];
  texto?: string;

  @Input() marcas: Marca[];
  @Input() info: ProductsRegister;
  @Output() destroy = new EventEmitter<string>();

  constructor(
    private productService: ProductService,
    private toastService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.changeFilters();
  }

  submit(): void {
    const data = {
      items: this.productos
    };
    this.productService.saveProductosVendedor(data)
      .subscribe((info: any) => {
        this.toastService.success("Guardado correctamente");
        this.changes = false;
        this.destroy.emit("Testing");
      })
  }

  logoError(event: any) {
    event.target.src = environment.defaultNoImage;
  }

  setMarca(marca: Marca): void {
    this.marca = marca;
    this.changeFilters();
  }

  setTexto(texto: string): void {
    this.texto = texto;
    this.changeFilters();
  }

  changeFilters(): void {
    if(this.changes) {
      this.modalChanges = true;
    } else {
      this.getProductos();
    }
  }

  closeConfirmChanges(confirm: boolean): void {
    this.changes = false;
    this.modalChanges = false;
    // guardar y continuar.
    if(confirm) {
      this.submit();
      setTimeout(() => {
        this.getProductos();
      }, 200);
    } else {
      this.getProductos();
    }
  }

  getProductos(): void {
    this.loading = true;

    const params: any = {};
    if (this.marca) {
      params.marca = this.marca.id;
    }
    if (this.texto) {
      params.texto = this.texto;
    }
    this.productService.getProductosVendedor(params)
      .subscribe((info: any) => {
        this.loading = false;
        this.changes = false;
        this.initForm(info.productos);
      }, error => {
        console.error(error);
        this.loading = false;
      })
  }

  initForm(productos: Product[]): void {
    this.productos = productos.map(x => {
      return {
        idVendeproducto: x.vendeproducto?.id,
        idProducto: x.id,
        producto: x,
        selected: !!x.vendeproducto?.id,
        comentario: x.vendeproducto?.comentario
      }
    });
  }

}
