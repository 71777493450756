import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, timeout } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { environment } from '../../environments/environment';
import { getTimestamp } from '../shared/utils/date.utils';

const CONTENT_TYPE_HEADER = 'Content-Type';
const CONTENT_TYPE_DEFAULT = 'application/json';
const AUTH_HEADER = 'Authorization';
const AUTH_DEFAULT = '';

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
  ) {
  }

  get(apiUrl: string, path: string, params?): Observable<any> {
    const reqOpts = {};
    const idLoader: string = this.getIdLoader(path);
    if (params) {
      reqOpts['params'] = params;
    }
//
    // return from(idLoader)
    //   .pipe(switchMap(() => this.http.get(`${ apiUrl }${ path }`, reqOpts).pipe(
    //       timeout(environment.timeout),
    //       catchError(err => throwError(err)),
    //       finalize(() => {
    //       }),
    //     )));

    return this.http.get(`${ apiUrl }${ path }`, reqOpts).pipe(
          timeout(environment.timeout),
          catchError(err => throwError(err)),
          finalize(() => {
          }),
        );
  }

  post(apiUrl: string, path: string, body, params?): Observable<any> {
    const reqOpts = { headers: this.getHeaders()};
    const idLoader: string = this.getIdLoader(path);
    if (params) {
      reqOpts['params'] = this.setQueryParams(params);
    }
    return from(this.loader.show(idLoader))
      .pipe(switchMap(() => {
        return this.http.post(
          `${apiUrl}${path}`,
          JSON.stringify(body),
          reqOpts,
        ).pipe(
          timeout(environment.timeout),
          catchError(err => throwError(err)),
          finalize(() => {
            this.loader.hide(idLoader);
          }),
        );
      }));
  }

  postCourse(apiUrl: string, path: string, body, params?){
    const reqOpts = { headers: this.getHeaders()};
    return this.http.post<any>(`${apiUrl}${path}`, body, reqOpts);
  }
  /*post(apiUrl: string, path: string, body, params?): Observable<any> {

    const reqOpts = { };
    const idLoader: string = this.getIdLoader(path);
    if (params) {
      reqOpts[' params '] = this.setQueryParams(params);
    }
    return from(this.loader.show(idLoader))
      .pipe(switchMap(() => this.http.post(
          `${ apiUrl }${ path }`,
          JSON.stringify(body),
          reqOpts,
        ).pipe(
          timeout(environment.timeout),
          catchError(err => throwError(err)),
          finalize(() => {
            this.loader.hide(idLoader);
          }),
        )));
  }

  delete(apiUrl: string, path): Observable<any> {
    const idLoader: string = this.getIdLoader(path);

    return from(this.loader.show(idLoader))
      .pipe(switchMap(() => this.http.delete(
          `${apiUrl}${path}`
        ).pipe(
          timeout(environment.timeout),
          catchError(err => throwError(err)),
          finalize(() => {
            this.loader.hide(idLoader);
          }),
        )));
  }*/
  // postFile(apiUrl: string, path: string, body, params?): Observable<any> {
  //   const reqOpts = { headers: this.getHeaders(), withCredentials: true };
  //   const idLoader: string = this.getIdLoader(path);
  //
  //   if (params) {
  //     reqOpts[' params ']  = this.setQueryParams(params);
  //   }
  //
  //   return from(this.loader.show(idLoader))
  //     .pipe(switchMap(() => {
  //       return this.http.post(
  //         `${apiUrl}${path}`,
  //         body,
  //         reqOpts,
  //       ).pipe(
  //         timeout(environment.timeout),
  //         catchError(err => throwError(err)),
  //         finalize(() => {
  //           this.loader.hide(idLoader);
  //         }),
  //       );
  //     }));
  // }

  put(apiUrl: string, path: string, body, params?): Observable<any> {
    const reqOpts = { headers: this.getHeaders(), withCredentials: true };
    const idLoader: string = this.getIdLoader(path);

    if (params) {
      reqOpts[' params ']  = this.setQueryParams(params);
    }

    return from(this.loader.show(idLoader))
      .pipe(switchMap(() => {
        return this.http.put(
          `${apiUrl}${path}`,
          JSON.stringify(body),
          reqOpts,
        ).pipe(
          timeout(environment.timeout),
          catchError(err => throwError(err)),
          finalize(() => {
            this.loader.hide(idLoader);
          }),
        );
      }));

  }
  //
  // patch(apiUrl: string, path: string, body, params?): Observable<any> {
  //   const reqOpts = { headers: this.getHeaders(), withCredentials: true };
  //   if (params) {
  //     reqOpts[' params ']  = this.setQueryParams(params);
  //   }
  //   const idLoader: string = this.getIdLoader(path);
  //   return from(this.loader.show(idLoader))
  //     .pipe(switchMap(() => {
  //       return this.http.patch(
  //         `${apiUrl}${path}`,
  //         JSON.stringify(body),
  //         reqOpts,
  //       ).pipe(
  //         timeout(environment.timeout),
  //         catchError(err => throwError(err)),
  //         finalize(() => {
  //           this.loader.hide(idLoader);
  //         }),
  //       );
  //     }));
  //
  // }
  //
  // delete(apiUrl: string, path): Observable<any> {
  //   const idLoader: string = this.getIdLoader(path);
  //
  //   return from(this.loader.show(idLoader))
  //     .pipe(switchMap(() => {
  //       return this.http.delete(
  //         `${apiUrl}${path}`,
  //         { headers: this.getHeaders(), withCredentials: true },
  //       ).pipe(
  //         timeout(environment.timeout),
  //         catchError(err => throwError(err)),
  //         finalize(() => {
  //           this.loader.hide(idLoader);
  //         }),
  //       );
  //     }));
  // }

  private setQueryParams(params): HttpParams {
    let queryParams = new HttpParams();
    Object.keys(params).forEach(param => {
      queryParams = queryParams.append(param, params[param]);
    });
    return queryParams;
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers
      .set(CONTENT_TYPE_HEADER, CONTENT_TYPE_DEFAULT)
      .set(AUTH_HEADER, AUTH_DEFAULT)
    ;

  }

  /**
   * To be used as 'ID' in the loader service
   */
  private getIdLoader(path: string): string {
    return `${ path }-${ getTimestamp() }`;
  }

}
