import {Injectable} from '@angular/core';
import {ApiService} from '../core/api.service';
import {Observable} from 'rxjs';
import {apiBaseUrl} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Proveedor} from "../models";

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  path = '/proveedores';

  info?: any;

  constructor(private apiService: ApiService) {
  }


  getProvider(): Observable<Array<any>> {
    return this.apiService.get(apiBaseUrl, `${this.path}/actual`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }


  async getInfo(): Promise<any> {
    if (this.info) {
      return this.info;
    }
    const response = await this.apiService.get(apiBaseUrl, `${this.path}/info`).toPromise();
    if(!response) {
      return null;
    }
    this.info = response.payload;
    return this.info;
  }

  getProviders(filter: any): Observable<Array<any>> {
    return this.apiService.get(apiBaseUrl, `${this.path}/servicios`, this.apiService.setQueryParams(filter))
      .pipe(
        map((response) => {
          return response.payload?.proveedores;
        })
      );
  }

  findProvider(id: number): Observable<any> {
    return this.apiService.get(apiBaseUrl, `${this.path}/servicios/` + id)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  saveProvider(proveedor: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.path}/guardar`, proveedor).pipe(
      map((response) => response.payload)
    );
  }

  saveProviderImages(proveedor: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.path}/imagenes`, proveedor).pipe(
      map((response) => response.payload)
    );
  }

  addUserEvent(data: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.path}/evento`, data);
  }

  sortImages(id: number, items: any[]): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.path}/${id}/ordenar-imagenes`, items);
  }
}
