import {Component, Input, OnChanges, OnInit} from '@angular/core';

export enum BannerVendedorType {
  PRODUCTOS_VENDEDOR_WEB = "PRODUCTOS_VENDEDOR_WEB",
  CURSOS_VENDEDOR_WEB = "CURSOS_VENDEDOR_WEB",
  TERCERIZADOS_PERFIL_SUPERIOR = "TERCERIZADOS_PERFIL_SUPERIOR",
  TERCERIZADOS_PERFIL_INFERIOR = "TERCERIZADOS_PERFIL_INFERIOR",
}

@Component({
  selector: 'app-banner-vendedor-chips',
  templateUrl: './banner-vendedor-chips.component.html',
  styleUrls: ['./banner-vendedor-chips.component.scss']
})
export class BannerVendedorChipsComponent implements OnInit {

  @Input() vendedorId?: number;
  @Input() type: BannerVendedorType;

  constructor() { }

  ngOnInit(): void {
  }


}
