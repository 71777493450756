<ng-container *ngIf="uuid">
  <div class="accordion" [id]="'sidebarAccordion'+uuid">

    <div class="accordion-item" *ngIf="items?.length">
      <h2 class="accordion-header" [id]="'filterHeading'+uuid">
    <span
      class="cursor-pointer accordion-button"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#filterCollapse'+uuid"
      aria-expanded="true"
      [attr.aria-controls]="'filterCollapse'+uuid"
      [innerText]="name">
    </span>
      </h2>
      <div [id]="'filterCollapse' + uuid" class="accordion-collapse collapse show" [attr.aria-labelledby]="'filterHeading'+uuid" [attr.data-bs-parent]="'#sidebarAccordion'+uuid">
        <div class="cursor-pointer d-flex mr-t-10 " *ngFor="let p of (expanded ? items : items.slice(0, min))" (click)="selected(p)">
          <input class="form-check-input check-size" type="checkbox" value="{{p[key]}}"/>
          <span class="ms-1 font-12">{{p[value]|uppercase}}</span>
        </div>

        <div class="pb-3" click-stop-propagation>
          <p *ngIf="items.length>min && !expanded" class="btn btn-link mb-0" (click)="expanded = true">Mostrar más</p>
          <p *ngIf="items.length>min && expanded" class="btn btn-link mb-0" (click)="expanded = false">Ocultar</p>
        </div>

      </div>
    </div>
  </div>
</ng-container>
