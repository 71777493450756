<ul class="list-inline mb-0" [ngClass]="customCss" >

  <li class="list-inline-item about-us" *ngIf="position ==='header'">
    <a
      [routerLink]="['about-us']"
       class="d-flex align-items-center nav-link btn btn-outline-secondary">
      SOBRE NOSOTROS
    </a>
  </li>


  <li class="list-inline-item ">
    <a target="_blank"
       class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret p-0" href="https://www.facebook.com/profile.php?id=100090341402199" role="button"
       aria-expanded="false">
      <img src="{{position ==='header'?'assets/social-nets/facebook2.png':'assets/social-nets/facebook-footer.png'}}">
    </a>
  </li>
  <li class="list-inline-item">
    <a target="_blank"
       class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret p-0" href="https://www.instagram.com/todo.odonto.ok/" role="button"
       aria-expanded="false">
      <img src="{{position ==='header'?'assets/social-nets/instagram2.png':'assets/social-nets/instagram-footer.png'}}" >
    </a>
  </li>
  <li class="list-inline-item">
    <a target="_blank"
       class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret p-0" href="https://www.youtube.com/channel/UCimYfQDM1ydGWBjD383y_hg" role="button"
       aria-expanded="false">
      <img src="{{position ==='header'?'assets/social-nets/youtube2.png':'assets/social-nets/youtube-footer.png'}}" >
    </a>
  </li>
  <li class="list-inline-item">
    <a target="_blank"
       class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret  p-0" href="https://twitter.com/todoodontook" role="button"
       aria-expanded="false">
      <img src="{{position ==='header'?'assets/social-nets/twitter2.png':'assets/social-nets/twitter-footer.png'}}">
    </a>
  </li>
</ul>

