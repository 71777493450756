
<div class="section-container" *ngIf="vendedores && vendedores.length">
  <div class="p-1">
    <div class="historial">
      <div *ngFor="let v of vendedores" class="card card-historial pointer bg-dark" (click)="search(v)"
      >
        <img [src]="v.logo ? v.logo : v.imagen"  class="img-fluid" [alt]="v.razonSocial">
      </div>
    </div>
  </div>
</div>
