<app-carousel  [customCss]="'b-0 p-0'" [id]="'carouselPP'" [mode]="'slider'" [type]="'PRODUCTOS_PRINCIPAL'" [quantity]="1"></app-carousel>

<div class="row pt-3" *ngIf="faqs && faqs.length > 0">
  <div class="col-12 col-lg-12 mx-auto">
    <div class="text-center">
      <h5 class="mb-0 text-uppercase">Preguntas Frecuentes (FAQ<small class="text-lowercase">s</small>)</h5>
      <hr/>
    </div>
    <div>
      <div *ngFor="let faq of faqs; index as i" class="card">
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse'+i" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne">
                {{ faq.pregunta }}
              </button>
            </h2>
            <div [attr.id]="'collapse'+i" class="accordion-collapse collapse show"
                 aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body" [innerHTML]="faq.respuesta|safeHTML"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end row-->
