
<div class="hidden-desktop">
  <carousel class="carousel-html-content" *ngIf="specialty && specialty.length"
            [cellsToShow]="12"
            [objectFit]="'cover'"
            [cellWidth]="'70%'"
            [height]="70">
    <div class="carousel-cell" *ngFor="let item of specialty" (click)="search(item)">
      <img  [src]="baseUrl + item.imagen" alt="..." />
    </div>
  </carousel>

</div>
<div class="hidden-mobile">
  <carousel class="carousel-html-content" *ngIf="specialty && specialty.length"
            [cellsToShow]="5"
            [objectFit]="'cover'"
            [cellWidth]="'70%'"
            [height]="70">
    <div class="carousel-cell" *ngFor="let item of specialty" (click)="search(item)">
      <img  [src]="baseUrl + item.imagen" alt="..." />
    </div>
  </carousel>

</div>
