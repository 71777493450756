const translateAuthError = {
  'auth/claims-too-large': 'La carga útil del reclamo que se entregó a setCustomUserClaims() supera el tamaño máximo de 1,000 bytes.',
  'auth/invalid-argument': 'Se proporcionó un argumento no válido para un método de autenticación. El mensaje de error debería incluir información adicional.',
  'auth/invalid-claims': '	Los atributos personalizados del reclamo que se entregaron a setCustomUserClaims() no son válidos.',
  'auth/invalid-disabled-field': 'El valor que se proporcionó para la propiedad de usuario disabled no es válido. Debe ser un booleano.',
  'auth/invalid-display-name': 'El valor que se proporcionó para la propiedad de usuario displayName no es válido. Debe ser una cadena de texto que no esté vacía.',
  'auth/invalid-email-verified': 'El valor que se proporcionó para la propiedad de usuario emailVerified no es válido. Debe ser un booleano.',
  'auth/invalid-email': 'El valor que se proporcionó para la propiedad de usuario email no es válido. Debe ser una dirección de correo electrónico válida.',
  'auth/invalid-page-token': 'El token de página siguiente que se entregó en listUsers() no es válido. Debe ser una cadena de texto válida que no esté vacía.',
  'auth/invalid-password': 'El valor que se proporcionó para la propiedad de usuario password no es válido. Debe ser una cadena de texto con al menos seis caracteres.',
  'auth/invalid-phone-number': 'El valor que se proporcionó para phoneNumber no es válido. Debe ser una cadena de texto de identificador que no esté vacía y que cumpla con el estándar E.164.',
  'auth/invalid-photo-url': 'El valor que se proporcionó para la propiedad de usuario photoURL no es válido. Debe ser una URL.',
  'auth/invalid-uid': 'El uid proporcionado debe ser una cadena de texto no vacía con un máximo de 128 caracteres.',
  'auth/missing-uid': 'Se requiere un identificador uid para la operación actual.',
  'auth/reserved-claims': 'Uno o más de los reclamos personalizados de usuarios que se entregaron a setCustomUserClaims() están reservados.',
  'auth/uid-alread-exists': 'Otro usuario ya utiliza el uid proporcionado.',
  'auth/email-already-exists': 'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
  'auth/user-not-found': 'No tenemos registrada ninguna cuenta con este correo electrónico.',
  'auth/operation-not-allowed': 'El proveedor de acceso proporcionado está inhabilitado para tu proyecto de Firebase.',
  'auth/invalid-credential': 'La credencial utilizada para autenticar los SDK de Admin no se puede utilizar para realizar la acción deseada. ',
  'auth/phone-number-already-exists': 'Otro usuario ya está utilizando el phoneNumber proporcionado.',
  'auth/project-not-found': '	No se encontró ningún proyecto de Firebase para la credencial que se usó para inicializar los SDK de administrador.',
  'auth/insufficient-permission': '	La credencial que se usó para inicializar el SDK de Admin no tiene permisos suficientes para acceder al recurso de autenticación solicitado. ',
  'auth/internal-error': '	El servidor de autenticación encontró un error inesperado al tratar de procesar la solicitud.',
  'auth/wrong-password': 'La contraseña ingresada es incorrecta o la cuenta no tiene contraseña.',
  'auth/argument-error': 'El correo electrónico debe ser una cadena válida',
  'auth/email-already-in-use': 'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
  'auth/redirect-cancelled-by-user': '',
  'auth/network-request-failed': 'Parece que no tenés conexión a internet',
  'auth/weak-password': 'La contraseña debe tener al menos 6 caracteres'
};

export const uploadErrors = {
  'storage/unknown': 'Ocurrió un error desconocido.',
  'storage/object-not-found': 'No existe ningún objeto en la referencia deseada.',
  'storage/bucket-not-found': 'No se configuró ningún bucket para Cloud Storage.',
  'storage/project-not-found': 'No se configuró ningún proyecto para Cloud Storage.',
  'storage/quota-exceeded': 'Se superó la cuota del bucket de Cloud Storage. Si estás en el nivel sin costo, deberás actualizar a un plan pagado. Si estás en un plan pagado, comunícate con el personal de asistencia de Firebase.',
  'storage/unauthenticated': 'El usuario no se autenticó. Vuelve a intentarlo después de realizar la autenticación.',
  'storage/unauthorized': 'El usuario no está autorizado para realizar la acción deseada. Consulta las reglas de seguridad para asegurarte de que sean correctas.',
  'storage/retry-limit-exceeded': 'Se superó el límite de tiempo máximo permitido para una operación (de carga, descarga, eliminación, etc.). Vuelve a subirlo.',
  'storage/invalid-checksum': 'El archivo del cliente no coincide con la suma de verificación del archivo que recibió el servidor. Vuelve a subirlo.',
  'storage/canceled': 'El usuario canceló la operación.',
  'storage/invalid-event-name': 'Se proporcionó un nombre de evento no válido. El modo debe ser uno de los siguientes: `running`, `progress` o `pause`.',
  'storage/invalid-url': 'Se proporcionó una URL no válida a refFromURL(). Debes usar el formato gs://bucket/object o https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=&ltTOKEN>',
  'storage/invalid-argument': 'El argumento que se pase a put() debe ser un arreglo de tipo `File`, `Blob` o `UInt8`. El argumento que se pase a putString() debe ser una string sin procesar `Base64` o `Base64URL`.',
  'storage/no-default-bucket': 'No se estableció ningún bucket en la propiedad storageBucket de tu configuración.',
  'storage/cannot-slice-blob': 'Ocurre generalmente cuando hubo un cambio en el archivo local (se borró, se volvió a guardar, etc.) Intenta volver a subirlo después de verificar que el archivo no haya cambiado.',
  'storage/server-file-wrong-size': 'El archivo del cliente no coincide con el tamaño del archivo que recibió el servidor. Vuelve a subirlo.'
}

export default translateAuthError;
