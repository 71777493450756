import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value?: number): string {
    if (value === undefined || value === null) {
      return '';
    }
    const isInteger = Number.isInteger(value);

    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: isInteger ? 0 : 2, // Sin decimales si es entero, con 2 si no
      maximumFractionDigits: 2
    }).format(value);
  }

}
