<div class="d-flex  justify-content-between mr-l-r">
  <div>
    <h5 class="mb-0 pt-4 text-uppercase">
      <i class="lni {{iconTitle}} mr-r-5"></i>{{title}}</h5>
    <p class="card-text">
      {{description}}
    </p>
  </div>
  <div class="d-flex">
    <button class="btn btn-primary back" (click)="addCourse()" *ngIf="showButton">
      <i class="bx {{iconButton}}"></i>{{action}}
    </button>
    <button class="btn btn-outline-primary back" (click)="backCourse()" *ngIf="showButtonBack">Volver
    </button>
    <button class="btn btn-danger back" (click)="removeCart()" *ngIf="showButtonRemove"><i class="lni lni-trash mr-r-5"></i>
    </button>
  </div>
</div>
<hr/>
