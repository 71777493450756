import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'format2date',
})
export class Format2datePipe implements PipeTransform {

  constructor() {
  }

  transform(strDate: string): Date {
    const date = new Date();
    date.setFullYear(parseInt(strDate.substr(6, 4), 0),
      parseInt(strDate.substr(3, 2), 0) - 1, parseInt(strDate.substr(0, 2), 0) );
    return date;
  }
}
