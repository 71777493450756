<div class="price-card">
  <div class="text-center p-1 bx-img">
    <img
      src="{{vendedor.imagen || defaultAvatar}}"
      [alt]="vendedor.razonSocial">
  </div>
  <div class="card-body text-center">
    <h5 class="card-title font-18">
      <b [innerText]="vendedor.razonSocial"></b>
    </h5>
    <ng-content></ng-content>
  </div>
</div>
