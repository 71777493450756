<google-map height="500px" width="100%" [center]="center" [zoom]="zoom" #googleMap (mapClick)="clickedMap()">

  <map-info-window #infoWindow >
    <app-price-card-seller-x [vendedor]="selected" *ngIf="selected">

      <div style="min-width: 400px"></div>

      <div class="text-center my-4">
        <button
          *ngIf="selected.vende?.curso"
          routerLink="/courses" [queryParams]="{vendedor: selected.id, nombre: selected.razonSocial}"
          class="btn btn-outline-primary mx-2">
          Ver Cursos
        </button>

        <button
          *ngIf="selected.vende?.producto"
          routerLink="/product" [queryParams]="{vendedor: selected.id, nombre: selected.razonSocial}"
          class="btn btn-outline-primary mx-2">
          Ver Productos
        </button>

        <button
          *ngIf="selected.vende?.servicio"
          routerLink="/providers/{{selected.idProveedor}}"
          class="btn btn-outline-primary mx-2">
          Ver Servicios
        </button>

      </div>


      <div class="text-center mt-4">
        <app-send-whatsapp
          class="mb-3"
          *ngIf="selected.whatsappConfig"
          [config]="selected.whatsappConfig">
        </app-send-whatsapp>
      </div>
    </app-price-card-seller-x>
  </map-info-window>
  <ng-container *ngFor="let seller of sellers; index as idx">

    <div class="map-marker">
      <map-marker
        [clickable]="true"
        [position]="seller.ubicacion"
        [options]="{ icon: seller.icon }"
        [title]="seller.razonSocial"
        #marker="mapMarker"
        (mapClick)="openInfoWindow(marker, seller)">
      </map-marker>
    </div>


  </ng-container>


</google-map>
