<p class="mb-0 mt-2 text-favorite" [class]="css" *ngIf="!value">Sin calificaciones</p>
<div style="display: inline-flex" [class]="css">
  <h5 class="mt-0 mb-1 mt-2" *ngIf="value > 0">
    <i class="bx bxs-star text-favorite" [class.text-secondary]="value < 1"></i>
    <i class="bx bxs-star text-favorite" [class.text-secondary]="value < 2"></i>
    <i class="bx bxs-star text-favorite" [class.text-secondary]="value < 3"></i>
    <i class="bx bxs-star text-favorite" [class.text-secondary]="value < 4"></i>
    <i class="bx bxs-star text-favorite" [class.text-secondary]="value < 5"></i>
  </h5>
  <label class="ms-2 mt-2" *ngIf="count > 0">{{count}} {{count > 1 ? 'calificaciones' : 'calificación'}}</label>
</div>
<br>
