import { Component, Input, OnInit } from '@angular/core';
import { baseUrlImg } from 'src/environments/environment';
import { Carousel, CarouselService } from "../../../components/carousel/carousel.service";

@Component({
  selector: 'app-banner-with-blinking',
  templateUrl: './banner-with-blinking.component.html',
  styleUrls: ['./banner-with-blinking.component.scss']
})
export class BannerWithBlinkingComponent implements OnInit {

  @Input() type: string;  // PRINCIPAL
  @Input() seller?: number;

  banners:Array<any>;
  currentIndex: number ;
  imageDisplays!: HTMLImageElement[];
  intervalId!: any;

  baseUrlImg = baseUrlImg;
  constructor(private carouselService: CarouselService) {

  }
  ngOnInit(): void {
    this.carouselService.getBannersByType(this.type, {seller: this.seller}).subscribe((resp: Array<Carousel>) => {
      if (resp.length > 0) {
        this.banners = resp;

        // Inicializar las imágenes de visualización
        this.imageDisplays = Array.from(document.querySelectorAll('.imageDisplay')) as HTMLImageElement[];

        // Cambiar la imagen cada 5 segundos, solo si hay banners disponibles
        if (this.banners.length > 0) {
          this.changeImage();
          this.intervalId = setInterval(this.changeImage.bind(this), 5000);
        }
      }
    });
  }


  changeImage() {
    // Añadir la clase de animación a todas las imágenes con la clase "imageDisplay"
    this.imageDisplays.forEach(imageDisplay => {
      imageDisplay.classList.add('image-fade-animation');
    });

    // Esperar un corto período para permitir que la animación se inicie
    setTimeout(() => {
      this.banners.forEach((banner, index) => {
        const imageUrl = this.baseUrlImg + banner.imagen;

        // Aplicar la imagen y animación a cada elemento imageDisplay
        const imageDisplay = this.imageDisplays[index];
        if (imageDisplay) {
          imageDisplay.src = imageUrl;
        }

        // Eliminar la clase de animación después de que termine la animación
        imageDisplay?.addEventListener('animationend', () => {
          imageDisplay.classList.remove('image-fade-animation');
        }, { once: true });
      });

      this.move(1);

    }, 50); // Ajusta el tiempo si es necesario
  }

  move(plus: number): void {
    if(this.currentIndex >= 0) {
      this.currentIndex = (this.currentIndex + plus) % this.banners.length;
    } else {
      this.currentIndex = 0;
    }
  }

  currentImageUrl(): string {
    const banner = this.banners[this.currentIndex];
    return banner ? this.baseUrlImg + banner.imagen : null;
  }

  currentLink(): string {
    const banner = this.banners[this.currentIndex];
    return banner?.link || ''; // Devolver la URL de enlace o una cadena vacía si no hay enlace
  }

  ngOnDestroy() {
    // Limpiar el intervalo cuando el componente se destruya
    clearInterval(this.intervalId);
  }

  next(): void {
    this.move(1);
  }

  prev(): void {
    this.move(-1);
  }


}
