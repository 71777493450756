<div class="modal-dialog modal-lg modal-dialog-centered">
  <div class="modal-content bg-dark">
    <div class="modal-header block">
      <div class="row">
        <div class="col-8">
          <h5 class="modal-title text-white">{{title}}</h5>
          <p class="description" *ngIf="!calificado">Contanos tu experiencia</p>
          <p class="description" *ngIf="calificado">Ya calificado</p>
          <div class="container">
            <div id="userRateInput"
                 class="starrating risingstar d-flex justify-content-end flex-row-reverse">
              <input [disabled]="!!calificado" type="radio" id="star5" name="rating" value="5" (click)="rate(5)"/><label
              for="star5" title="5 star" style="text-align: center;">
              <p>Excelente</p>
            </label>
              <input [disabled]="!!calificado" type="radio" id="star4" name="rating" value="4" (click)="rate(4)"/>
              <label for="star4" title="4 star"></label>
              <input [disabled]="!!calificado" type="radio" id="star3" name="rating" value="3" (click)="rate(3)"/>
              <label for="star3" title="3 star"></label>
              <input [disabled]="!!calificado" type="radio" id="star2" name="rating" value="2" (click)="rate(2)"/>
              <label for="star2" title="2 star"></label>
              <input [disabled]="!!calificado" type="radio" id="star1" name="rating" value="1" (click)="rate(1)"/>
              <label for="star1" title="1 star" style="text-align: center;">
                <p>Muy Mal</p>
              </label>
            </div>
            <div *ngIf="enterRateError" class="text-danger">
              {{ enterRateError }}
            </div>
          </div>
        </div>
        <div class="col-4 image" style="background-image: url({{image}});">
        </div>
      </div>
    </div>
    <div class="container">
      <form [formGroup]="rateObservationsForm" (ngSubmit)="submit()">
        <div class="modal-body">
          <div class="col-12">
							<textarea formControlName="input" type="text" class="form-control" id="inputEmailAddress"
                        [readOnly]="!!calificado"
                        placeholder="Ingrese observaciones" aria-label="With textarea"></textarea>
            <div style="text-align: right;">
              <small>Máximo 250 caracteres</small>
            </div>
          </div>
          <div class="text-danger" *ngIf="inputNoValido">
            {{ getError() }}
          </div>
        </div>
        <div class="modal-footer no-line centered">
          <button (click)="cancel()" type="button" class="btn btn-light"
                  data-bs-dismiss="modal"> Cerrar
          </button>
          <button type="submit" class="btn btn-dark" *ngIf="!calificado">Calificar!</button>
        </div>
      </form>
    </div>
  </div>
</div>
