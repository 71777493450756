import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Vendedor} from "../services/course.service";
import {MapInfoWindow, MapMarker} from "@angular/google-maps";
import {environment} from "../../environments/environment";
import {SendWhatsppConfig} from "../shared/send-whatsapp/send-whatsapp.component";

export interface SellerMarker extends Vendedor {
  icon?: any;
  whatsappConfig?: SendWhatsppConfig;
}

@Component({
  selector: 'app-sellers-map',
  templateUrl: './sellers-map.component.html',
  styleUrls: ['./sellers-map.component.scss']
})
export class SellersMapComponent implements OnInit, OnChanges {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @ViewChild(MapInfoWindow) map!: MapInfoWindow;

  @Input() sellers: SellerMarker[] = [];

  @Input() zoom ;
  @Input() center: google.maps.LatLngLiteral;

  selected?: SellerMarker;
  defaultAvatar = environment.defaultAvatar;

  constructor() { }

  ngOnInit(): void {
    this.loadInfo();
  }
  ngOnChanges(): void {
    this.loadInfo();
  }

  clickedMap() {
    this.selected = undefined;
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  openInfoWindow(map: any, marker: SellerMarker) {
    this.selected = marker;
    this.infoWindow.open(map);
  }
  loadInfo(): void {
    this.sellers = (this.sellers||[])
      .map(x => {
      return {
        ...x,
        icon: {
          url: x.imagen ?? this.defaultAvatar,
          scaledSize: { width: 50, height: 50 },
        },
        whatsappConfig: {
          css: 'w-100',
          title: 'Atención',
          description: 'Abriremos la aplicación de Whatsapp para que pueda contactarse directamente con ' + x.razonSocial,
          message: `Hola ${x.razonSocial}, te contacto desde Todo-Odonto`,
          phone: x.telefono,
          text: 'Contactar',
          callback: () => (console.log('Agregar evento de guardar click'))
         }
      }
    })

    setTimeout(() => {
      this.addStyles();
    }, 500);
    setTimeout(() => {
      this.addStyles();
    }, 2000);

  }

  addStyles(): void {
    const images = document.querySelectorAll(".gm-style img");
    images.forEach((img: any) => {
      if ((img).src.includes("todo-odonto") || img.src.includes("localhost")) {
        img.classList.add("pin-odonto");
      }
    });
  }

}
