import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SharedService} from 'src/app/shared/shared.service';
import {FileUploadService} from '../file-upload.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  fileInfos: Observable<any>;
  @Input() orderNumber: string;
  @Input() canUploadFiles: any;
  uploadedFiles: any[];
  canUpload: BehaviorSubject<boolean>;
  @Output() close: EventEmitter<string[]>;

  filePath: string;

  constructor(
    private fileUploadService: FileUploadService,
    private sharedService: SharedService,
  ) {
    this.close = new EventEmitter();
    this.canUpload = new BehaviorSubject(false);
  }

  ngOnInit(): void {
    $.getScript('./assets/plugins/fancy-file-uploader/jquery.ui.widget.js');
    // $.getScript('./assets/plugins/fancy-file-uploader/jquery.fileupload.js');
    $.getScript('./assets/plugins/fancy-file-uploader/jquery.iframe-transport.js');
    // $.getScript('./assets/plugins/fancy-file-uploader/jquery.fancy-fileupload.js');
    // $.getScript('./assets/plugins/Drag-And-Drop/imageuploadify.min.js');
    // $.getScript('./assets/js/custom-file-upload.js');
    this.filePath = environment.storage.chats.documents + this.orderNumber;
    this.sharedService.presentLoading();
    this.fileUploadService.getUploadedFileNames(this.filePath)
      .then((res) => {

        if (res.items.length > 0) {
          this.uploadedFiles = res.items.map(itemRef => {
            const fullPath = itemRef.fullPath;
            const name = itemRef.name;
            this.sharedService.closeLoading();

            return {fullPath, name};
          });
        } else {
          this.sharedService.closeLoading();
        }
      }).catch((error) => {
      this.sharedService.closeLoading();
      console.error(error);
    });
  }

  fileUpload(event): void {
    this.selectedFiles = event.target.files;
    this.selectedFiles && this.selectedFiles.length ? this.canUpload.next(null) : this.canUpload.next(false);
  }

  uploadFiles(): void {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      const fileName = file.name;
      this.sharedService.presentLoading();
      this.fileUploadService.uploadFile(this.filePath, fileName, file).then(() => {
        this.sharedService.closeLoading();
        this.backToChats(true);
      });
    }
  }

  downloadFile(fullPath: string): void {
    let blob;

    const [filePath, fileName] = fullPath.split('/');
    this.fileUploadService.downloadFile(filePath, fileName)
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'
        console.log(url);

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          blob = xhr.response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          link.remove();
        };

        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  async backToChats(filesUploaded: boolean): Promise<void> {
    if (filesUploaded) {
      const files = [];

      for (let i = 0; i < this.selectedFiles.length; i++) {
        const fileName = this.selectedFiles[i].name;
        files.push(fileName);
      }
      this.close.emit(files);
    } else {
      this.close.emit([]);
    }
  }
}
