import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-product',
  templateUrl: './sidebar-product.component.html',
  styleUrls: ['./sidebar-product.component.scss']
})
export class SidebarProductComponent implements OnInit {
  @Input() marcas: any;
  @Input() especialidades: any;
  @Input() categorias: any;
  @Input() tipoProductos: any;
  constructor() { }

  ngOnInit(): void {
  }

}
