import { Injectable } from '@angular/core';
import {apiBaseUrl, baseUrlImg} from 'src/environments/environment';
import { ApiService } from '../core/api.service';
import { Observable } from 'rxjs';
import { Usuario } from '../user-profile/user-profile.service';
import { map } from 'rxjs/operators';
import { ProductsRegister, ProductosUpdate } from './form-products-register/interface-products-register';
import { MisCursos } from './form-my-courses/interface-my-courses';
import { AvalesModalidades } from './form-add-course/interface-add-course';

export interface Province {
  id: number;
  nombre?: string;
  provincia?: string;
}

export interface Specialty {
  id: number;
  nombre: string;
  imagen: string;
  fullPath: string;
  visible?: boolean;
  selected?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FormService {

  url: string;

  constructor(private apiService: ApiService) {
    this.url = apiBaseUrl;
  }

  getProvinces(): Observable<Array<Province>> {
    return this.apiService.get(this.url, '/usuariosapp/provincias')
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getSpecialties(): Observable<Array<Specialty>>  {
    return this.apiService.get(this.url, '/usuariosapp/especialidades')
      .pipe(
        map((response) => {
          if(Array.isArray(response.payload)) {
            return response.payload.map(x => {
              return Object.assign({}, x, {fullPath: baseUrlImg + x.imagen});
            })
          }
          return response.payload;
        })
      );
  }

  registerAsBuyerSeller(userData: any): Observable<Array<Usuario>> {
    return this.apiService.post(this.url, '/usuariosapp/basica', userData)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  saveSpecialties(userData: any): Observable<Array<Usuario>> {
    return this.apiService.post(this.url, '/usuariosapp/especialidades', userData);
  }

  getUserProfile(): Observable<Array<Usuario>> {
    return this.apiService.post(this.url, '/usuariosapp/user', {})
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getGuaranteesModalities(): Observable<AvalesModalidades>  {
    return this.apiService.get(this.url, '/curso/get_info')
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  postCourse(course: any): Observable<any> {
    console.log(course);
    return this.apiService.post(this.url, '/curso/guardar', course)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateOrder(info: any): Observable<any> {
    return this.apiService.post(this.url, '/compra/modificarorden', info)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCourses(): Observable<MisCursos> {
    return this.apiService.get(this.url, '/curso/mis_cursos', {})
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  // getCourseById(id: number): Observable<MisCursos> {
  //   return this.apiService.get(this.url, '/curso/' + id, {})
  //     .pipe(
  //       map((response) => {
  //         return response.payload;
  //       })
  //     );
  // }

  getSellProducts(): Observable<ProductsRegister> {
    return this.apiService.get(this.url, '/productos/vendeproducto', {})
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  postSellProducts(products: ProductosUpdate): Observable<any> {
    return this.apiService.post(this.url, '/odontologo/vendeproducto', products)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}
