import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ToastrService} from "ngx-toastr";

export interface GoogleMapLocation {
  lat: number;
  lng: number;
  number: number;
  street: string;
  city: string;
  state: string;
  country: string;
  other: string;
  postalCode: string;
  formatted: string;
  type?: string;
  name?: string;
  locked: boolean;
}

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss'],
})
export class GoogleAutocomplete implements OnInit {
  @Output() emitLocation = new EventEmitter();
  @Input() isDisabled = false;
  @Input() placeholder = 'Buscar dirección';
  @Input() init? = '';
  @Input() optionsAC: any = {
    types: [],
    language: 'es',
    componentRestrictions: { country: ['ar', 'uy'] }
  };
  autocomplete = true;
  position: GoogleMapLocation = {} as GoogleMapLocation;

  constructor(
    private toastService: ToastrService,
  ) {}

  ngOnInit() {
    if(this.init) {
      this.position = {
        formatted: this.init,
        locked: true,
      } as GoogleMapLocation;
    }
  }

  setLock(): void {
    this.autocomplete = false;
    this.position = {} as GoogleMapLocation;
    this.emitLocation.emit(this.position);
    this.init = '';
    setTimeout(() => {
      this.autocomplete = true;
    })
  }

  handleAddressChange(addressComponents: any) {
    this.position = {} as GoogleMapLocation;
    this.position.lat = addressComponents?.geometry?.location?.lat();
    this.position.lng = addressComponents?.geometry?.location?.lng();
    if(!this.position.lat) {
      this.setLock();
      this.toastService.error('Ingresé una dirección exacta');
      return;
    }
    this.position.locked = !!this.position.lat;
    this.position.formatted = addressComponents.formatted_address;
    for (const component of addressComponents.address_components) {
      const componentType: string = component.types[0];
      switch (componentType) {
        case 'street_number':
          this.position.number = component.long_name
            ? parseInt(component.long_name)
            : 0;
          break;
        case 'route':
          this.position.street = component.long_name;
          break;
        case 'locality':
          this.position.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          this.position.state = component.short_name;
          break;
        case 'country':
          this.position.country = component.long_name;
          break;
        case 'administrative_area_level_2':
          this.position.other = component.long_name;
          break;
        case 'postal_code':
          this.position.postalCode = component.long_name;
          break;
      }
    }
    this.emitLocation.emit(this.position);
  }


  showError(error: any): void {
    this.toastService.error('No address available');
    console.error(error);
    this.emitLocation.emit(this.position);
  }
}
