import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiBaseUrl} from 'src/environments/environment';
import {ApiService} from '../core/api.service';
import {Order} from './orders';

export interface UserRate {
  comentario?: string;
  puntuacion: number;
  orden: number;
  tipo: string;
  vendedorId?: number;
  clienteId?: number;
  producto?: number;
  productoventa?: number;
  curso?: number;
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  url: string;

  constructor(private apiService: ApiService) {
    this.url = apiBaseUrl;
  }

  getOrders(ordersType: string): Observable<any> {
    return this.apiService.get(this.url, `/carrito/${ordersType}`);
  }

  getOrderById(orderId: number): Observable<Order> {
    return this.apiService.get(this.url, `/carrito/mis_compras/${orderId}`);
  }

  getUserDataById(userId: number): Observable<any> {
    return this.apiService.get(this.url, `/usuariosapp/by_id/${userId}`)
      .pipe(
        map((response) => response));
  }

  rateProduct(userRate: UserRate): Observable<any> {
    return this.apiService.post(this.url, `/odontologo/calificar`, userRate)
      .pipe(
        map((response) => response));
  }

}
