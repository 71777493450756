import { AfterContentChecked, AfterContentInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Specialty } from 'src/app/services/especialidad.service';
import { Usuario } from 'src/app/user-profile/user-profile.service';
import { FormService, Province } from '../form.service';
import { AuthService } from '../../auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { COMMON } from '../../shared/utils/common-texts.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
  styleUrls: ['./form-register.component.scss']
})
export class FormRegisterComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {

  @Input() steps = false;
  current = 0;

  ejecutoScript: boolean = false;
  ejecutoScriptCliente: boolean = false;
  isClient: boolean = false;
  isSeller: boolean = false;
  clientProvinces: Province[];
  serviceSubject: Subject<number>;
  sellerProvinces: Province[];
  specialties: Specialty[];
  errorNombre: string;
  errorApellido: string;
  errorCUIT: string;
  itemsPaymentOptions: string[];
  itemsShippingOptions: string[];
  userProfile: any;

  subscriptions: Subscription[];

  registerForm = this.fb.group({
    // Usuario
    email: [''],
    nombre: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*')]],
    apellido: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*')]],
    telefono: ['', Validators.pattern('^[0-9]*')],
    opcionesPago: [''],
    opcionesEnvio: ['']
  });
  hasProvinces: boolean;
  hasSpecialties: boolean;
  showForm: boolean;

  constructor(private fb: FormBuilder,
              private router: Router,private toastService: ToastrService,

              private formService: FormService,
              private authService: AuthService) {
    this.clientProvinces = [];
    this.serviceSubject = new Subject();
    this.serviceSubject.next(0);
    this.sellerProvinces = [];
    this.specialties = [];
    this.hasProvinces = false;
    this.hasSpecialties = false;
    this.showForm = false;
    this.subscriptions = [];
  }

  ngOnInit(): void {
    Promise.all([
      $.getScript('./assets/plugins/select2/select2.min.js'),
      $.getScript('./assets/js/custom-select2.js')
    ]).then(() => {
      this.userProfile = this.authService.loadStorage('userProfile');

      if (this.userProfile) {
        console.log(this.userProfile);

        this.isClient = this.userProfile.comprador ;

        if (this.isClient) {
          this.alterClient(this.isClient);
          $("#flexSwitchCheckCheckedClient").prop("checked", true);
        }

        this.isSeller = this.userProfile.vendedor ;
        console.log(this.isSeller);

        if (this.isSeller) {
          this.alterSeller(this.isSeller);
          $("#flexSwitchCheckCheckedSeller").prop("checked", true);

          if (this.userProfile.opcionesPago) {
            this.itemsPaymentOptions = this.userProfile.opcionesPago.split(';');
          }

          if (this.userProfile.opcionesEnvio) {
            this.itemsShippingOptions = this.userProfile.opcionesEnvio.split(';');
          }

        }

        this.registerForm.patchValue(this.userProfile);
        this.finishLoading();
      }
    });

    if (this.steps) {
      $.getScript('./assets/plugins/smartwizard/dist/js/jquery.smartWizard.min.js');
      $.getScript('./assets/js/form-register-smartWizard.js');
      this.showForm = true;
    }
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    (<any>$('.choose_usr_email')).select2({
      tags: true
    });
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.

    if (this.isClient) {
      if ((<any>$('#es')).length !== 0 && !this.ejecutoScriptCliente) {
        (<any>$('#es')).select2({
          tags: false
        });

        this.ejecutoScriptCliente = true;
      }
      $("#flexSwitchCheckCheckedClient").prop("checked", true);
    } else {
      this.ejecutoScriptCliente = false;
    }

    if (this.isSeller) {
      if ((<any>$('.po')).length !== 0 && !this.ejecutoScript) {
        (<any>$('.po')).select2({
          tags: true
        });

        (<any>$('.so')).select2({
          tags: true
        });

        this.ejecutoScript = true;
      }
      $("#flexSwitchCheckCheckedSeller").prop("checked", true);
    } else {
      this.ejecutoScript = false;
    }

  }

  alterClient(isClient: boolean, btnClick: boolean = false) {
    this.isClient = isClient;

    if (this.isClient) {
      (<any>$('#es')).select2({
        data: this.userProfile.especialidad,
        tags: false
      });
      this.registerForm.addControl('matricula', new FormControl(this.userProfile.matricula, Validators.required));
      this.registerForm.addControl('especialidad', new FormControl(this.userProfile.especialidad, Validators.required));
      this.registerForm.addControl('idProvinciaCliente', new FormControl(this.userProfile.idProvinciaCliente, Validators.required));
      this.registerForm.addControl('localidadCliente', new FormControl(this.userProfile.localidadCliente, Validators.required));
      this.registerForm.addControl('direccionCliente', new FormControl(this.userProfile.direccionCliente, Validators.required));
      this.registerForm.addControl('cpCliente', new FormControl(this.userProfile.cpCliente, Validators.required));

      this.subscriptions.push(
        this.formService.getSpecialties().subscribe((resp: Array<Specialty>) => {
          console.log(resp);

          if (resp.length > 0) {
            this.specialties = resp;
            console.log(this.specialties);
            this.hasSpecialties = true;
          }
        })
      );

      if (!this.hasProvinces) {
        this.subscriptions.push(
          this.formService.getProvinces().subscribe((resp: Array<Province>) => {
            console.log(resp);

            if (resp.length > 0) {
              this.clientProvinces = resp;
              this.sellerProvinces = JSON.parse(JSON.stringify(this.clientProvinces));
              this.registerForm.controls.idProvinciaCliente.setValue(this.userProfile.idProvinciaCliente);
              this.hasProvinces = true;
              this.isSeller ? this.subscriptions.push( this.serviceSubject.subscribe(resp => {
                if (resp === 1) {
                  this.serviceSubject.next(2);
                }
              })) : this.serviceSubject.next(2);
            }
          })
        )
      } else {
        if ( btnClick && this.isSeller ) {
          this.serviceSubject.next(2);
        } else {
          this.serviceSubject.next(1);
        }
      }
    } else {
      this.hasSpecialties = false;

      this.registerForm.removeControl('matricula');
      this.registerForm.removeControl('especialidad');
      this.registerForm.removeControl('idProvinciaCliente');
      this.registerForm.removeControl('localidadCliente');
      this.registerForm.removeControl('direccionCliente');
      this.registerForm.removeControl('cpCliente');
    }
  }

  alterSeller(isSeller: any, btnClick: boolean = false) {

    if (isSeller) {
      this.registerForm.addControl('particular', new FormControl(Number(this.userProfile.particular), Validators.required));
      this.registerForm.addControl('cuit', new FormControl(this.userProfile.cuit || '', [Validators.required, Validators.pattern('^[0-9]*')]));
      this.registerForm.addControl('razonSocial', new FormControl(this.userProfile.razonSocial, Validators.required));
      this.registerForm.addControl('telefonoVendedor', new FormControl(this.userProfile.telefonoVendedor, [Validators.required, Validators.pattern('^[0-9]*')]));
      this.registerForm.addControl('idProvinciaVendedor', new FormControl(this.userProfile.idProvinciaVendedor, Validators.required));
      this.registerForm.addControl('localidadVendedor', new FormControl(this.userProfile.localidadVendedor, Validators.required));
      this.registerForm.addControl('direccionVendedor', new FormControl(this.userProfile.direccionVendedor, Validators.required));
      this.registerForm.addControl('cpVendedor', new FormControl(this.userProfile.cpVendedor, Validators.required));
      this.registerForm.addControl('opcionesPago', new FormControl(this.userProfile.opcionesPago, Validators.required));
      this.registerForm.addControl('opcionesEnvio', new FormControl(this.userProfile.opcionesEnvio, Validators.required));
      this.registerForm.addControl('montoEnvioGratis', new FormControl(this.userProfile.montoEnvioGratis, Validators.required));
      this.registerForm.get('montoEnvioGratis').setValue(10);

      if (!this.hasProvinces) {
        this.subscriptions.push(
          this.formService.getProvinces().subscribe((resp: Array<Province>) => {

            if (resp.length > 0) {
              this.clientProvinces = resp;
              this.sellerProvinces = JSON.parse(JSON.stringify(this.clientProvinces));
              this.registerForm.controls.idProvinciaVendedor.setValue(this.userProfile.idProvinciaVendedor);
              this.hasProvinces = true;

              if ( btnClick && this.isClient ) {
                this.serviceSubject.next(2);
              } else {
                this.serviceSubject.next(1);
              }

              this.isSeller = isSeller;
            }
          })
        );
      } else {
        this.isSeller = isSeller;

        if ( btnClick && this.isClient ) {
          this.serviceSubject.next(2);
        } else {
          this.serviceSubject.next(1);
        }
      }

    } else {
      this.isSeller = isSeller;
      this.registerForm.removeControl('particular');
      this.registerForm.removeControl('cuit');
      this.registerForm.removeControl('razonSocial');
      this.registerForm.removeControl('telefonoVendedor');
      this.registerForm.removeControl('idProvinciaVendedor');
      this.registerForm.removeControl('localidadVendedor');
      this.registerForm.removeControl('direccionVendedor');
      this.registerForm.removeControl('cpVendedor');
      this.registerForm.removeControl('opcionesPago');
      this.registerForm.removeControl('opcionesEnvio');
      this.registerForm.removeControl('montoEnvioGratis');
    }

  }

  /******************************************************************/
  /************************** Validaciones **************************/
  /******************************************************************/

  // Usuarios
  public get nombreNoValido(): boolean {
    let objInput: AbstractControl = this.registerForm.get('nombre');
    let name: string = (objInput.value || '').toString().trim();

    if (name === '' && objInput.touched) {
      this.errorNombre = 'El nombre no puede ser vacío';
      return true;
    } else if (objInput.hasError('minlength') && objInput.touched) {
      this.errorNombre = 'El nombre debe tener al menos 2 letras';
      return true;
    } else if (objInput.hasError('pattern') && objInput.touched) {
      this.errorNombre = 'Solo carateres alfabéticos';
      return true;
    } else if (objInput.invalid && objInput.touched) {
      this.errorNombre = 'Ingrese nombre';
    }

    return false;
  }

  public get apellidoNoValido(): boolean {
    let objInput: AbstractControl = this.registerForm.get('apellido');
    let lastName: string = (objInput.value || '').toString().trim();

    if (lastName === '' && objInput.touched) {
      this.errorApellido = 'El apellido no puede ser vacío';
      return true;
    } else if (objInput.hasError('pattern') && objInput.touched) {
      this.errorApellido = 'Solo carateres alfabéticos';
      return true;
    } else if (objInput.invalid && objInput.touched) {
      this.errorApellido = 'Ingrese apellido';
    }

    return false;

  }

  public get telefonoNoValido(): boolean {
    let objInput: AbstractControl = this.registerForm.get('telefono');

    return objInput.hasError('pattern') && objInput.touched;
  }

  // Clientes
  public get matriculaNoValida(): boolean {
    return this.registerForm.get('matricula').invalid && this.registerForm.get('matricula').touched;
  }

  public get especialidadNoValida(): boolean {
    let options = [];
    let obj = this.registerForm.get('especialidad');

    if (obj && this.isClient && this.ejecutoScriptCliente) {
      options = (<any>$('#es')).select2('data');
    }

    if (this.isClient) {
      if (options.length > 0) {
        obj.markAsTouched();
        this.registerForm.value.especialidad = options;
        this.registerForm.controls['especialidad']?.setErrors(null);
      } else {
        this.registerForm.controls['especialidad']?.setErrors({ 'invalid': true });
      }
    } else {
      this.registerForm.controls['especialidad']?.setErrors(null);
    }

    return (options.length > 0 ? false : true) && obj.touched;
  }

  public get idProvinciaClienteNoValida(): boolean {
    return this.registerForm.get('idProvinciaCliente').invalid && this.registerForm.get('idProvinciaCliente').touched;
  }

  public get localidadClienteNoValido(): boolean {
    return this.registerForm.get('localidadCliente').invalid && this.registerForm.get('localidadCliente').touched;
  }

  public get direccionClienteNoValido(): boolean {
    return this.registerForm.get('direccionCliente').invalid && this.registerForm.get('direccionCliente').touched;
  }

  public get cpClienteNoValido(): boolean {
    return this.registerForm.get('cpCliente').invalid && this.registerForm.get('cpCliente').touched;
  }

  // Vendedor
  public get particularNoValido(): boolean {
    return this.registerForm.get('particular').invalid && this.registerForm.get('particular').touched;
  }

  public get cuitNoValid(): boolean {
    let objInput: AbstractControl = this.registerForm.get('cuit');

    if (!this.verifyCuit(objInput.value) && this.registerForm.get('cuit').touched) {
      this.errorCUIT = 'CUIT no válido';
      return true;
    } else if (objInput.invalid && objInput.touched) {
      this.errorCUIT = 'Ingrese CUIT';
      return true;
    }

    return false;
  }

  verifyCuit = (cuit) => {
    if (cuit.length !== 11) {
      return false;
    }

    let acumulado = 0;
    let digitos = cuit.split('');
    let digito = parseInt(digitos.pop());

    for (let i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }

    return digito === verif;
  };

  public get razonSocialNoValida(): boolean {
    return this.registerForm.get('razonSocial').invalid && this.registerForm.get('razonSocial').touched;
  }

  public get telefonoVendedorValido(): boolean {
    return this.registerForm.get('telefonoVendedor').invalid && this.registerForm.get('telefonoVendedor').touched;
  }

  public get idProvinciaVendedorNoValido(): boolean {
    return this.registerForm.get('idProvinciaVendedor').invalid && this.registerForm.get('idProvinciaVendedor').touched;
  }

  public get localidadVendedorNoValido(): boolean {
    return this.registerForm.get('localidadVendedor').invalid && this.registerForm.get('localidadVendedor').touched;
  }

  public get direccionVendedorNoValido(): boolean {
    return this.registerForm.get('direccionVendedor').invalid && this.registerForm.get('direccionVendedor').touched;
  }

  public get cpVendedorNoValido(): boolean {
    return this.registerForm.get('cpVendedor').invalid && this.registerForm.get('cpVendedor').touched;
  }

  public get opcionesPagoNoValido(): boolean {
    let options = [];
    let obj = this.registerForm.get('opcionesPago');

    if (obj && this.isSeller && this.ejecutoScript) {
      options = (<any>$('#po')).select2('data');
    }

    if (this.isSeller) {
      if (options.length > 0) {
        obj.markAsTouched();
        this.registerForm.value.opcionesPago = options;
        this.registerForm.controls['opcionesPago']?.setErrors(null);
      } else {
        this.registerForm.controls['opcionesPago']?.setErrors({ 'invalid': true });
      }
    } else {
      this.registerForm.controls['opcionesPago']?.setErrors(null);
    }

    return (options.length > 0 ? false : true) && obj.touched;
  }

  public get opcionesEnvioNoValido(): boolean {
    let options = [];
    let obj = this.registerForm.get('opcionesEnvio');

    if (obj && this.isSeller && this.ejecutoScript) {
      options = (<any>$('#so')).select2('data');
    }

    if (this.isSeller) {
      if (options.length > 0) {
        obj.markAsTouched();
        this.registerForm.value.opcionesEnvio = options;
        this.registerForm.controls['opcionesEnvio']?.setErrors(null);
      } else {
        this.registerForm.controls['opcionesEnvio']?.setErrors({ 'invalid': true });
      }
    } else {
      this.registerForm.controls['opcionesEnvio']?.setErrors(null);
    }

    return (options.length > 0 ? false : true) && obj.touched;
  }

  /******************************************************************/
  /***************************** Grabar *****************************/
  /******************************************************************/

  save() {

    if (this.registerForm.invalid) {
      Object.values(this.registerForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(subcontrol => {
            subcontrol.markAsTouched();
          });
        } else {
          control.markAsTouched();
        }
      });
    } else {

      let profile: any = {
        email: '',
        nombre: '',
        apellido: '',
        telefono: ''
      };

      if (this.isClient) {
        profile.comprador = 1;
        profile.matricula = '';
        profile.especialidad = '';
        profile.idProvinciaCliente = '';
        profile.localidadCliente = '';
        profile.direccionCliente = '';
        profile.cpCliente = '';
      } else {
        profile.comprador = 0;
      }

      if (this.isSeller) {
        profile.vendedor = 1;
        profile.particular = 1;
        profile.cuit = '';
        profile.razonSocial = '';
        profile.telefonoVendedor = '';
        profile.idProvinciaVendedor = '';
        profile.localidadVendedor = '';
        profile.direccionVendedor = '';
        profile.cpVendedor = '';
        profile.opcionesPago = '';
        profile.opcionesEnvio = '';
        profile.montoEnvioGratis = '';
      } else {
        profile.vendedor = 0;
      }

      for (const field in this.registerForm.controls) {

        if (field === 'opcionesPago') {
          if (this.isSeller) {
            profile[field] = ((<any>$('#po')).select2('data').map(obj => obj.id)).join(';');
          }
        } else if (field === 'opcionesEnvio') {
          if (this.isSeller) {
            profile[field] = ((<any>$('#so')).select2('data').map(obj => obj.id)).join(';');
          }
        } else if (field === 'especialidad') {
          if (this.isClient) {
            console.log(((<any>$('#es')).select2('data')).map(obj => Number(obj.id.split(':')[1])));

            profile[field] = ((<any>$('#es')).select2('data').map(obj => Number(obj.id.split(':')[1])));
          }
        } else if (this.isSeller && field === 'particular') {
          profile[field] = Number(this.registerForm.get(field).value);
        } else {
          profile[field] = this.setNumeric(field);
        }

      }
      console.log(profile);

      // this.formService.registerAsBuyerSeller(profile).subscribe((resp: Array<Usuario>) => {
      //   console.log(resp);
      //
      //   if (resp.length > 0) {
      //     this.router.navigateByUrl('/', { replaceUrl: true });
      //   }
      // } , (error: HttpErrorResponse) => {
      //   this.toastService.error(`${COMMON.errores.error_listado_descripcion}`, `${COMMON.errores.error_listado_titulo}`);
      //
      // });
    }

  }

  setNumeric(field: string) {
    const numericFields = [
      'montoEnvioGratis',
      'idProvinciaCliente',
      'idProvinciaVendedor',
      'cp',
      'cpVendedor'
    ]
    console.log(field, numericFields.includes(field), this.registerForm.get(field).value);
    return numericFields.includes(field) ? Number(this.registerForm.get(field).value) : this.registerForm.get(field).value;
  }

  finishLoading() {
    if ((this.isClient && this.isSeller) || (this.isClient && !this.isSeller)) {
      this.subscriptions.push(
        this.serviceSubject.subscribe(resp =>
          resp == 2 ? this.showForm = true :
            this.showForm = false)
      );
    } else if (this.isSeller) {
      this.subscriptions.push(
        this.serviceSubject.subscribe(resp =>
          resp == 1 ? this.showForm = true :
            (this.isClient ? this.showForm = true : this.showForm = false)
        )
      );
    } else {
      this.showForm = true;
    }
  }

  ngOnDestroy() {
    console.log(this.subscriptions);
    if ( this.subscriptions ) {
      this.subscriptions.map(subscription => {
        subscription.unsubscribe();
      })
    }
  }
}
