import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-sidebar-collapse-filter',
  templateUrl: './sidebar-collapse-filter.component.html',
  styleUrls: ['./sidebar-collapse-filter.component.scss']
})
export class SidebarCollapseFilterComponent implements OnInit {

  @Input() name: string;
  @Input() items: any[];
  @Input() expanded = false;
  @Input() min = 4;

  @Input() key = 'id';
  @Input() value = 'nombre';

  uuid: string;

  @Output() onSelect = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.uuid = Md5.hashStr(this.name + (new Date()).toString());
  }

  selected(item: any): void {
    this.onSelect.emit(item);
  }

}
