import {Component, Input, OnInit} from '@angular/core';
import {UserProfileService} from '../../user-profile/user-profile.service';
import {environment} from "../../../environments/environment";

class ItemHistorial {
  tipo: string;
  fecha: string;
  item: {
    id: number;
    imagen: string;
    nombre: string;
    descripcion: string;
    url: string;
    slug: string;
  };
}

@Component({
  selector: 'app-historial-usuario',
  templateUrl: './historial-usuario.component.html',
  styleUrls: ['./historial-usuario.component.scss']
})
export class HistorialUsuarioComponent implements OnInit {

  @Input() type: 'CURSO' | 'PRODUCTO' | 'VIDRIERA';
  defaultNoImage = environment.defaultNoImage;
  items: ItemHistorial[];
  loading = false;

  constructor(
    private userProfileService: UserProfileService
  ) {
  }

  ngOnInit(): void {
    this.getHistorial();
  }

  getHistorial(): void {
    this.loading = true;
    this.userProfileService.getHistorial(this.type)
      .subscribe((response) => {
        if (response.status.name === 'OK') {
          this.items = response.payload;
        }
        this.loading = false;
      });
  }

}
