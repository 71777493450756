import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { baseUrlPublic, environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { SidebarService } from "../../sidebar/sidebar.service";
import { SharedService } from "../../shared.service";
import { AuthService } from "../../../auth/auth.service";
import { StorageService } from "../../../services/storage.service";
import { ChatConversation, ChatService } from "../../../chat/chat.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserProfileService } from "../../../user-profile/user-profile.service";
import { Notification, NotificationsService } from "../../../notifications/notifications.service";
import { COMMON } from "../../utils/common-texts.utils";
import { ModalSharedComponent } from "../../modal-shared/modal-shared.component";
import { Timestamp } from "firebase/firestore";
import { Carousel, CarouselService } from "../../../components/carousel/carousel.service";

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showPrincipal: boolean;

  isLoggedIn: boolean;
  showMenuVendedor: boolean;
  showMenuComprador: boolean;
  show: boolean;
  userImage: string;
  userName: string;
  userId: number;
  userDataSubscription: any;
  logginSubscription: any;
  chatConversations: any[];
  notifications: any[];
  userNameSurname: string;
  senderNameSurname: string;
  receiverNameSurname: string;
  carrito: Array<any>;
  cantidad: number;
  defaultAvatar = environment.defaultAvatar;
  userProfile?: any;
  cartSubscription: Subscription;
  whatsapp = environment.whatsappShared;
  toShared: string;
  bannersService: Carousel[];
  banners: Carousel[];
  imageUrls: string[] = [
    'assets/images/banner.png',
    'assets/images/banner.png',
    // ...otras URLs de imágenes
  ];

  currentIndex: number = 0;
  imageDisplay!: HTMLImageElement;
  intervalId!: any;

  constructor(
    private router: Router,
    public sidebarservice: SidebarService,
    private sharedService: SharedService,
    private authService: AuthService,
    private storageService: StorageService,
    private chatService: ChatService,
    private modalService: NgbModal,
    private userProfileService: UserProfileService,
    private notificationsService: NotificationsService,
  ) {

    this.userImage = '';
    this.userId = 0;
    this.userName = 'Invitado';
    this.isLoggedIn = false;
    this.chatConversations = [];
    this.notifications = [];
    this.userNameSurname = '';
  }


  infoCartSubscription(): void {
    this.cartSubscription = this.storageService.infoCart$.subscribe((info) => {
      this.carrito = this.storageService.getCartStorage();
    });
  }

  ngOnInit(): void {

    this.storageService.checkLastCarrito();


    this.carrito = this.storageService.getCartStorage();
    this.whatsapp += COMMON.whatsapp.web_sharing + ' ' + baseUrlPublic;
    this.toShared = COMMON.whatsapp.web_sharing + ' ' + baseUrlPublic;
    this.infoCartSubscription();



  }



  openSharedModal(): void {
    const modalRef = this.modalService.open(ModalSharedComponent);
    modalRef.componentInstance.toShared = this.toShared;
  }

  typeMenu(): void {
    this.showMenuVendedor = false;
    this.showMenuComprador = false;
    this.setVarsUser();
  }

  setVarsUser(): void {
    this.userProfile = this.authService.loadStorage('userProfile');
    this.showMenuVendedor = this.userProfile.vendedor;
    this.showMenuComprador = this.userProfile.comprador;
  }

  loadUser(): void {
    this.setVarsUser();
    this.userProfileService.getUserProfile().subscribe((user: Array<any>) => {
      if (user.length) {
        const userProfileData = user[0];
        this.userProfile = userProfileData;
        localStorage.setItem('userProfile', JSON.stringify(userProfileData));
        this.setVarsUser();
      }
    }, (error: any) => {
      this.sharedService.closeLoading();
    });
  }

  ngAfterViewInit(): void {
    this.logginSubscription = this.sharedService.isLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.userDataSubscription = this.sharedService.userData.subscribe((userData: any) => {
      const userImage = userData.photoURL;
      const userName = userData.displayName !== null ? userData.displayName : userData.email;
      this.userProfile = this.authService.loadStorage('userProfile');

      const userId = this.userProfile?.id;

      if (userImage) {
        this.userImage = userImage;
      }

      if (userName) {
        this.userName = userName;
      }

      if (userId) {
        this.userId = userId;
        this.chatService.getChatConversationsByUserId(userId).subscribe((chatConversations: any[]) => {

          if (chatConversations.length) {
            this.chatConversations = chatConversations.map(a => {
              const data = a.payload.doc.data() as any;
              const id = a.payload.doc.id;
              return {id, ...data};
            });
          }
        });

        if (!this.notificationsService.getToken()) {
          this.notificationsService.requestPermission(userId);
        }
        // Get user notifications history
        this.notificationsService.getNotificationsByUserId(userId).valueChanges().subscribe((resp) => {
          this.notifications = resp;
          this.notifications.forEach(notification => notification.date = notification.date.toDate());
        });
      }
    });
  }

  toggleSidebar(): void {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState(): boolean {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar(): void {
    this.sidebarservice.setSidebarState(true);
  }

  // Get date and time format for chatMessages
  getDateTime(timestamp: Timestamp): string {
    return this.chatService.getDateTime(timestamp);
  }

  signOut(): void {
    this.isLoggedIn = false;
    this.userName = 'Invitado';
    this.userImage = '';
    this.authService.signOut().then(() => console.log('Sesión cerrada'));
  }

  getNewMessageCount(): number {
    return this.chatConversations.filter(chatConversation =>
      chatConversation.latestMessage?.leido === false && chatConversation.latestMessage.senderId !== this.userId).length;
  }

  hasNewMessage(chatConversation: ChatConversation): boolean {
    return chatConversation.latestMessage ?
      (!chatConversation.latestMessage.leido) && (chatConversation.latestMessage.senderId !== this.userId) : false;
  }

  openChatConversation(orderNumber: string): void {
    this.router.navigate(['chats', orderNumber], {replaceUrl: true});
  }

  getNewNotificationsCount(): number {
    return this.notifications && this.notifications.length ? this.notifications.filter(notification => !notification.read).length : 0;
  }

  async markNotificationAsread(notification): Promise<void> {
    notification.read = true;
    this.notificationsService.updateNotification(notification);
  }

  markNotificationsAsread(): void {
    this.notifications.filter(notification => !notification.read).forEach(notification => this.markNotificationAsread(notification));
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    if (this.logginSubscription) {
      this.logginSubscription.unsubscribe();
    }

    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  async redirectNotification(notification: Notification): Promise<any> {
    await this.markNotificationAsread(notification);
    if (notification.url && notification.url !== '') {
      await this.router.navigateByUrl(notification.url);
    }
  }

}
