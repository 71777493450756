import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {COMMON} from '../utils/common-texts.utils';
import {HttpErrorResponse} from '@angular/common/http';
import {FavouriteService} from '../../services/favourite.service';
import {ToastrService} from 'ngx-toastr';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {Usuario} from "../../services/auth.service";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-favorite-product',
  templateUrl: './favorite-product.component.html',
  styleUrls: ['./favorite-product.component.scss']
})
export class FavoriteProductComponent implements OnInit {

  @Input() isFavourite: boolean;
  @Input() idProduct: number;
  @Input() product: any;
  @Output() removeProductFavorite: EventEmitter<any> = new EventEmitter();


  constructor(
    private toastService: ToastrService,
    private modalService: NgbModal,
    private favouriteServices: FavouriteService,
    private authService: AuthService,
  ) {
  }

  currentUser?: Usuario;
  ngOnInit(): void {
    this.currentUser = this.authService.loadStorage('userProfile');
  }

  removeFavorite(): void{
    const data = {
      idProducto: this.idProduct,
      favorito: false
    };
    this.favouriteServices.saveFavourite(data).subscribe(
      (response: any) => {
    this.toastService.success(`${COMMON.producto.favorito.mensaje_edicion_exitoso}`, `${COMMON.producto.favorito.titulo_edicion_exitoso}`);
    this.closeModal();
    this.removeProductFavorite.emit()

      }, (error: HttpErrorResponse) => {
        this.toastService.error(`${COMMON.errores.error_listado_descripcion}`, `${COMMON.errores.error_listado_titulo}`);
      }
    );
  }
  closeModal(): void {
    this.modalService.dismissAll();
  }
  open(content): void {
    // this.idProdcutDelete = this.idProduct;
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
  sendToFavourite(content,isFavourite): void {
    ;
    this.isFavourite = !isFavourite;
    const data = {
      idProducto: this.idProduct,
      favorito: !isFavourite
    };

    this.favouriteServices.saveFavourite(data).subscribe(
      (response: any) => {
        this.toastService.success(`${COMMON.producto.favorito.mensaje_gurdado_exitoso}`, `${COMMON.producto.favorito.titulo_guardado_exitoso}`);
        this.removeProductFavorite.emit()
      }, (error: HttpErrorResponse) => {
        this.toastService.error(`${COMMON.errores.error_listado_descripcion}`, `${COMMON.errores.error_listado_titulo}`);

      }
    );
  }

}
