import { Component, OnInit } from '@angular/core';
import { ExternalLinks, ExternalLinksService} from "../services/external-links.service";
import {COMMON} from "../shared/utils/common-texts.utils";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  links: ExternalLinks[] = [];
  noHayResultadosFiltro = COMMON.filtro.sin_resultado;
  loading = true;
  constructor(
    private externalLinksService: ExternalLinksService,
  ) { }

  ngOnInit(): void {
    this.getLinksAboutUs();
  }

  getLinksAboutUs(): void {
    this.loading = true;
    this.externalLinksService.getExternalLinks({categoria: 15}).subscribe((resp: Array<ExternalLinks>) => {
      this.loading = false;
      this.links = (resp||[]);
    });
  }

}
