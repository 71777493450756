<div style="position:relative;">
  <a
    *ngIf="banners?.length>1"
    (click)="prev()"
    class="arrow arrow-left cursor-pointer">
    <i class="lni lni-chevron-left"></i>
  </a>
  <div class="imageDisplay">
    <a *ngIf="banners && banners.length" [href]="currentLink()" target="_blank">
      <img [src]="currentImageUrl()" alt="Imagen" class="image-fade-animation img-thumbnail">
    </a>
  </div>
  <a
    *ngIf="banners?.length>1"
    (click)="next()"
    class="arrow arrow-right cursor-pointer">
    <i class="lni lni-chevron-right"></i>
  </a>
</div>
