import { Component, OnInit } from '@angular/core';
import { Carousel, CarouselService } from "../../../../components/carousel/carousel.service";
import { baseUrlImg } from "../../../../../environments/environment";

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent implements OnInit {
  cards:Array<any>;
  banners:Array<any>;
  quantity: number;

  constructor(private carouselService: CarouselService) {
    this.quantity = 3;
  }

  ngOnInit(): void {

    this.banners = [];
    this.carouselService.getBannersByType('HOME_RECOMENDADOS').subscribe((resp: Array<Carousel>) => {
      if (resp.length > 0) {
        this.banners = resp

      }
    })

    this.cards = [
      {
        title: 'Card 1',
        description: 'Description for Card 1',
        image: 'URL_to_image_1'
      },
      {
        title: 'Card 2',
        description: 'Description for Card 2',
        image: 'URL_to_image_2'
      },
      // Agrega más objetos para más tarjetas
    ];
  }

  // Arranges banner's structure for slider
  private setSliderBanners(banners: Carousel[]): any[] {
    let resolution = window.innerWidth;
    let quantityImg: number;

    if ( resolution < 576 ) {
      quantityImg = 1;
    } else if ( resolution >= 576 && resolution < 768 ) {
      quantityImg = 2;
      quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
    } else if ( resolution >= 768 && resolution < 992 ) {
      quantityImg = 3;
      quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
    } else if ( resolution >= 992 ) {
      quantityImg = 4;
      quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
    }

    let sliderBanners = [];
    let internalBanner = {
      arrayOfBanners: []
    };
    let index = 1;
    if  (banners && banners.length){
      banners.forEach((banner: any) => {

        if (index > quantityImg) {
          const arrayOfBanners = { arrayOfBanners: [...internalBanner.arrayOfBanners] };
          sliderBanners.push(arrayOfBanners);
          internalBanner.arrayOfBanners = [];
          index = 1;
        }

        internalBanner.arrayOfBanners.push(banner);
        index++;
      });

      if (internalBanner.arrayOfBanners.length > 0) {
        sliderBanners.push(internalBanner);
      }

      return sliderBanners;

    }
  }
  protected readonly baseUrlImg = baseUrlImg;
}
