<button *ngIf="currentUser" class="btn btn-link float-end" [ngbTooltip]=" isFavourite ?'Quitar de favoritos':'Agregar a favoritos'"
        (click)="isFavourite? open(contentFavorite) : sendToFavourite(content,isFavourite)">
  <i class="{{ isFavourite? 'lni lni-heart-filled m-0  text-favorite r' : 'lni lni-heart m-0  text-favorite '}}" ></i>
</button>

<button *ngIf="!currentUser" class="btn btn-link float-end" [ngbTooltip]="'Inicie sesión para agregarlo a favoritos'">
  <i class="lni lni-heart m-0  text-favorite" ></i>
</button>


<ng-template #content let-modal>

  <div class="modal-content ">
    <div class="modal-body">
      <div class="row icon-alig">
        <img class="me-2" src="assets/images/icons/icon-check.svg"
             width="16" alt="check">
      </div>
      <p>EL PRODUCTO SE HA AGREGADO AL CARRITO DE COMPRA.</p>
      <div class="d-felx">
        <!--        <button type="button" class="btn btn-outline-primary">Seguir comprando</button>-->
        <button type="button" class="btn btn-outline-modal gold-border" (click)="closeModal()"><span>Seguir comprando</span></button>
        <button type="button" class="btn btn-outline-primary-modal gold-border mr-l-10" (click)="redirection('cart/')"><span>ir al Carrito</span></button>
      </div>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
<ng-template #contentFavorite let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">¡Atención! </h5>
    </div>
    <div class="modal-body">
      <p>Esta seguro de eliminar este producto del listado de favoritos.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">No</button>
      <button type="button" class="btn btn-dark" (click)="removeFavorite()">Si</button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
