import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {Timestamp} from 'firebase/firestore';
import {StorageService} from '../services/storage.service';

export interface Notification {
  title: string;
  body: any;
  date: Timestamp;
  users: number[] | null;
  read: boolean;
  id: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  pathUsersTokens = 'usersTokens';
  constructor(
    private afm: AngularFireMessaging,
    private storageService: StorageService,
    private db: AngularFirestore) {
  }

  // -------------------   Cloud Messages push notifications   ------------------- //
  requestPermission(userId: number): void {
    this.afm.requestToken.subscribe({
      next: (token: string) => {
        this.registerNotificationUser(userId, token);
        this.storageService.setItem('notificationToken', token);
      },
      error: (error: any) => console.log(error)
    });
  }

  listenNotifications(): any {
    return this.afm.messages;
  }

  // -------------------   Firestore stored notifications   ------------------- //

  getNotificationsByUserId(userId: number): AngularFirestoreCollection<unknown> {
    return this.db.collection('notifications', ref => ref.where('users', 'array-contains', userId).orderBy('date', 'desc').limit(50));
  }

  addNotification(notification: Notification): any {
    return this.db.collection('notifications').add(notification);
  }

  updateNotification(notification: Notification): any {
    return this.db
      .collection('notifications')
      .doc(notification.id)
      .update(notification);
  }

  async getUserNotificationTokenByUserId(userId: number): Promise<any> {
    return this.db.collection(this.pathUsersTokens, ref => ref.where('userId', '==', userId)).get();
  }

  registerNotificationUser(userId: number, token: string): void {
    this.db.collection(this.pathUsersTokens, ref => ref.where('userId', '==', userId)).get().subscribe(resp => {

      if (resp.empty) {
        this.db.collection(this.pathUsersTokens).add({userId, token}).catch(error => console.log(error)
        );
      } else {
        this.db.doc(`${this.pathUsersTokens}/${resp.docs[0].id}`).update({token}).catch(error => console.log(error));
      }
    });
  }

  getToken(): string {
    return this.storageService.getItem('notificationToken');
  }
}
