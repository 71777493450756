<app-carousel
  [customCss]="'b-0 p-0'" [id]="'about_us_superior'" [mode]="'slider'" [type]="'SOBRE_NOSOTROS_SUPERIOR'" [quantity]="1">
</app-carousel>

<div class="container-fluid">
  <div class="d-flex align-items-center justify-content-between mr-l-r">
    <div>
      <h5 class="mb-0 pt-4 text-uppercase">SOBRE NOSOTROS</h5>
    </div>
  </div>
  <hr/>
  <app-loading *ngIf="loading"></app-loading>
  <ng-container *ngIf="!loading">
    <div class="ta-c" *ngIf="!links?.length">
      <p>{{noHayResultadosFiltro}}</p>
    </div>
    <div class="row " *ngIf="links.length > 0">
      <div class="col-12">
        <div class="row">
          <div *ngFor="let link of links" class="p-3 col col-12 col-md-3">
            <a [href]="link.link" target="_blank">
              <div class="card mb-3 bg-dark gold-border">
                <img [src]="link.imagen" class="card-img-top">
                <div class="card-body">
                  <h6 class="card-title">{{ link.titulo }}</h6>
                  <p class="card-text">{{ link.descripcion }}</p>
                  <p class="card-text">
                    <small class="text-muted">
                      {{ link.idioma }}
                    </small>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-carousel
    [customCss]="'b-0 p-0'" [id]="'about_us_inferior'" [mode]="'slider'" [type]="'SOBRE_NOSOTROS_INFERIOR'" [quantity]="1">
  </app-carousel>

</div>
