<div class="row mt-4">
  <div class="col col-12 col-md-3" >
    <app-searchbar placeholder="Buscar.." (searchByText)="setTexto($event)"></app-searchbar>
  </div>
</div>


<div class="box-marcas">
  <div class="marca" *ngFor="let m of marcas" [class.selected]="m.selected" [hidden]="!showMarca(m)">
    <img class="cursor-pointer" (click)="setMarca(m)" [src]="m.imagen" [alt]="m.nombre" (error)="logoError($event)">
  </div>
</div>


<div class="fixed-bottom floating-box">
  <button type="button" class="btn btn-primary px-5" (click)="submit()">
    Guardar Cambios
  </button>
</div>
