import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';
import translateAuthError from '../shared/interfaces/error-messages';
import { apiBaseUrl } from 'src/environments/environment';
import { ApiService } from '../core/api.service';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url: string;
  private idToken: string;

  constructor(
    private auth: AngularFireAuth,
    private apiService: ApiService,
    private sharedService: SharedService) {

    this.idToken = '';
    this.url = apiBaseUrl;
    this.auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          const accessToken = await user.getIdToken();
          this.idToken = accessToken;

          this.saveStorage('idToken', accessToken.replace('"',''));

          // Set user profile for navbar
          this.sharedService.userData.next(user);
          this.sharedService.isLoggedIn.next(true);

        }
      });
  }

  // Email register
  createUserWithEmailAndPassword(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  // Email sign-in
  signInWithEmailAndPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = translateAuthError[errorCode];
      this.sharedService.presentTimeOutCustomPrompt('Error al iniciar sesión', 'error', errorMessage, 4000);
      console.error(errorCode);
    });
  }

  signInWithProvider(provider) {

    return this.auth.signInWithPopup(provider)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = translateAuthError[errorCode];
        this.sharedService.presentTimeOutCustomPrompt('Error al iniciar sesión', 'error', errorMessage, 4000);
        console.error(errorCode);
      });
  }

  signInWithCustomToken(token: string) {
    return this.auth.signInWithCustomToken(token);
  }

  sendPaswordResetEmail(email: string) {
    return this.auth.sendPasswordResetEmail(email)
      .then(() => {
        this.sharedService.presentTimeOutCustomPrompt('Se envío mail de recuperación', 'success', `Mail de recuperación enviado a ${email}`, 4000);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = translateAuthError[errorCode];

        this.sharedService.presentTimeOutCustomPrompt('Error al iniciar sesión', 'error', errorMessage, 4000);
        console.error(errorCode);
      });
  }

  verifyPasswordResetCode(actionCode: any) {
    return this.auth.verifyPasswordResetCode(actionCode);
  }

  confirmPasswordReset(code: string, password: any) {
    return this.auth.confirmPasswordReset(code, password);
  }

  getUserProfile() {
    return this.apiService.post(this.url,'/usuariosapp/user', {})
    .pipe(
      map((response) => {
        return response.payload;
      }));
  }

  postUserProfile() {
    return this.apiService.post(this.url,'/usuariosapp/user', {})
    .pipe(
      map((response) => {
        return response.payload;
      }));
  }

  // Helpers and Prompts

  // Password reset prompt
  presentPromptEmailReset(email?: string) {
    return Swal.fire({
      customClass: {
        input: 'colorPlaceholder'
      },
      title: 'Recuperar contraseña',
      text: 'Ingrese su email y le enviaremos un link para recuperarla',
      position: 'center',
      input: 'email',
      inputPlaceholder: 'ejemplo: hola@todoodonto.com.ar',
      inputValue: email || '',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3494da',
      cancelButtonColor: '#3e3f3f',
      background: '#0e1316',
      color: 'white',
      inputValidator: userInput => (!userInput || !RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/).test(userInput)) && 'Ingrese un email valido!'
    })
  }

  presentLoading() {
    this.sharedService.presentLoading();
  }

  closeLoading() {
    this.sharedService.closeLoading();
  }

  presentCustomInputPrompt(title: string, type: string, msg: string) {
    return this.sharedService.presentCustomInputPrompt(title, type, msg);
  }

  presentTimeOutCustomPrompt(title: string, type: string, msg: string, timeOut: number) {
    return this.sharedService.presentTimeOutCustomPrompt(title, type, msg, timeOut);
  }

  saveStorage(key: string, data: any) {
    const value = JSON.stringify(data.replace(' ', ''));

    localStorage.setItem(key, value);
  }

  loadStorage(key: string) {
    const uid = localStorage.getItem(key);

    if (uid !== null) {
      return JSON.parse(uid);
    }
    else return '';
  }

  getIdToken() {
    return this.idToken.replace('"', '') ? this.idToken.replace('"', '') : this.loadStorage('idToken');
  }

  cleanUser() {
    this.saveStorage('idToken', '');
    this.saveStorage('userProfile', '');
  }

  signOut() {
    this.saveStorage('idToken', '');
    this.saveStorage('userProfile', '');
    this.sharedService.userData.next({});
    this.sharedService.isLoggedIn.next(false);

    return this.auth.signOut()
      .catch(error => console.error(error));
  }
}
