  <!-- Sidebar Content starts -->
<div class="sidebar sidebar-wrapper" >
        <!-- Sidebar Header starts -->
        <div class="sidebar-header">
            <div>
                <img src="assets/images/logo-icon.jpg" class="logo-icon" alt="logo icon">
                <img src="assets/images/logo-img.jpg" class="logo-img" alt="logo img">

            </div>
            <div class="toggle-icon ms-auto" *ngIf="isMobile">
              <i  (click)="toggleSidebar()"  class='bx bx-arrow-to-left'></i>
            </div>
        </div>
        <!-- Sidebar Header Ends -->
    <div class="sidebar-content" [perfectScrollbar]>
        <ul class="navigation">
            <!-- First level menu -->
            <li *ngFor="let menuItem of menuItems" [ngClass]="[menuItem.class]">
                <a (click)="menuItem.path? hiddeMenu():return" [routerLink]="menuItem.class === 'empty-class' ? menuItem.path : null" *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
                    <i *ngIf="menuItem.icon" [ngClass]="[menuItem.icon]"></i>
                    <span class="menu-title">{{menuItem.title}}</span>
                    <span *ngIf="menuItem.badge && menuItem.badgeClass " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <ng-template #externalLinkBlock>
                    <a [href]="menuItem.path" target="_blank">
                        <i [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title}}</span>
                        <span *ngIf="menuItem.badge " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>
                </ng-template>
                <!-- Second level menu -->
                <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
                    <li *ngFor="let menuSubItem of menuItem.submenu" >
                        <a (click)="menuSubItem.path? hiddeMenu():return"  [routerLink]="menuSubItem.submenu.length > 0 ? null : menuSubItem.path" [queryParams]="menuSubItem.params" *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                            <i *ngIf="menuSubItem.icon" [ngClass]="[menuSubItem.icon]"></i>
                            <span class="sub-menu-title">{{menuSubItem.title}}</span>
                            <span *ngIf="menuSubItem.badge " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <ng-template #externalSubLinkBlock>
                            <a [href]="menuSubItem.path" target="_blank">
                                <i [ngClass]="[menuSubItem.icon]"></i>
                                <span class="sub-menu-title">{{menuSubItem.title}}</span>
                                <span *ngIf="menuSubItem.badge " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                            </a>
                        </ng-template>
                        <!-- Third level menu -->
                        <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                            <li *ngFor="let menuSubsubItem of menuSubItem.submenu"
                                [ngClass]="[menuSubsubItem.class]">
                                <a (click)="menuSubsubItem.path? hiddeMenu():return"  [routerLink]="menuSubsubItem.path" *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                                    <span class="sub-menu-title">{{menuSubsubItem.title}}</span>
                                    <span *ngIf="menuSubsubItem.badge " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                </a>
                                <ng-template #externalSubSubLinkBlock>
                                    <a [href]="menuSubsubItem.path" target="_blank">
                                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                                        <span class="sub-menu-title">{{menuSubsubItem.title}}</span>
                                        <span *ngIf="menuSubsubItem.badge " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                    </a>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <!-- Sidebar Content Ends -->
</div>
