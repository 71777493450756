import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks-purchase',
  templateUrl: './thanks-purchase.component.html',
  styleUrls: ['./thanks-purchase.component.scss']
})
export class ThanksPurchaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
