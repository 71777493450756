<div class="main-header-position">
  <app-social class="social-header" [customCss]="'redes-header'" [position]="'header'"></app-social>
  <header>

    <div class=" topbar-custom d-flex align-items-center">

      <nav class="navbar navbar-expand">

        <img routerLink="/" src="assets/icons/logo-todoOdonto.png" class="logo-todoOdonto cursor-pointer"
             alt="logo img">
        <div class="mobile-toggle-menu ms-4"><i (click)="toggleSidebar()" class='bx bx-menu'></i>
        </div>
        <a href="/" [routerLink]="['/']" class="d-sm-none">
          <img class="pl-4" style="width: 40px" src="/assets/images/logo-icon.jpg" alt="logo icon">
        </a>


        <div class="top-menu ms-auto d-flex">

          <ul class="navbar-nav align-items-center">

            <li class="nav-item">
              <button
                *ngIf="!isLoggedIn"
                type="button" class="btn btn-outline-secondary btn-border" [routerLink]="['/auth/sign-up']">
                <span class="title">SUMATE</span>
              </button>
            </li>


            <li class="nav-item dropdown-large" *ngIf="isLoggedIn">

              <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="/favourites" role="button">
                <i class='bx bx-heart'></i>
              </a>
            </li>
            <li class="nav-item dropdown-large" *ngIf="isLoggedIn">
              <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="/cart" role="button">
                <span class="alert-count" *ngIf="carrito.length>0">{{carrito.length}}</span><i class='bx bx-cart'></i>
              </a>
            </li>
            <li class="nav-item dropdown dropdown-large">
              <a *ngIf="isLoggedIn" class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#"
                 role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span *ngIf="getNewNotificationsCount()" class="alert-count">{{getNewNotificationsCount()}}</span>
                <i class='bx bx-bell'></i>
              </a>
              <div *ngIf="isLoggedIn" class="dropdown-menu dropdown-menu-end">
                <a href="#" (click)="$event.preventDefault()">
                  <div class="msg-header">
                    <p class="msg-header-title">Notificaciones</p>
                    <p (click)="markNotificationsAsread()" class="msg-header-clear ms-auto">Marcar todo como leído</p>
                  </div>
                </a>
                <div class="header-notifications-list" [perfectScrollbar]>
                  <div *ngFor="let notification of notifications" class="dropdown-item pointer"
                       (click)="redirectNotification(notification)">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <h6 [class.bold]="!notification.read" class="msg-name">{{notification.title}}<span
                          class="msg-time float-end">{{ notification.date ? (notification.date | date: 'dd-MM-yyyy') : ''}}</span>
                        </h6>
                        <div class="row">
                          <div [class.bold]="!notification.read" class="col">
                            <p class="msg-info">{{notification.body}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a [routerLink]="['/notifications']" (click)="$event.preventDefault()" hidden>
                  <div class="text-center msg-footer">Ver todas las notificaciones</div>
                </a>
              </div>
            </li>
            <li *ngIf="isLoggedIn" class="nav-item dropdown dropdown-large">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <span *ngIf="getNewMessageCount() > 0" class="alert-count">{{getNewMessageCount()}}</span>
                <i class='bx bx-comment'></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a href="#" (click)="$event.preventDefault()">
                  <div class="msg-header">
                    <p class="msg-header-title">Mensajes</p>
                  </div>
                </a>
                <div class="header-message-list" [perfectScrollbar]>
                  <a *ngFor="let chatConversation of chatConversations" class="dropdown-item pointer"
                     [class.unread]="hasNewMessage(chatConversation)"
                     (click)="openChatConversation(chatConversation.orderNumber)">
                    <div class="d-flex align-items-center">
                      <img
                        [src]="chatConversation.senderId !== userId ? chatConversations.senderImage : chatConversation.receiverImage || defaultAvatar"
                        class="msg-avatar" alt="user avatar">

                      <div class="flex-grow-1">
                        <h6
                          class="msg-name">{{ chatConversation.senderId !== userId ? chatConversations.senderNameSurname : chatConversation.receiverNameSurname }}
                          <span class="msg-time float-end">{{ getDateTime(chatConversation.time) }}</span></h6>
                        <p class="msg-info">{{ chatConversation.latestMessage?.message }}</p>
                      </div>
                    </div>
                  </a>
                </div>
                <a [routerLink]="['/chats/all']">
                  <div class="text-center msg-footer">Ver todos los mensajes</div>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div class="user-box dropdown">
          <div *ngIf="isLoggedIn">
            <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" (click)="typeMenu()"
               role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              <div *ngIf="userImage; else elseBlock">
                <img [src]="userImage" class="user-img" alt="user avatar">
              </div>
              <ng-template #elseBlock>
                <img [src]="defaultAvatar" class="user-img" alt="user avatar">
              </ng-template>

              <div class="user-info ps-3">
                <p class="user-name mb-0">{{ userName }}</p>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" *ngIf="isLoggedIn">
              <li>
                <button class="dropdown-item" (click)="openSharedModal()"><i
                  class="bx bx-share-alt"></i><span>Compartir</span></button>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/profile"><i class="bx bx-user"></i><span>Mis datos</span></a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/favourites"><i
                  class="bx bx-heart"></i><span>Productos favoritos</span></a>
              </li>
              <li *ngIf="showMenuComprador">
                <div class="dropdown-divider mb-0"></div>
              </li>
              <li *ngIf="showMenuComprador">
                <a class="dropdown-item" routerLink="/my-shopping"><i
                  class="bx bx-cart"></i><span>Mis compras</span></a>
              </li>

              <li *ngIf="showMenuVendedor">
                <div class="dropdown-divider mb-0"></div>
              </li>
              <li *ngIf="showMenuVendedor">
                <a class="dropdown-item" routerLink="/trainings">
                  <i class="lni lni-graduation"></i><span>Venta de cursos</span>
                </a>
              </li>
              <li *ngIf="showMenuVendedor">
                <a class="dropdown-item" routerLink="/trainings/v2">
                  <i class="lni lni-graduation"></i><span>Venta de cursos (V2 Beta)</span>
                </a>
              </li>

              <li *ngIf="showMenuVendedor">
                <a class="dropdown-item" routerLink="/form/products-register"><i
                  class="lni lni-money-location"></i><span>Venta de productos</span></a>
              </li>
              <li *ngIf="showMenuVendedor">
                <a class="dropdown-item" routerLink="/form/vendeproductos"><i
                  class="lni lni-money-location"></i><span>Venta de productos (V2 Beta)</span></a>
              </li>
              <li *ngIf="showMenuVendedor">
                <a class="dropdown-item" routerLink="/my-sales"><i
                  class="bx bx-shopping-bag"></i><span>Mis ventas</span></a>
              </li>
              <li>
                <div class="dropdown-divider mb-0"></div>
              </li>
              <li><a (click)="signOut()" class="dropdown-item" routerLink="/auth/sign-in"><i
                class='bx bx-log-out-circle'></i><span>Cerrar sesión</span></a>
              </li>
            </ul>
          </div>
          <div *ngIf="!isLoggedIn">
            <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              <div *ngIf="userImage; else elseBlock">
                <img [src]="userImage" class="user-img" alt="user avatar">
              </div>
              <ng-template #elseBlock>
                <img [src]="defaultAvatar" class="user-img" alt="user avatar">
              </ng-template>

              <div class="user-info ps-3">
                <p class="user-name mb-0">{{ userName }}</p>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" [href]="whatsapp"><i class="bx bxl-whatsapp"></i><span>Compartir</span></a>
              </li>
              <li>
                <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
                   [routerLink]="['/auth/sign-up']" role="button" aria-expanded="false">
                  <i class='bx bx-user-plus'></i>Creá tu cuenta</a></li>
              <li><a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
                     [routerLink]="['/auth/sign-in']" role="button" aria-expanded="false">
                <i class='bx bx-log-in-circle'></i> Ingresá</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <app-notifications></app-notifications>
</div>




