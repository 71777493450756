<div class="error-404 d-flex justify-content-center">
  <div class="card shadow-none bg-transparent">
    <div class="card-body text-center">
      <a class="navbar-brand mt-4 mb-2   " routerLink="/">
        <img src="assets/images/logo-img.jpg" alt="Todo-Odonto" width="200px"/>
      </a>

      <h1 class="display-4 mt-5">¡Gracias por ayudarnos!</h1>
      <p>Contanos por whastapp o completando una breve encuesta que te parece Todo-Odonto, en qué podemos mejorar y que no te gustó.</p>
      <div class="row">
        <div class="col-12 col-lg-12 mx-auto mt-4">
          <a [href]="whatsapp" target="_blank" class="btn btn-success me-3" >
            <i class='bx bxl-whatsapp'></i>
            Por Whatsapp
          </a>
          <a [href]="forms" target="_blank" class="btn btn-facebook me-3" >
            <i class='bx bx-list-ol'></i>
            Encuesta en Google Form
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
