<app-header [title]="'Venta de Cursos'"
            [action] = "'Agregar un curso'"
            [iconButton]="'bx-plus'"
            [showButton] = true
            (addPressed)="navigateAdd()"></app-header>
<app-alert [showAlertSeller] = showAlertSeller></app-alert>

<app-alert [showMessageCourse] = showMessageCourse> </app-alert>


<form class="row g-3 formPadding" [formGroup]="coursesForm"  [hidden]="showAlertSeller">
  <div class="row" style="padding: 2em;">

    <div class="col-10 col-md-3 mb-2 col-lg-2">
      <input #searchTerm type="text" class="form-control" placeholder="Busque aquí">
    </div>
    <div class="col-2 col-md-3 mb-2 col-lg-1">
      <button (click)="search(searchTerm.value)" class="btn btn-primary btnBuscar" type="button"> Buscar</button>
    </div>
  </div>
</form>

<div class="row divPadding" *ngIf="courses.length > 0">
  <div *ngFor="let course of courses" class="col-md-3 mb-2 pointer" [routerLink]="course.id">
    <div class="card mb-3 bg-dark gold-border">
      <img *ngIf="course.realpath" [src]="course.realpath || defaultNoImage" class="card-img-top">
      <div class="card-body">
        <h6 class="card-title">{{ course.nombre }}</h6>
        <p class="card-text">
          <span>{{course.dictantes}} </span>
          <span style="float: right"> {{ course.preciodescuento ? (course.preciodescuento|price) : (course.precio|price) }}</span>
        </p>
        <p class="card-text">
          <small class="text-muted">{{ course.especialidad?.nombre }}</small>
        </p>

      </div>
      <div class="card-footer">
        <small class="text-muted">{{course.activo ? 'Activo': 'Inactivo'}}</small>
        <small style="float: right">{{course.aprobado ? 'Aprobado': 'No aprobado'}} </small>
      </div>
    </div>

  </div>
</div>
<div *ngIf="courses && !courses.length && !showAlertSeller " class="not-exist">
  <p>{{listadoVacio}}</p>
</div>
