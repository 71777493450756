<div class="p-1" *ngIf="!isCardCustomer && items && items.length">
  <div class="items" *ngIf="!isLink">
    <div *ngFor="let item of items" class="card card-item pointer bg-dark"
         [routerLink]="item.tipo === 'PRODUCTO' ? '/product/' + item.slug : '/courses/' + item.slug">
      <img *ngIf="item.tipo === 'PRODUCTO'" [src]="item.imagen ? item.imagen : defaultNoImage" class="img-fluid" [alt]="item.nombre">
      <img *ngIf="item.tipo === 'CURSO'" [src]="item.realpath ? item.realpath : defaultNoImage" class="img-fluid" [alt]="item.nombre">
      <img *ngIf="isLink" [src]="item.imagen ? item.imagen : defaultNoImage" class="img-fluid" [alt]="item.nombre">
      <p class="mt-1 mb-1 text-3l-dots p-2">{{item.nombre}}</p>
    </div>
  </div>
  <div class="items" *ngIf="isLink">
    <div *ngFor="let item of items" class="card card-item pointer bg-dark">
      <ng-container *ngIf="!item.video" class="card-item-body" >
        <a [href]="item.link" target="_blank">
          <img *ngIf="isLink" [src]="item.imagen ? item.imagen : defaultNoImage"  class="img-fluid" [alt]="item.nombre">
          <p *ngIf="isLink" class="mt-1 mb-1 text-3l-dots p-2">{{item.titulo}}</p>
        </a>
      </ng-container>
      <div class="container-video" *ngIf="item.video && item.link">
        <div class="text-center mt-5" *ngIf="showLoading">
        <span class="spinner-border spinner-border-sm" role="status"></span>
        Cargando...
        </div>
        <iframe class="video" [src]="safeUrl(item.link)" onloadeddata="onLoad()" ></iframe>
      </div>
    </div>
  </div>
</div>
<div class="p-1" *ngIf="isCardCustomer && items && items.length">
  <div class="items-2">
    <div *ngFor="let item of items" class="card card-item"  [routerLink]="item.tipo === 'PRODUCTO' ? '/product/' + item.slug : '/courses/' + item.slug">

      <img *ngIf="item.tipo === 'PRODUCTO'" [src]="item.imagen ? item.imagen : defaultNoImage"  class="card-img-top"[alt]="item.nombre">
      <img *ngIf="item.tipo === 'CURSO'" [src]="item.realpath ? item.realpath : defaultNoImage" class="card-img-top" [alt]="item.nombre">
      <img *ngIf="isLink" [src]="item.imagen ? item.imagen : defaultNoImage" class="img-fluid" [alt]="item.nombre">
      <div class="card-body">
        <div >
          <h6 class="card-title cursor-pointer" [title]="item.nombre">{{item.nombre}}</h6>
          <p *ngIf="item.tipoProducto" class="cursor-pointer card-subT mb-1">{{item.tipoProducto.nombre}}</p>
          <p *ngIf="item.marca && item.marca.nombre" class=" mb-1 cursor-pointer ">{{item.marca.nombre}}</p>
          <div class="precio">
            <p *ngIf="item.precioDesde">Precios desde ${{item.precioDesde|price}}</p>
          </div>
          <div class="vendedores gold-border" *ngIf="item.vendedores && item.vendedores.length">
            <img *ngFor="let v of item.vendedores" [src]="v.imagen" [alt]="v.nombre">
          </div>
        </div>
      </div>
      <div *ngIf="true" class="card-footer justific">
        <app-cart></app-cart>
        <app-favorite-product [isFavourite]="item.isFavorite" [idProduct]="item.id" *ngIf="item.tipo === 'PRODUCTO'"></app-favorite-product>
      </div>
      <div *ngIf="item.action" class="card-footer justific">
        <a class="btn">
          <i class='lni lni-heart-filled m-0 text-danger' title="Quitar de favorito" (click)="removeFavorite()"></i>
        </a>
        <a class="btn">
          <i class='lni lni-eye m-0' title="Ver detalle" (click)=" goToProductDetail()"></i>
        </a>
      </div>
      <!--      <p class="mt-1 mb-1 text-3l-dots p-2">{{item.nombre}}</p>-->
    </div>
  </div>

</div>

