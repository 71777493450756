// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC8-FkK-niZnlNfC_86l42Tv-A4IQ7VjnM',
  authDomain: 'todo-odontologo.firebaseapp.com',
  projectId: 'todo-odontologo',
  storageBucket: 'todo-odontologo.appspot.com',
  messagingSenderId: '701934052758',
  appId: '1:701934052758:web:edfd0fa48f5ad6ce5ea9b7'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const environment = {
  cleanCart: 1715104698090, //(new Date()).getTime(),
  tagManager: 'GTM-MZ84GH2',
  firebase: firebaseConfig,
  production: false,
  timeout: 30000,
  firebaseConfig,
  defaultAvatar: 'assets/images/icons/user-dark.png',
  defaultAvatarLink: 'assets/images/icons/imagen.png',
  defaultNoImage: 'assets/images/icons/no-image.png',
  googleFormFeedback: 'https://docs.google.com/forms/d/e/1FAIpQLSfeXXTqEbKRPkKBXHLCUvdDn00f_2muYQq1ERd9ILR4TMRncA/viewform?vc=0&c=0&w=1&flr=0',
  whatsappShared: 'https://api.whatsapp.com/send?text=',
  whatsapp: 'https://wa.me/5493425673506?text=Hola Todo Odonto!',
  storage: {
    chats: {
      documents: 'chats-documents-order-'
    }
  }
};
export const apiBaseUrl = 'https://todoodonto.winsoft.com.ar/API';
export const baseUrl = 'https://todoodonto.winsoft.com.ar/';
export const baseUrlPublic = 'https://todo-odontologo.firebaseapp.com/';
export const baseUrlImg = 'https://todoodonto.winsoft.com.ar';

/*
export const baseUrlPublic = 'http://localhost:4200/';
export const apiBaseUrl = 'http://localhost:8000/API';
export const baseUrl = 'http://localhost:8000/';
export const baseUrlImg = 'http://localhost:8000';
*/


// Initialize Firebase
export const db = getFirestore(app);

// Initialize Storage
export const storage = getStorage(app);
