<div class="card bg-dark mt-3">
  <div class="card-body">
    <p class="font-18"><b [innerText]="title"></b></p>
    <div class="rounded class-car text-center">
      <div class="radius-15 button-profile">
        <img [src]="image ? image : (init ? (init|safeURL) : defaultImage)" class="radius-15" alt="Actualizar Imagen">
        <div class="d-grid button-file">
          <input #file type="file" accept="image/*" (change)="changeListener($event)">
          <a #upload (click)="file.click()" class="btn btn-outline-primary radius-15">Actualizar imagen</a>
        </div>
      </div>
    </div>
  </div>
</div>
