import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Specialty} from '../../services/especialidad.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Category} from '../../categories/categories.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-modal-link',
  templateUrl: './modal-link.component.html',
  styleUrls: ['./modal-link.component.scss']
})
export class ModalLinkComponent implements OnInit {
  @Input() specialties: Array<Specialty>;
  @Input() categories: Array<Category>;
  @Input() categoria?: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  singleSettings: IDropdownSettings = {};
  multipleSettings: IDropdownSettings = {};
  linkForm: FormGroup;
  imagen: string | ArrayBuffer;
  defaultImage: string;
  showButton: boolean;
  nombreCategoria: string;
  selected = [];
  constructor(public activeModal: NgbActiveModal,
              private domSanitizer: DomSanitizer,
              private formBuilder: FormBuilder) {

  }

  private createForm(): void {

    this.linkForm = this.formBuilder.group({
      username: [''],
      specialty: ['', [Validators.required]],
      category: ['', [Validators.required]],
      video: ['no'],
      expiration: [''],
      images: [''],
      title: ['', [Validators.required]],
      description: [''],
      idiom: [''],
      link: [''],
    });
    if (this.categoria) {
      this.linkForm.get('category')?.setValue(this.categoria);
      this.nombreCategoria = this.categories.find(x => x.id === this.categoria)?.nombre;
      this.selected = this.categories.filter(x => x.id === this.categoria);
    }
  }



  ngOnInit(): void {
    this.createForm();

    // this.linkForm.get('phoneNumber').setValue(user.telefono);
    this.defaultImage = environment.defaultAvatarLink;
    const userPerfil = JSON.parse(localStorage.getItem('userProfile'));
    this.linkForm.get('username').setValue(userPerfil['razonSocial']);
    // this.linkForm.get('username').disable();

    this.singleSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'nombre',
      searchPlaceholderText: 'Buscar',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.multipleSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

  }

  deleteImage() {

  }

  changeListener($event, tipo: string): void {
    this.readThis($event.target, tipo);
  }

  readThis(inputValue: any, tipo: string): void {

    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imagen = myReader.result;
      this.showButton = true;
      // this.updateLogo(myReader.result, tipo);


    };
    myReader.readAsDataURL(file);
  }

  send(): void {
    let idSpecialty = [];
    if (this.linkForm.value.specialty && this.linkForm.value.specialty.length) {
      this.linkForm.value.specialty.forEach(element => idSpecialty.push(element.id));
    }
    const params = {
      username: this.linkForm.value.username,
      titulo: this.linkForm.value.title,
      idioma: this.linkForm.value.idiom,
      video: this.linkForm.value.video === 'no' ? 0 : 1,
      link: this.linkForm.value.link,
      descripcion: this.linkForm.value.description,
      especialidad: idSpecialty,
      categoria: this.linkForm.value.category,
      imagen: this.imagen,
      fecha: this.linkForm.value.expiration,

    };
    this.passEntry.emit(params);
  }

}
