
<!--Start Back To Top Button-->
<a href="#" (click)="$event.preventDefault()" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->
<!--footer -->

<footer class="page-footer">
  <br>

  <p class="text-center">
    <img src="assets/images/logo-img.jpg" style="width: 334px;" alt="Todo Odonto">
  </p>
	<!-- <p class="mb-0">Copyright © 2021. All right reserved.</p> -->
	<ul class="list-inline mb-5">

		<li class="list-inline-item" hidden>
			<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
				aria-expanded="false">
				Quiénes somos
			</a>
		</li>
		<li class="list-inline-item ">
			<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" [routerLink]="'/app/terminos'" role="button"
				aria-expanded="false">
				Términos y Condiciones
			</a>
		</li>
		<li class="list-inline-item">
			<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" [routerLink]="'/app/politicas'" role="button"
				aria-expanded="false">
				Políticas de Privacidad
			</a>
		</li>
		<li class="list-inline-item">
			<a [routerLink]="['/faqs']" class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
				aria-expanded="false">
				Preguntas Frecuentes
			</a>
		</li>
		<li class="list-inline-item">
			<a [routerLink]="['/contacts']" class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
				aria-expanded="false">
				Contacto
			</a>
		</li>
  </ul>

  <app-social [customCss]="'redes-footer'" [position]="'sopcial-footer'"></app-social>



</footer>

<!-- end footer -->
