import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqRoutingModule } from './faq-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FaqComponent } from './faq.component';
import {SharedModule} from "../shared/shared.module";


@NgModule({
    declarations: [FaqComponent],
    exports: [
        FaqComponent
    ],
  imports: [
    CommonModule,
    FaqRoutingModule,
    NgbModule,
    SharedModule,
  ]
})
export class FaqModule { }
