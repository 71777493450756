import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpErrorResponse} from "@angular/common/http";
import {ExternalLinksService} from "../../services/external-links.service";
import {Curso} from "../../trainings/training";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-curso-form-link',
  templateUrl: './curso-form-link.component.html',
  styleUrls: ['./curso-form-link.component.scss']
})
export class CursoFormLinkComponent implements OnInit {

  @Input() curso: Curso;
  form: FormGroup;
  loading = false;

  constructor(
    private toastService: ToastrService,
    private linksService: ExternalLinksService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      curso: [this.curso?.id, [Validators.required]],
      titulo: ['', [Validators.required]],
      descripcion: [''],
      link: ['', [Validators.required]],
      imagen: ['', [Validators.required]]
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {

      const myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {
        this.form.patchValue({
          imagen: myReader.result
        });
      };
      myReader.readAsDataURL(file);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const data = this.form.value;
      this.linksService.guardarLinkCurso(data).subscribe((resp: any) => {
        this.toastService.success('Link Guardado Correctamente');
        this.activeModal.dismiss(true);
      }, (error: HttpErrorResponse) => {
        this.toastService.error('Ocurrio un error al guardar el link');
      });
    } else {
      this.toastService.error('El formulario es inválido');
    }
  }

}
