export const COMMON = {
  tagsPagos: ['Transferencia Bancaria', 'Mercado Pago', 'Tarjeta de Débitos', 'Tarjeta de Créditos', 'Otros'],
  tagsEnvios: ['Correo Argentino', 'Flete Propio', 'Otros'],
  producto: {
    titulo_guardado_exitoso: 'Producto agregado con exito',
    mensaje_gurdado_exitoso: 'El producto fue agregado con exito al carrito.',
    registro: {
      registro_de_producto: 'Registro de producto',
      titulo_guardado_exitoso: 'Producto guardado con exito',
      mensaje_gurdado_exitoso: 'Guardado correctamente.',
      titulo_guardado_fallido: 'Error en el servidor',
      mensaje_gurdado_fallido: 'Disculpe no se han podido guardar los productos.',
      mensaje_incremento: 'Los productos seleccionados se incrementaron un',
      titulo_mensaje_incremento: 'Atencion',
    },
    detalle: {
      titulo_mensaje_variante: 'Atencion',
      mensaje_variante: 'Deberá seleccionar una variante.',
    },
    favorito: {
      titulo_guardado_exitoso: 'Guardado exitoso',
      mensaje_gurdado_exitoso: 'Este producto fue guardado como favorito',
      titulo_edicion_exitoso: 'Guardado exitoso',
      mensaje_edicion_exitoso: 'Este producto fue quitado de favorito',
      sin_resultado: 'No encontramos resultados para tu filtro de busqueda',
      no_existen_productos: 'Aún no tenes ningun producto favorito',
    }
  },
  curso: {
    titulo_guardado_exitoso: 'Guardado exitoso',
    mensaje_gurdado_exitoso: 'Tu curso ha sido guardado',
    titulo_guardado_fallido: 'Error en el servidor',
    mensaje_gurdado_fallido: 'Disculpe no se ha podido crear la orden de compra.',
    mensaje_curso_no_encontrado: 'Disculpe no se ha podido encontrar el curso.',
    titulo_curso_no_encontrado: 'Curso no encontrado.',
    no_existen_cursos: 'No existen cursos cargados',
  },
  filtro: {
    sin_resultado: 'No encontramos resultados para tu filtro de busqueda',
    buscando_resultado: 'Buscando resultados',
    no_existen_productos: 'No existen productos cargados',
    no_existen_post: 'No existen post cargados',
    oferta: 'Ofertas',
    seleccionadas: 'seleccionadas',
    seleccionada: 'seleccionada',
    seleccionado: 'seleccionado',
    seleccionados: 'seleccionados',
    filtros_seleccionados: 'Filtros seleccionados',
    categorias: 'Categorias',
    marcas: 'Marcas',
    tipo_producto: 'Tipo de producto'
  },
  calificaciones: {
    calificaciones: 'calificaciones'
  },
  vendedor: {
    no_existen_vendedor: 'No contamos con vendedores asociados.'
  },
  errores: {
    general: 'Estamos teniendo inconvenientes para realizar esta acción',
    error_listado_titulo: 'El servidor no responde',
    error_listado_descripcion: 'Se ha producido un error inesperado, intentelo nuevamente mas tarde',
  },
  listado_vacio: {
    mesaje_miscompras: 'Usted no posee ordenes de compra.',
    mesaje_miscursos: 'No tenés ningun curso creado aún.'
  },
  carrito: {
    trabajando: 'Proximamente agregaremos esta funcionalidad',
    confirmacion: {
      opciones_de_pago: 'Opciones de pago',
      opciones_de_envio: 'Opciones de envío',
      datos_de_entrega: 'Datos de entrega',
    },
    titulo_guardado_fallido: 'Error en el servidor',
    mensaje_gurdado_fallido: 'Disculpe no se ha podido procesar la orden.',

  },
  perfil: {
    titulo_guardado_exitoso: 'Datos guardado con exito',
    mensaje_gurdado_exitoso: 'Tus datos fueron guardado con exito',
    titulo_guardado_fallido: 'Error en el servidor',
    mensaje_gurdado_fallido: 'Disculpe no se ha podido guardar los datos.',
    titulo_imagen_exito: 'Actualizacion exitosa',
    mensaje_imagen_exito: 'EL logo fue gurdado con exito.',
    titulo_imagen_fallido: 'Error en el servidor',
    mensaje_imagen_fallido: 'Disculpe no se ha podido guardar el logo.',
  },
  botones: {
    chatear: 'Chatear',
  },
  whatsapp: {
    producto_titulo : '¿Querés recomendarnos un producto?',
    producto_descripcion : 'Escribinos por whatsapp con los detalles del producto',
    producto_detalle_titulo : '¿Querés recomendarnos un producto?',
    producto_detalle_descripcion : 'Escribinos por whatsapp con los detalles del producto',
    producto_detalle_copy_shared: 'Copiar para compartir Todo-Odonto con tus amigos y colegas',
    producto_detalle_shared: 'Compartí Todo-Odonto con tus amigos y colegas',
    web_sharing: 'Estoy usando Todo-Odonto, El aliado estratégico de su consultorio.',
    orden_titulo : '',
    orden_descripcion : 'Comunicate con nuestro servicio de atención por Whatsapp.',
    youtube_titulo : '¿Qué otro canal de youtube recomendarias? ',
    youtube_descripcion : 'Contanos por whatsapp y lo compartimos.',
    biblioteca_titulo : '¿Qué otras bibliotecas recomendarias? ',
    biblioteca_descripcion : 'Contanos por whatsapp y lo compartimos. ',
    blog_titulo : '¿Conoces algún Blog con temas odontológicos para recomendarnos?',
    blog_descripcion : 'Contanos por whatsapp y lo compartimos.',
    canales_titulo : '¿Qué otro canal recomendarias? ',
    canales_descripcion : 'Comunicate con nuestro servicio de atención por Whatsapp.',
  },
};
