
<div class="box-whatsapp">
  <div class="flex flex-nowrap justify-content-between ">

    <div class="d-inline-flex">
      <div class="icon">
        <a [href]="whatsapp" target="_blank" class="btn btn-success btn-whatsapp" >
          <i class='bx bxl-whatsapp'></i>
        </a>
        <div class="mt-2" *ngIf="!showText">
          <p>Whatsapp</p>
        </div>
      </div>
      <div class="p-1 ms-3 mt-2 d-flex align-content-center flex-sm-wrap" *ngIf="showText">
        <h6>{{descriptionShared}}</h6>
      </div>
    </div>

    <div class="d-inline-flex">
      <div class="icon">
        <a (click)="copyText()" class="btn btn-primary btn-copy" >
          <i class='bx bx-copy'></i>
        </a>
        <div class="mt-2" *ngIf="!showText">
          <p>Copiar vinculo</p>
        </div>
      </div>
      <div class="p-1 ms-3 mt-2 d-flex align-content-center flex-sm-wrap" *ngIf="showText">
        <h6>{{descriptionCopy}}</h6>
      </div>
    </div>

    <div class="gmail">
      <a [href]="href">
        <img src="assets/images/descarga.png" class="img-fluid  rounded-border p-2" alt="..." >
        </a>
      <div *ngIf="!showText">
        <p>Gmail</p>
      </div>
    </div>
  </div>
</div>
