import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-odonto-tabs',
  templateUrl: './odonto-tabs.component.html',
  styleUrls: ['./odonto-tabs.component.scss']
})
export class OdontoTabsComponent implements OnInit {

  @Input() tabs: string[];
  @Input() current: number = 0;
  @Output() onTabChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  tabClicked(index: number): void {
    this.onTabChange.emit(index);
  }

}
