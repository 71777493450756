import {Pipe, PipeTransform} from '@angular/core';
import {SafeHtml, DomSanitizer, SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safeURL',
})
export class SafeURLPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
