<h4
  [class]="css"
  class="linea"
  *ngIf="titles"
    [ngClass]="{
    'alig-left': position==='left',
    'alig-right': position==='right',
    'text-transform': textTransform === 'uppercase'
    }">
<a href="{{ title.link }}" [target]="title.link ? '_blank' : '_self'" *ngFor="let title of titles">
    <span class="title-link" >{{ title.title }}</span>
</a>

</h4>

<h4
  [class]="css"
  class="linea"
  *ngIf="title"
    [ngClass]="{
    'alig-left': position==='left',
    'alig-right': position==='right',
    'text-transform': textTransform === 'uppercase'
    }">
  <span *ngIf="title" >{{title}} </span>
</h4>

<div class="border-line" *ngIf="!title && !titles" ></div>

