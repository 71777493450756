import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormProductsRegisterComponent } from './form-products-register/form-products-register.component';
import { FormRegisterComponent } from './form-register/form-register.component';

import { SellerGuard } from '../auth/seller.guard';
import {VendeproductosComponent} from "../vendeproductos/vendeproductos.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'form-register',
        component: FormRegisterComponent,
        data: {
          title: 'form-register'
        }
      },
      {
        path: 'products-register',
        component: FormProductsRegisterComponent,
        data: {
          title: 'products-register'
        },
        canActivate: [SellerGuard]
      },
      {
        path: 'vendeproductos',
        component: VendeproductosComponent,
        canActivate: [SellerGuard],
        data: {
          title: 'Vender Productos'
        }
      },
      /*
            {
        path: 'form-wizard',
        component: FormWizardComponent,
        data: {
          title: 'Form Wizard'
        }
      },
      {
        path: 'form-elements',
        component: FormElementsComponent,
        data: {
          title: 'form-elements'
        }
      },
      {
        path: 'input-groups',
        component: InputGroupsComponent,
        data: {
          title: 'input-group'
        }
      },

       */
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormRoutingModule { }
