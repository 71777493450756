import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AvalesModalidades } from '../form/form-add-course/interface-add-course';

export interface FilterCurse {
  avales: Array<Aval>;
  modalidades: Array<Modalidad>;
  vendedores: Array<Vendedor>;
  especialidades: Array<any>;
}

export interface Value {
  id: number;
  nombre: string;
  selected?: boolean;
};

export interface Aval{
  id: number;
  nombre: string;
  selected?: boolean;
}
export interface Modalidad{
  id: number;
  nombre: string;
  selected?: boolean;
}

export interface Vendedor{
  id: number;
  imagen: string;
  localidad: string;
  logo: string;
  montoEnvioGratis: string;
  opcionesEnvio: string;
  opcionesPago: string;
  provincia: string;
  usuario: string;
  selected?: boolean;
  razonSocial: string;
}

@Injectable({
  providedIn: 'root'
})
export class CurseService {

  coursePath = '/curso';

  constructor(private apiService: ApiService) { }

  getFilterCourse(): Observable<FilterCurse> {
    return this.apiService.get(apiBaseUrl, `${this.coursePath}/get_info`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getAllCourses(params): Observable<Array<any>> {
    return this.apiService.get(apiBaseUrl, `${this.coursePath}/`, params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getCourseById(idCourse: string): Observable<Array<any>> {
    return this.apiService.get(apiBaseUrl, `${this.coursePath}/${idCourse}`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  postCourse(course: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.coursePath}/guardar`, course).pipe(
      map((response) => response.payload)
    );
  }

  duplicarCurso(id: number): Observable<any> {
    return this.apiService.get(apiBaseUrl, `${this.coursePath}/duplicar/${id}`).pipe(
      map((response) => response.payload)
    );
  }


  sortImages(id: number, items: any[]): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.coursePath}/${id}/ordenar-imagenes`, items);
  }

  updateCourse(id: string, course: any): Observable<FilterCurse> {
    return this.apiService.put(apiBaseUrl, `${this.coursePath}/edit`, course)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getGuaranteesModalities(): Observable<AvalesModalidades>  {
    return this.apiService.get(apiBaseUrl, '/curso/get_info')
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

}
