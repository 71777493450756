import {Component, Input, OnInit} from '@angular/core';
import {Curso} from "../../trainings/training";
import {environment} from "../../../environments/environment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CursoFormLinkComponent} from "../curso-form-link/curso-form-link.component";
import {ExternalLinks, ExternalLinksService} from "../../services/external-links.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-curso-links',
  templateUrl: './curso-links.component.html',
  styleUrls: ['./curso-links.component.scss']
})
export class CursoLinksComponent implements OnInit {

  @Input() curso: Curso;
  links: ExternalLinks[];
  defaultNoImage = environment.defaultNoImage;
  loading = false;
  constructor(
    private toastService: ToastrService,
    private modalService: NgbModal,
    private linkService: ExternalLinksService,
  ) {}

  ngOnInit(): void {
    this.getLinksByCurso();
  }

  async getLinksByCurso(): Promise<void> {
    try {

      this.loading = true;
      this.links = await this.linkService.getLinksByCurso(this.curso.id).toPromise();
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.toastService.error('Ocurrio un error al obtener los cursos');
      this.links = [];
    }
  }

  async eliminar(item: ExternalLinks): Promise<void> {
    try {
      const response = confirm('Estás seguro de eliminar el link?')
      if(response) {
        this.loading = true;
        this.links = await this.linkService.eliminarLinkDeCurso({curso: this.curso.id, id: item.id}).toPromise();
        this.loading = false;
        this.getLinksByCurso();
      }
    } catch (e) {
      console.error(e);
      this.toastService.error('Ocurrio un error al eliminar el link');
    }
  }

  openFormModal() {
    const modalRef = this.modalService.open(CursoFormLinkComponent, { size: 'lg' });
    modalRef.componentInstance.curso = this.curso;

    modalRef.result.then((result) => {}, (reason) => {
      reason && this.getLinksByCurso();
    });
  }

}
