<div class="d-flex price-card">
  <div class="text-center p-1 bx-img">
    <img
      src="{{vendedor.imagen || defaultAvatar}}"
      [alt]="vendedor.razonSocial">
  </div>
  <div class="card-body text-center ">
    <h5 class="card-title font-18 mb-2">
      <b [innerText]="vendedor.razonSocial"></b>
    </h5>
    <p  *ngIf="vendedor?.ubicacion?.direccion"
        class="text-dark text-center"
        [innerText]="vendedor?.ubicacion?.direccion"></p>
    <ng-content></ng-content>
    <!--
    <app-stars css="text-black" [value]="vendedor.puntuacion"></app-stars>
    -->
  </div>
</div>
