import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { PERSISTENCE, USE_DEVICE_LANGUAGE } from '@angular/fire/compat/auth';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DndModule } from 'ngx-drag-drop';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AuthInterceptor } from './auth/auth.inteceptor';
import { SpinnersComponent } from './components/spinners/spinners.component';
import { WelcomeComponent } from './welcome/welcome.component';
import {FormModule} from './form/form.module';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ClipboardModule } from 'ngx-clipboard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { VendeproductosComponent } from './vendeproductos/vendeproductos.component';
import { VpMarcasComponent } from './vendeproductos/vp-marcas/vp-marcas.component';
import { VpProductosComponent } from './vendeproductos/vp-productos/vp-productos.component';
import { VpPreciosComponent } from './vendeproductos/vp-precios/vp-precios.component';
import {SharedModule} from "./shared/shared.module";
import {FormsModule} from "@angular/forms";
import { QuillModule } from 'ngx-quill';

registerLocaleData(es, 'es');

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    VendeproductosComponent,
    VpMarcasComponent,
    VpProductosComponent,
    VpPreciosComponent,
  ],
  imports: [
    BrowserModule,
    GooglePlaceModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AngularEditorModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places']
    }),
    NgbModule,
    AppRoutingModule,
    ClipboardModule,
    IvyCarouselModule,
    PerfectScrollbarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FormModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    DndModule,
    GoogleTagManagerModule.forRoot({
      id: environment.tagManager,
    }),
    SharedModule,
    FormsModule,
    QuillModule.forRoot()
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es'},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: PERSISTENCE, useValue: 'local'},
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    SpinnersComponent,
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
