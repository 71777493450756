import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesMainComponent } from './main/courses-main.component';
import { CourseRoutingModule } from './course-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailCourseComponent } from './detail/detail-course.component';
import { FaqModule } from '../faq/faq.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SubCourseComponent } from './sub-course/sub-course.component';
import { CourseSortImagesComponent } from './course-sort-images/course-sort-images.component';
import {DndModule} from "ngx-drag-drop";
import { CourseVideosComponent } from './course-videos/course-videos.component';

@NgModule({
  declarations: [CoursesMainComponent, DetailCourseComponent, SubCourseComponent, CourseSortImagesComponent, CourseVideosComponent],
  exports: [
    SubCourseComponent
  ],
  imports: [
    CommonModule,
    CourseRoutingModule,
    SharedModule,
    NgbModule,
    FaqModule,
    PerfectScrollbarModule,
    DndModule
  ]
})
export class CoursesModule { }
