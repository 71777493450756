import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Aval, Modalidad, Vendedor } from '../../services/curse.service';
import { Categoria } from '../../services/product.service';
import { Specialty } from '../../services/especialidad.service';

@Component({
  selector: 'app-course-filter',
  templateUrl: './course-filter.component.html',
  styleUrls: ['./course-filter.component.scss']
})
export class CourseFilterComponent implements OnInit {
  @Input() avales: Array<Aval>;
  @Input() specialty: Array<Specialty>;
  @Input() modalidades: Array<Modalidad>;
  @Input() chips: Array<any>;
  @Input() vendedores: Array<Vendedor>;
  @Output() search: EventEmitter<boolean> = new EventEmitter();
  @Output() searchFilter: EventEmitter<any> = new EventEmitter();
  @Output() deleteChip: EventEmitter<any> = new EventEmitter();
  private debounceTimer?: NodeJS.Timeout;

  idAval = 0;
  idModalidad = 0;
  idVendedor = 0;
  nombre: '';

  constructor() { }

  ngOnInit(): void {
  }

  buscar(value: any): void {
    if (value){
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer =  setTimeout(() => {
        this.selected('texto', value);
      }, 1000);
    }
  }


  deleteSelected(chipSelected): void {
    this.deleteChip.emit(chipSelected);
  }

  selected(ev, value): void {
    if (ev === 'aval' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'avales' );
      this.chips.push({
        id: value.id,
        tipo: 'Avales',
        keyType: 'avales',
        nombre: value.nombre,
      });
      this.avales = [];
    }

    if (ev === 'modalidad' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'modalidad' );
      this.chips.push({
        id: value.id,
        tipo: 'Modalidad',
        keyType: 'modalidad',
        nombre: value.nombre,
      });
    }

    if (ev === 'vendedor' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'vendedor' );
      this.chips.push({
        id: value.id,
        tipo: 'Vendedor',
        keyType: 'vendedor',
        nombre: value.razonSocial,
      });
    }

    if (ev === 'especialidad' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'especialidad' );
      this.chips.push({
        id: value.id,
        tipo: 'Especialidad',
        keyType: 'especialidad',
        nombre: value.nombre,
      });
    }

    if (ev === 'texto' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'texto' );
      this.chips.push({
        id: 0,
        tipo: 'Texto',
        keyType: 'texto',
        nombre: value,
      });
    }
    this.searchFilter.emit(this.chips);
  }
}
