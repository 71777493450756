<ng-container *ngIf="uuid">
  <div class="accordion" [id]="'sidebarAccordion'+uuid">

    <div class="accordion-item">
      <h2 class="accordion-header" [id]="'filterHeading'+uuid">
      <span
        class="cursor-pointer accordion-button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#filterCollapse'+uuid"
        aria-expanded="true"
        [attr.aria-controls]="'filterCollapse'+uuid"
        [innerText]="name">
      </span>
      </h2>
      <div [id]="'filterCollapse' + uuid" class="accordion-collapse collapse show"
           [attr.aria-labelledby]="'filterHeading'+uuid" [attr.data-bs-parent]="'#sidebarAccordion'+uuid">
        <div class="content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-container>
