

  <div class="row card-home ">
    <div class="col-auto point-responsive" >
      <img class="img-point" src="assets/images/img.png">
    </div>
    <div class="card p-block p-hover col col-6 col-md-2 col-lg-3 bg-dark"  *ngFor="let banner of banners">
      <a href="{{ banner.link }}">
        <div class="aline-center">
          <img src="{{banner.imagen}}" class="block-image" [ngClass]="{'width-60':banner.width==='60','width-65': banner.width==='65','width-90': banner.width==='90','width-85': banner.width==='85'}"  title="{{banner.titulo}}"/>
        </div>
      </a>
    </div>
    <div class="col-auto point-responsive">
      <img class="img-point" src="assets/images/img_5.png">
    </div>
  </div>






