import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Marca, ProductService} from "../../services/product.service";
import {ProductsRegister} from "../../form/form-products-register/interface-products-register";
import {environment} from "../../../environments/environment";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-vp-marcas',
  templateUrl: './vp-marcas.component.html',
  styleUrls: ['../vp-styles.scss', './vp-marcas.component.scss']
})
export class VpMarcasComponent implements OnInit {

  @Input() marcas: Marca[];
  @Input() info: ProductsRegister;
  @Output() destroy = new EventEmitter<string>();

  texto?: string;
  changes = false;

  constructor(
    private productService: ProductService,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  submit(): void {
    const data = {
      marcas: this.marcas.filter(x => !!x.selected).map(x => x.id).join(',')
    };
    this.productService.saveMarcasVendedor(data)
      .subscribe((info: any) => {
        this.toastService.success("Guardado correctamente");
        this.destroy.emit(data.marcas);
      })
  }

  logoError(event: any) {
    event.target.src = environment.defaultNoImage;
  }

  setTexto(texto: string): void {
    this.texto = texto;
  }

  setMarca(marca: Marca): void {
    marca.selected = !marca.selected
    this.changes = true;
  }
  showMarca(marca: Marca): boolean {
    return !this.texto || marca.nombre.toLowerCase().includes(this.texto.toLowerCase());
  }

}
