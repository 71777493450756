<div class="p-3" *ngIf="videos?.length">
  <app-title [title]="'VIDEOS RELACIONADOS CON ESTE CURSO'" [position]="'left'"></app-title>

  <div class="items">
    <div *ngFor="let item of videos" class="card card-item pointer bg-dark">
      <div class="container-video">
        <div class="text-center mt-5" *ngIf="showLoading">
          <span class="spinner-border spinner-border-sm" role="status"></span>
          Cargando...
        </div>
        <iframe class="video" [src]="item|safeURL" onloadeddata="onLoad()"></iframe>
      </div>
    </div>
  </div>
</div>
