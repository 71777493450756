<div class="mt-4">
  <p class="px-4 pb-2" (click)="openFormModal()">
    <b class="float-end pointer text-primary">Agregar Publicación</b>
  </p>
  <div class="p-4 border border-3 rounded">
    <p>
      <b>Publicaciones relacionadas a este curso (de Instagram, Facebook, Youtube, etc.)</b>
    </p>

    <p *ngIf="!links?.length">No hay publicaciones relacionadas.</p>

    <div class="items">
      <div class="display-block m-2" *ngFor="let item of links">
        <div class="card card-item pointer bg-dark">
          <div class="card-item-body">
            <a [href]="item.link" target="_blank">
              <img [src]="item.imagen ? item.imagen : defaultNoImage" class="img-fluid" [alt]="item.titulo">
              <p class="mt-1 mb-1 text-3l-dots p-2" [innerText]="item.titulo"></p>
            </a>
          </div>
        </div>
        <p class="mr-1">
          <button class="btn btn-link text-danger" type="button" (click)="eliminar(item)">Eliminar</button>
        </p>
      </div>
    </div>

  </div>


</div>
