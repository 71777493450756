import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from "../../../services/product.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-menu-line',
  templateUrl: './menu-line.component.html',
  styleUrls: ['./menu-line.component.scss']
})
export class MenuLineComponent implements OnInit {
  activeItem: number = 0;
  activeItemFeature: number = 0;

  @Output() searchProduct: EventEmitter<any> = new EventEmitter();
  @Input() product: Product;
  @Input() productFavorite: Array<Product>;
  @Input() relateds: Array<any>;
  @Input() courses: Array<any>;
  @Input() destacados: any;
  @Input() links: any;
  @Input() calificaciones: Array<any>;
  @Input() videos: Array<any>;
  @Input() showMenuRight: boolean;
  @Input() isFavoriteList: boolean;
  menu: any;
  isCardCustomer: boolean;
  showFeature: any;
  show: any;
  showCourses: any;
  showLinks: any;
  showRelateds: any;
  showVideo: any;

  active;
  disabled = true;

  constructor(
    private router: Router,
  ){}

  ngOnInit(): void {
    ;
    this.menu = [];
    if(this.isFavoriteList){
      this.menu.push(
        {title:'Favoritos', show: [],itemsVideo:[], itemlinks:[]},
        {title:'Mis listas', show: [],itemsVideo:[], itemlinks:[]},
        {title:'Mis compras', show: [],itemsVideo:[], itemlinks:[]},
      );
    }else{
      if(this.product && this.product.descripcion){
        this.menu.push(
          {title:'Descripción', show:this.product.descripcion,itemsVideo:[], itemlinks:[]}
        );
      }

      if(this.videos){
        this.menu.push(
          {title:'Folletos y videos', itemsVideo: this.videos, itemlinks:this.links}
        );
      }


      if(this.calificaciones){
        this.menu.push(
          {
            title:'Calificaciones y opiniones',
            show:this.calificaciones,itemsVideo:[], itemlinks:[]}
        );
      }



      this.onFeaturedChange(this.destacados);
    }
    this.onNavChange(this.menu[0]);

    // [ngClass]="showMenuRight ? product && product.link || product.folleto? 'col-md-9':'col-md-12'"
  }
  ngAfterViewInit(){
    ;
    if(this.destacados!==undefined && this.destacados){
      this.onFeaturedChange(this.destacados[this.activeItemFeature]);
    }

    this.onNavChange(this.menu[this.activeItem]);
  }

  onNavChange(changeEvent) {
    this.showVideo =[];
    this.showRelateds =[];
    this.showLinks =[];
    this.showCourses = false;
    this.show = '';
    if(changeEvent!== undefined){

      if(changeEvent.title==='Descripción'){
        this.show = changeEvent.show;
      }

      if(changeEvent.title==='Mis listas'){
         // this.show = changeEvent.show;
        this.searchProduct.emit(changeEvent.show);
      }
      if(changeEvent.title==='Favoritos'){
         // this.show = changeEvent.show;
        this.searchProduct.emit(changeEvent.show);
      }
      if(changeEvent.title==='Mis listas'){
        this.router.navigate(['/coming-soon'])
      }
      if(changeEvent.title==='Mis compras'){
        this.router.navigate(['/my-shopping'])
      }


      if(changeEvent.items && changeEvent.items.length && changeEvent.title==='Calificaciones y opiniones'){
        // this.show = changeEvent.show;
      }
      if((changeEvent.itemsVideo && changeEvent.itemsVideo.length || changeEvent.itemlinks && changeEvent.itemlinks.length) && changeEvent.title==='Folletos y videos'){
        this.showVideo = changeEvent.itemsVideo;
        this.showLinks = changeEvent.itemlinks;
      }
    }

    if (changeEvent!==undefined && changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }

  onFeaturedChange(changeEvent){
    ;
    this.showVideo =[];
    this.showRelateds =[];
    if(changeEvent!==undefined && changeEvent.items && changeEvent.items.length){
      this.showFeature = changeEvent;
      this.isCardCustomer = true;

    }else{
      this.isCardCustomer= false;
      this.showFeature = changeEvent;
    }

    if ( changeEvent!==undefined && changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }

  }

  toggleDisabled() {
    this.disabled = !this.disabled;
    if (this.disabled) {
      this.active = 1;
    }
  }
}
