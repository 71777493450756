<div class="mb-3">
  <label class="form-label" *ngIf="title">{{title}}</label>
  <div class="position-relative">

    <input ngx-google-places-autocomplete class="form-control ps-5"
           placeholder="{{placeholder}}"
           [value]="inputLabel"
           [options]='options' #placesRef="ngx-places"
           (onAddressChange)="handleAddressChange($event)"/>
    <datalist id="direccion">
      <option [value]="[currentUser.direccionVendedor, currentUser.localidadVendedor].toString()">
    </datalist>
    <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-been-here"></i></span>
  </div>
</div>
<div class="col-md-6 maps " *ngIf="showMap">
  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
    <agm-marker [latitude]="latitude" [longitude]="longitude"
    ></agm-marker>
  </agm-map>
</div>

