import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-odonto-confirm',
  templateUrl: './odonto-confirm.component.html',
  styleUrls: ['./odonto-confirm.component.scss']
})
export class OdontoConfirmComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() cancel: string;
  @Input() submit: string;
  @Input() size = 'lg'
  @Output() destroy = new EventEmitter<boolean>();

  @ViewChild('content')
  private content: TemplateRef<any>;
  constructor(
    private modalService: NgbModal
  ) {}

  open() {
    this.modalService.open(this.content, {size: this.size});
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.open();
    }, 500);
  }

  confirm(): void {
    this.destroy.emit(true);
    this.modalService.dismissAll();
  }

  close(): void {
    this.destroy.emit(false);
    this.modalService.dismissAll();
  }


}
