import {Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {OrdersService, UserRate} from 'src/app/orders/orders.service';
import {SharedService} from '../../shared/shared.service';
import {ToastrService} from 'ngx-toastr';

export interface Calificado {
  comentario: string;
  id: string;
  puntuacion: number;
  tipo: string;
}

@Component({
  selector: 'app-rate-modal',
  templateUrl: './rate-modal.component.html',
  styleUrls: ['./rate-modal.component.scss']
})
export class RateModalComponent implements OnInit, OnChanges {

  @HostBinding('attr.class') cssClass = 'alfa';
  @Output() rateOutput: EventEmitter<any> = new EventEmitter<any>();
  @Input() orderId: number;
  @Input() userId: number;
  @Input() type: string;
  @Input() userRate: number;
  @Input() image: string;
  @Input() title: string;
  @Input() typeId: number;
  @Input() productoVenta?: number;

  @Input() calificado?: Calificado;
  enterRateError = '';
  rateObservationsForm: FormGroup;

  constructor(
    private sharedService: SharedService,
    private ordersService: OrdersService,
    private toastService: ToastrService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.rateObservationsForm = this.fb.group({
      input: ['', [Validators.maxLength(250)]]
    });
    if (this.userRate > 0) {
      const rateStarButton = document.getElementById('star' + this.userRate.toString());
      rateStarButton.click();
    }
    this.setCalificado();

  }

  ngOnChanges(): void {
    this.initializeForm();
  }

  setCalificado(): void {
    if (this.calificado) {
      this.rateObservationsForm.controls.input.setValue(this.calificado.comentario);
      this.userRate = this.calificado.puntuacion;

    }
  }

  rate(rate: number): void {
    if (!this.calificado) {
      this.userRate = rate;
      this.rateObservationsForm.setErrors({norate: false});
      this.rateObservationsForm.updateValueAndValidity();
    }
  }

  cancel(): void {
    // navigate back
    this.rateOutput.emit({});
  }

  submit(): void {
    if (!this.userRate) {
      this.enterRateError = 'Ingrese una puntuación';
    } else {
      this.enterRateError = '';
    }

    if (this.rateObservationsForm.invalid || !this.userRate) {
      Object.values(this.rateObservationsForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(subcontrol => {
            subcontrol.markAsTouched();
          });
        } else {
          control.markAsTouched();
        }
      });
    } else {
      // Form Ok, submit
      const comentario = this.rateObservationsForm.get('input').value;
      const puntuacion = this.userRate;
      const orden = this.orderId;
      // TODO Definir nombre de los campos para utilizar en el servicio POST
      const userRate: UserRate = {
        comentario,
        puntuacion,
        orden,
        tipo: this.type,
        producto: this.typeId,
        vendedorId: this.typeId,
        clienteId: this.typeId,
        curso: this.typeId,
        productoventa: this.productoVenta
      };

      this.sharedService.presentLoading();
      this.ordersService.rateProduct(userRate).subscribe(resp => {
        if (resp.status.name === 'OK') {
          this.toastService.success(`Gracias por ayudarnos a mejorar! `, `Listo`);
          this.sharedService.closeLoading();
          this.rateOutput.emit(userRate);

        } else {
          console.error(resp);
          this.toastService.error(`No pudimos guardar tu comentario. Intentá nuevamente.`, `Error`);
        }
      }, error => {
        console.error(error);
        this.toastService.error(`No pudimos guardar tu comentario. Intentá nuevamente.`, `Error`);
      });

    }
  }

  public get inputNoValido(): boolean {
    return this.rateObservationsForm.get('input').invalid && this.rateObservationsForm.get('input').touched;
  }

  getError(): void {
    const inputControl = this.rateObservationsForm.controls.input;
    const errors = {
      'required': 'Debe ingresar observaciones.',
      'minlength': 'Debe ingresar al menos 30 caracteres.',
      'maxlength': 'Puede ingresar como máximo 250 caracteres.',
    }

    return inputControl.errors ? errors[Object.keys(errors).filter(error => inputControl.hasError(error))[0]] : '';
  }
}
