import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-single-product-purchase',
  templateUrl: './card-single-product-purchase.component.html',
  styleUrls: ['./card-single-product-purchase.component.scss']
})
export class CardSingleProductPurchaseComponent implements OnInit {
  @Input() product;
  constructor() { }

  ngOnInit(): void {
    console.log(this.product);
  }

  plus(p): void {
    p.cantidad += 1;
    // this.calculateTotalCost(p);
  }

  subtract(p): void {
    if (p.cantidad > 1) {
      p.cantidad -= 1;
    }
    // this.calculateTotalCost(p);
  }

}
