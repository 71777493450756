<div class="card radius-15">
  <div class="card-body text-center">
    <div class="p-4 border radius-15">
      <img [src]="seller.image ? seller.image : 'assets/images/avatars/avatar-1.png'"  width="110" height="110" class="border-radius shadow" alt="">
      <h6 class="mb-0 mt-2"><b [innerText]="seller.name"></b></h6>
      <p class="mb-3">{{seller.city}}</p>
      <hr/>
      <app-stars [value]="seller.assessment"></app-stars>
      <hr/>
<!--      <div *ngIf="seller.recommendation">-->
<!--        <hr/>-->
<!--        <div class="align-items-center text-success" >-->
<!--          <i class='bx bx-radio-circle-marked bx-burst bx-rotate-90 align-middle font-18'></i>-->
<!--          <span>{{seller.recommendation}}</span>-->
<!--        </div>-->
<!--        <hr/>-->
<!--      </div>-->

      <div class="body mb-3">
        <h6>$ {{seller.totalPrice}}</h6>
        <p>{{seller.shippingType}}</p>
        <p>{{seller.quantityOfProducts}} </p>
      </div>
      <div class="d-grid">
        <button hidden class="btn btn-outline-primary" type="button" (click)="sendBuy(content, contentModalLogin, contentModalEditData)">
          Consultar por Todo-Odonto
        </button>

        <div class="mt-3">
          <app-send-whatsapp *ngIf="getConfigWhatsapp()" [config]="getConfigWhatsapp()"></app-send-whatsapp>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Confirmacion de compra</h5>
    </div>
    <div class="modal-body">
      <p>Estas seguro que deseas confirmar esta compra.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">Cancelar</button>
      <button type="button" class="btn btn-dark" (click)="sendPurchase()">Confirmar compra</button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>


<ng-template #contentModalLogin let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="goToRegister()">Registrarme</button>
      <button type="button" class="btn btn-dark" (click)="goToLogin()">Iniciar sesion</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
<ng-template #contentModalEditData let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="goToProfile()">Actualizar mis datos</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
