import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-price-card-seller-y',
  templateUrl: './price-card-seller-y.component.html',
  styleUrls: ['./price-card-seller-y.component.scss']
})
export class PriceCardSellerYComponent implements OnInit {

  @Input() vendedor: any;
  defaultAvatar = environment.defaultAvatar;

  constructor() { }

  ngOnInit(): void {
  }

}
