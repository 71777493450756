<div class="row">
  <div class="col-md-12 text-end">
    <button class="btn btn-primary gold-border text-dark" (click)="navigateBack()">
      Volver
    </button>
  </div>
</div>
<app-title [title]="'Detalle del curso'" [position]="'left'" [textTransform]="'uppercase'"></app-title>


<div *ngIf="course" class="card pt-4 pb-4 ">
  <div class="row g-0">
    <div class="col-12 col-md-4 ">
      <div class="row mb-3 row-cols-auto g-2 justify-content-center mt-2 ">
        <a [href]="imagen" target="_blank">
          <img src="{{imagen}}" class="img-fluid  rounded-border p-2" alt="...">
        </a>
      </div>
      <div *ngIf="imagenes" class="row mb-5 row-cols-auto g-2 justify-content-center mt-4">
        <div class="col" *ngFor="let imagen of imagenes first as isFirst" (click)="selectImage(imagen)">
          <img src="{{imagen.urlImg}}" width="70" class="rounded cursor-pointer"
               [ngClass]="{'radio-color-selected' : imagen.selected, 'radio-color-unselected': !imagen.selected}"
               alt="">
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card-body">
        <div class="card-title">
          <h1 [innerText]="course.nombre"></h1>
        </div>
        <div class="row">
          <h4 *ngIf="course.aval">{{course.aval.nombre}}</h4>
        </div>
        <div class="d-flex" style="align-items: baseline;">
          <app-stars [count]="course.puntuaciones" [value]="course.puntuacion"></app-stars>

          <button class="btn btn-link" (click)="openSharedModal()">
            <i class="bx bx-share-alt mr-1"></i>
          </button>


        </div>
        <hr class="gold-border">
        <div class="row">
          <div class="col-12">
            <ul>
              <li *ngIf="course.modadalidad">
                <b [innerText]="course.modalidad.nombre"></b>
              </li>
              <li>Carga horaria: <b [innerText]="course.cargaHoraria"></b></li>

              <li *ngIf="course.ubicacionFisica && course.ubicacion">
                Ubicación:
                <a class="text-primary" target="_blank"
                   href="https://www.google.com/maps/search/?api=1&query={{course.ubicacion.ubicacionLat}},{{course.ubicacion.ubicacionLng}}&hl=es-419">
                  <b [innerText]="course.ciudad"></b>
                </a>
              </li>

              <li *ngIf="!course.ubicacionFisica">
                <b>CURSO A DISTANCIA (REMOTO)</b>
              </li>


              <li>Dictantes: <b [innerText]="course.dictantes"></b></li>
              <li>Fecha de inicio: <b>{{course.inicio}} {{course.hora}}</b></li>
              <li>Institucion: <b [innerText]="course.institucion"></b></li>
            </ul>
          </div>
        </div>
        <hr class="gold-border">

        <div class="row pt-3">

          <div class="col col-12 col-lg-6">
            <p class="safe" *ngIf="course.precioComentario" [innerHTML]="course.precioComentario|safeHTML"></p>
          </div>

          <div class="col col-12 col-lg-6" *ngIf="course.vendedor">
            <p class="font-18">Vendedor</p>
            <app-price-card-seller-x [vendedor]="course.vendedor">
              <h4 class="text-center precio-curso mb-2">
                Costo del curso: {{course.precioDescuento ? (course.precioDescuento|price) : (course.precio|price)}}
              </h4>

              <div class="text-center">
                <app-send-whatsapp
                  class="mb-3"
                  *ngIf="whatsappConfig"
                  [config]="whatsappConfig">
                </app-send-whatsapp>
              </div>


            </app-price-card-seller-x>
          </div>

          <div class="col col-12">
            <!--
                        <a (click)="sendBuy(content, contentModalLogin, contentModalEditData)"
               class="btn btn-primary gold-border mb-3 mb-md-0">
              <span class="text">Comprar curso</span>
            </a>
-->

          </div>


        </div>
      </div>
    </div>
  </div>
  <app-title [title]="'DESCRIPCIÓN'" [position]="'left'"></app-title>

  <div class="row g-0">

    <div class="col-12 pe-md-4"
         [class.col-md-8]="course.ubicacionFisica && course.ubicacion"
         [class.col-md-12]="!(course.ubicacionFisica && course.ubicacion)">
      <div class="p-3">
        <ng-container *ngIf="course.cursoPadre?.id">
          <p><b>Este curso es parte de
            <a class="text-primary" [routerLink]="'/courses/' + course.cursoPadre.id"
               [innerText]="course.cursoPadre.nombre"></a></b>
          </p>
        </ng-container>
        <div class="safe" [innerHTML]="course.descripcion|safeHTML"></div>
      </div>
    </div>


    <div class="col-12 col-md-4" *ngIf="course.ubicacionFisica && course.ubicacion">
      <div class="p-3">
        <p class="font-18"><b>UBICACIÓN</b></p>

        <p>
          <a class="text-primary" target="_blank"
             [href]="('https://www.google.com/maps/search/?api=1&query=' + course.ubicacion.ubicacionLat + ',' + course.ubicacion.ubicacionLng + '&hl=es-419')|safeURL"
             [innerText]="course.ciudad"></a>
        </p>
        <br>
        <div style="display: block">
          <iframe
            height="450"
            frameborder="0" style="border:0; max-width: 100%; width: 100%"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            [src]="'https://www.google.com/maps/embed/v1/place?key='+apiKey+'&q=' + course.ubicacion.ubicacionLat + ',' + course.ubicacion.ubicacionLng |safeURL"
            allowfullscreen>
          </iframe>
        </div>

      </div>
    </div>
  </div>


  <ng-container *ngIf="course.cursoIntracongresos?.length ">
    <app-title [title]="'CURSOS INTRACONGRESOS'" [position]="'left'"></app-title>
    <div class="p-3">
      <app-sub-course [items]="course.cursoIntracongresos"></app-sub-course>
    </div>
  </ng-container>

  <app-course-videos [curso]="course"></app-course-videos>

  <div class="p-3" *ngIf="relateds && relateds.length">
    <app-title [title]="'PRODUCTOS RELACIONADOS CON ESTE CURSO'" [position]="'left'"></app-title>
    <app-related-to-product [items]=relateds></app-related-to-product>
  </div>

  <div class="p-3" *ngIf="links && links.length">
    <app-title [title]="'LINKS RELACIONADOS CON ESTE CURSO'" [position]="'left'"></app-title>
    <app-related-to-product [isLink]="true" [items]=links></app-related-to-product>
  </div>


  <ng-container *ngFor="let d of featured">
    <div class="p-3" *ngIf="d.items && d.items.length">
      <app-title [title]="d.categoria" [position]="'left'"></app-title>
       <app-related-to-product [items]=d.items></app-related-to-product>
    </div>
  </ng-container>
  <div class="p-3">
    <app-title [title]="'CALIFICACIONES'" [position]="'left'"></app-title>
    <ul class="list-unstyled px-3">
      <li class="d-flex align-items-center pb-2" *ngIf="!calificaciones?.length">
        <div class="flex-grow-1 ">
          Aún no tenemos calificaciones de este curso
        </div>
      </li>

      <li class="d-flex align-items-center border-bottom-gold pb-2" *ngFor="let c of calificaciones">
        <img [src]="c.cliente.usuario.imagen || defaultAvatar" class="rounded-circle p-1 border" width="90" height="90"
             [alt]="c.cliente.nombreapellido">
        <div class="flex-grow-1 ms-3">
          <app-stars [value]="c.puntuacion"></app-stars>
          {{c.comentario}}
        </div>
      </li>

    </ul>
  </div>
  <!--
    <app-to-share
    [toShared]="toShared"></app-to-share>
-->

</div>

<div class="pt-4 pb-4" *ngIf="questions && questions.length">
  <app-faq [faqs]="questions"></app-faq>
</div>

<ng-template #content let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Confirmar Compra</h5>
    </div>
    <div class="modal-body">
      <p>Estás por Confirmar la Compra del Curso {{course.nombre}}. Te pondremos en contacto
        con {{course.vendedor.razonSocial}} para acordar por el pago y finalizar la inscripción. </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">Cancelar</button>
      <button type="button" class="btn btn-dark" (click)="sendPurchase()">Confirmar compra</button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
<ng-template #contentModalLogin let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="goToRegister()">Registrarme</button>
      <button type="button" class="btn btn-dark" (click)="goToLogin()">Iniciar sesion</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
<ng-template #contentModalEditData let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="goToProfile()">Actualizar mis datos</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
