import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {CategoryLinks, ExternalLinks} from '../../services/external-links.service';
import {SharedService} from '../../shared/shared.service';
import {FormService} from '../form.service';
import {UserProfileService} from '../../user-profile/user-profile.service';

import {
  Cotizacion,
  Marca,
  Producto,
  ProductosUpdate,
  ProductsRegister, TipoCotizacion,
  TipoProducto, VendeProductos
} from './interface-products-register';
import {Router} from '@angular/router';
import {COMMON} from '../../shared/utils/common-texts.utils';
import {FilterGet} from '../../product/main/product.component';
import {Categoria, ProductService} from '../../services/product.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ValidationService} from '../../services/validation.service';
import {FormErrors} from '../../user-profile/user-profile/user-profile.component';
import {timeout} from 'rxjs/operators';

@Component({
  selector: 'app-form-products-register',
  templateUrl: './form-products-register.component.html',
  styleUrls: ['./form-products-register.component.scss']
})
export class FormProductsRegisterComponent implements OnInit {

  isVendedor = false;
  isVendedorAprobado = false;
  registerProductForm: FormGroup;
  categories: CategoryLinks[];
  typesProducts: TipoProducto[];
  brands: Marca[];
  subscriptions: Subscription[];
  links: ExternalLinks[];
  ejecutoScriptCategorias = false;
  ejecutoScriptEspecialidades = false;
  saveForFilter: boolean;
  saveForFilterDelete: boolean;
  saveForFilterComponent: boolean;
  categorias: Array<Categoria> = [];
  titulo = COMMON.producto.registro.registro_de_producto;
  titleTypeProduct = COMMON.filtro.tipo_producto;
  filtros_seleccionados = COMMON.filtro.filtros_seleccionados;
  seleccionado = COMMON.filtro.seleccionado;
  seleccionada = COMMON.filtro.seleccionada;
  categoria = COMMON.filtro.categorias;
  chips = [];
  titleBrand = COMMON.filtro.marcas;
  registerForm: FormGroup;
  formErrors: FormErrors;
  marcas: Array<Marca>;
  tipoProductos: Array<any>;
  increase: number;
  increaseType?: TipoCotizacion;
  withPrices = {id: 1, nombre: 'Ver mis Productos a la Venta', key: 'precio', selected: false};

  fullListProducts: Producto[];
  dropdownSettings: IDropdownSettings = {};
  products: Array<Producto>;

  noHayResultadosFiltro = COMMON.filtro.sin_resultado;
  noexistenProductos = COMMON.filtro.no_existen_productos;

  page = 0;

  cotizaciones: Cotizacion[];
  tipoCotizaciones: TipoCotizacion[];
  spinner = false;

  singleSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'nombre',
    searchPlaceholderText: 'Buscar',
    unSelectAllText: 'Limpiar',
    allowSearchFilter: true,
    closeDropDownOnSelection: false,
    noDataAvailablePlaceholderText: 'Sin Resultados'
  };

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private validation: ValidationService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
    private formService: FormService,
    private toastService: ToastrService,
    private router: Router
  ) {
  }

  private createForm(): void {
    this.registerProductForm = this.fb.group({
      product: this.formBuilder.array([])
    });

  }

  get getitems() {
    return this.registerProductForm.get('product') as FormArray;
  }

  get showItems(): FormArray {
    return (this.registerProductForm.get('product') as any).slice(0, 100) as FormArray;
  }

  private async getCotizaciones(): Promise<void> {
    const response = await this.productService.getCotizaciones().toPromise();
    if(response.status?.value == 200 && response.payload) {
      this.cotizaciones = response.payload.cotizaciones;
      this.tipoCotizaciones = response.payload.tipos;
    } else {
      this.toastService.error('Estamos teniendo problemas para obtener las cotizaciones', `Error`, {timeOut: 6000});
    }
  }

  ngOnInit(): void {
    this.spinner = true;

    this.links = [];
    this.brands = [];
    this.subscriptions = [];
    this.categories = [];
    this.typesProducts = [];
    this.createForm();

    this.saveForFilter = false;
    this.saveForFilterDelete = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.sharedService.presentLoading();
    this.getCotizaciones();
    this.loading();
    this.sharedService.closeLoading();
  }

  //
  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.searchFilter();
    }, (reason) => {
    });
  }

  closeModalFilter(): void {
    this.modalService.dismissAll();
    this.searchFilter();

  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  clearForm(): void {
    const control = this.registerProductForm.controls.product as FormArray;
    control.clear();
  }

  private getIncreaseValue(value: number): number {
    return Number(((this.increase * value / 100) + value).toFixed(2))
  }

  private applyIncrease(control: any): void {
    if(control.value) {
      const value = Number(control.value);
      control.setValue(this.getIncreaseValue(value));
    }
  }

  addIncrement(contentIncrement): void {
    const control = this.registerProductForm.controls.product as FormArray;
    this.modalService.open(contentIncrement, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      control.controls.map((item) => {

        const enabled = item.get('enabled').value;
        const increaseType = this.increaseType.id;
        const itemType = item.get('cotizacion')?.get('tipoCotizacion').value;
        const sameType = (!this.increaseType) || (!itemType && increaseType === 1) || (itemType === increaseType);

        if (enabled && sameType) {
          this.applyIncrease(item.get('oferta'));
          this.applyIncrease(item.get('precio'));
          this.applyIncrease(item.get('cotizacion').get('precio'));
          this.applyIncrease(item.get('cotizacion').get('oferta'));
          this.registerProductForm.markAllAsTouched();
        }
      });
      this.toastService.success(`${COMMON.producto.registro.mensaje_incremento} ${this.increase} %`, `${COMMON.producto.registro.titulo_mensaje_incremento}`, {timeOut: 6000});
      this.increase = 0;
    }, (reason) => {
      this.closeModal();
    });

  }

  setValorFinal(item: any): void {
    const cotizacion = item.value.cotizacion;
    let valor = null;
    if(cotizacion.cotizacion) {
      const cc = this.cotizaciones.find(x => x.id === parseInt(cotizacion.cotizacion));
      if(cc) {
        valor = cc.valor;
        item.get('cotizacion').get('tipoCotizacion').setValue(cc.tipoCotizacion.id || 1);
      }
    }

    item.controls.precio.setValue(null);
    item.controls.oferta.setValue(null);

    if (cotizacion.precio && cotizacion.oferta === '' || cotizacion.oferta === 0 || cotizacion.oferta === '0') {
      item.get('cotizacion').get('oferta').setValue(cotizacion.precio);
    }

    if(cotizacion.cotizacion && cotizacion.precio) {
      item.controls.precio.setValue(!valor ? null : valor*cotizacion.precio);
    }

    if(cotizacion.cotizacion && cotizacion.oferta) {
      item.controls.oferta.setValue(!valor ? null : valor*cotizacion.oferta);
    }

  }

  updateOferta(item: any): void {
    if (item.value.oferta === '' || item.value.oferta === 0 || item.value.oferta === '0') {
      item.controls.oferta.setValue(item.value.precio);
    }
  }

  saveProduct(ev?, value?): void {
    const arraySend = [];
    this.registerProductForm.controls.product.value.map((item) => {

      if(!item.cotizacion?.precio){
        item.cotizacion.precio = 0;
      }

      arraySend.push({
        disponible: item.enabled,
        prodId: item.idProduct,
        precio: item.precio,
        oferta: item.oferta,
        comentario: item.comentario,
        lanzamiento: !!(item.lanzamiento),
        destacado: !!(item.destacado),
        marca: item.marca,
        variantes: item.variantesSelect ? item.variantesSelect.toString() : '',
        cotizacion: item.cotizacion,
      });
    });

    const params: ProductosUpdate = {
      items: arraySend
    };

    this.productService.postSellProducts(params).subscribe((resp: any) => {
      this.toastService.success(`${COMMON.producto.registro.mensaje_gurdado_exitoso}`, resp);
      this.registerProductForm.markAsUntouched();

      if (this.saveForFilterComponent || ev === 'straight') {
        this.saveForFilterComponent = false;
        this.searchFilter();
      }

      if (this.saveForFilter) {
        this.saveForFilter = false;
        this.selectedFilter(ev, value);
      }
      if (this.saveForFilterDelete) {
        this.saveForFilterDelete = false;
        this.selectedFilterDelete(ev);
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.toastService.error(`${COMMON.producto.registro.mensaje_gurdado_fallido}`, `${COMMON.producto.registro.titulo_guardado_fallido}. Error: ${error.status}`);
    });
  }


  filter(content, parmas): void {
    this.chips = parmas;
    if (this.registerProductForm.touched) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        if (this.registerProductForm.valid) {
          this.saveForFilterComponent = true;
          this.saveProduct();
        } else {
          this.closeModal();
          this.searchFilter();
        }
      }, (reason) => {
        this.closeModal();
        this.searchFilter();
      });
    } else {
      this.searchFilter();
    }

    // if (this.registerProductForm.touched) {
    //    this.open(content);
    //    this.saveForFilterComponent = true;
    // } else {
    //   this.searchFilter();
    // }
  }

  selectedFilterDelete(chipSelected): void {
    if (chipSelected.keyType === 'categoria' && this.chips.length) {
      // this.chips = this.chips.filter(chip => chip.id !== chipSelected.id && chip.keyType === chipSelected.keyType );
      this.chips = this.chips.filter(chip => chip.keyType !== chipSelected.keyType);
    }

    if (chipSelected.keyType === 'tipoProducto' && this.chips.length) {
      this.chips = this.chips.filter(chip => chip.id !== chipSelected.id && chip.keyType !== chipSelected.keyType);
    }

    if (chipSelected.keyType === 'marca' && this.chips.length) {
      this.chips = this.chips.filter(chip => chip.id !== chipSelected.id && chip.keyType !== chipSelected.keyType);
    }

    if (chipSelected.keyType === 'precio' && this.chips.length) {
      this.chips = this.chips.filter(chip => chip.id !== chipSelected.id && chip.keyType !== chipSelected.keyType);
      this.withPrices.selected = false;
    }

    if (this.chips.length) {
      this.chips[this.chips.length - 1].filter = true;
    }

    this.searchFilter();
  }

  deleteSelected(content, chipSelected): void {
    if (this.registerProductForm.touched) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        if (this.registerProductForm.valid) {
          this.saveForFilterDelete = true;
          this.saveProduct(chipSelected);
        } else {
          this.closeModal();
          this.selectedFilterDelete(chipSelected);
        }
      }, (reason) => {
        this.closeModal();
        this.selectedFilterDelete(chipSelected);
      });
    } else {
      this.selectedFilterDelete(chipSelected);
    }
  }

  selected(content, ev, value): void {

    if (this.registerProductForm.touched) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        if (this.registerProductForm.valid) {
          this.saveForFilter = true;
          this.saveProduct(ev, value);
        } else {
          this.closeModal();
          this.selectedFilter(ev, value);
        }
      }, (reason) => {
        this.closeModal();
        this.selectedFilter(ev, value);
      });
    } else {
      this.selectedFilter(ev, value);
    }


  }

  selectedFilter(ev, value): void {

    if (ev === 'categoria') {
      // this.chips = this.chips.filter(chip => chip.keyType === 'categoria' && chip.id !== value.id );
      this.chips.map((chip) => {
        chip.filter = false;
      });
      this.chips.push({
        id: value.id,
        tipo: 'Categoria',
        keyType: 'categoria',
        nombre: value.nombre,
        filter: true,
      });
      this.categorias = [];
    }

    if (ev === 'tipoProducto') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'tipoProducto');
      this.chips.push({
        id: value.id,
        tipo: 'Tipo producto',
        keyType: 'tipoProducto',
        nombre: value.nombre,
      });
    }

    if (ev === 'marca') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'marca');
      this.brands = [];
      this.chips.push({
        id: value.id,
        tipo: 'Marca',
        keyType: 'marca',
        nombre: value.nombre,
      });
    }

    if (ev === 'especialidad') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'especialidad');
      this.chips.push({
        id: value.id,
        tipo: 'Especialidad',
        keyType: 'especialidad',
        nombre: value.nombre,
      });
    }

    if (ev === 'texto') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'texto');
      this.chips.push({
        id: value.id,
        tipo: 'texto',
        keyType: 'texto',
        nombre: value.nombre,
      });
    }
    this.searchFilter();
  }

  searchFilter(): void {
    const params: FilterGet = {};
    // this.categorias = [];
    this.typesProducts = [];
    // this.brands = [];

    this.chips.forEach((chip) => {

      if (chip.keyType === 'categoria' && chip.filter) {
        params.categoria = chip.id;
      }

      if (chip.keyType === 'tipoProducto') {
        params.tipoProducto = chip.id;
      }

      if (chip.keyType === 'marca') {
        params.marca = chip.id;
      }

      if (chip.keyType === 'especialidad') {
        params.especialidad = chip.id;
      }

      if (chip.keyType === 'texto') {
        params.texto = chip.nombre;
      }

      if (chip.keyType === 'oferta') {
        params.oferta = chip.id;
      }

      if (chip.keyType === 'precio') {
        params.precio = chip.id;
      }
    });

    this.loading(params);
  }


  enabled(idx, item): void {
    if (item.value.enabled) {
      item.controls.precio.enable();
      item.controls.oferta.enable();
      item.controls.precio.setValidators(Validators.required);

      item.get('cotizacion')?.get('precio').setValidators(Validators.required);
      item.get('cotizacion')?.get('cotizacion').setValidators(Validators.required);
      item.get('cotizacion')?.get('tipoCotizacion').setValidators(Validators.required);


      item.controls.precio.updateValueAndValidity();
    } else {
      item.controls.precio.disable();
      item.controls.oferta.disable();

      item.get('cotizacion')?.get('precio').clearValidators();
      item.get('cotizacion')?.get('cotizacion').clearValidators();
      item.get('cotizacion')?.get('tipoCotizacion').clearValidators();

      item.controls.precio.clearValidators();
      item.controls.oferta.clearValidators();
    }

  }


  loading(params?): void {
    const index = -1;
    this.products = [];
    this.brands = [];
    this.clearForm();
    this.productService.getSellProducts(params).subscribe(
      (response: ProductsRegister) => {
        this.isVendedor = response.vendedor.vendedor;
        this.isVendedorAprobado = response.vendedor.aprobado;

        if (response.filtros.categorias.length) {
          this.categorias = response.filtros.categorias;
        }

        if (response.filtros.tipoProductos.length && !this.chips.filter(chip => chip.keyType === 'tipoProducto').length) {
          this.typesProducts = response.filtros.tipoProductos;
        }

        if (response.filtros.marcas.length && !this.chips.filter(chip => chip.keyType === 'marca').length) {
          // this.typesProducts = [];
          this.brands = response.filtros.marcas;
        }

        this.products = response.productos;
        if (response.productos.length) {
          (this.registerProductForm.controls.product as FormArray).clear();
          this.products.map((product: Producto) => {
            let variantesSelect = [];
            const elementSellProduct = response.vendeproductos.find(product2 => product2.producto === product.id);
            if (elementSellProduct && elementSellProduct.disponibles) {
              variantesSelect = elementSellProduct.disponibles.split(',');
              variantesSelect.map((variant: string, inex: number) => {
                return {
                  id: index,
                  nombre: variant,
                };
              });
            }
            let variantWithStock = [];
            if (product.variantes && product.variantes.length) {
              variantWithStock = product.variantes.split(',');

              variantWithStock.pop();
              variantWithStock.map((variant: string, index: number) => {
                return {
                  id: index,
                  nombre: variant,
                };
              });
            }
            const enabled = !!elementSellProduct?.producto;
            const cotizacion = this.getCotizacionValue(elementSellProduct);
            const control = this.registerProductForm.controls.product as FormArray;
            control.push(this.formBuilder.group({
              precio: [{
                value: elementSellProduct ? elementSellProduct.precio : 0,
                disabled: elementSellProduct && elementSellProduct.producto ? false : true
              }, elementSellProduct && elementSellProduct.producto ? Validators.required : ''],
              oferta: [{
                value: elementSellProduct ? elementSellProduct.oferta : 0,
                disabled: elementSellProduct && elementSellProduct.producto ? false : true
              }],
              idProduct: [product.id, [Validators.required]],
              enabled: [enabled],
              name: [product.nombre],
              nombreMarca: [product.marca.nombre],
              imagenMarca: [product.marca.imagen],
              requerirMarca: [product.marca.requerir],
              marca: [elementSellProduct?.marca || ''],
              comentario: [elementSellProduct?.comentario || ''],
              tipoProducto: [product.tipoProducto.nombre],
              image: [product.imagen],
              variantes: [variantWithStock.length ? variantWithStock : []],
              variantesSelect: [variantesSelect.length ? variantesSelect : []],
              cotizacion: this.formBuilder.group({
                cotizacion: [cotizacion.cotizacion, enabled ? Validators.required : null],
                tipoCotizacion: [cotizacion.tipoCotizacion, enabled ? Validators.required : null],
                precio: [cotizacion.precio, enabled ? Validators.required : null],
                oferta: [cotizacion.oferta, null],
              }),
              destacado: [elementSellProduct?.destacado || false],
              lanzamiento: [elementSellProduct?.lanzamiento || false],

            }));

          });
        }
        this.spinner = false;

      }, (error: HttpErrorResponse) => {
        this.toastService.error(`${COMMON.producto.registro.mensaje_gurdado_fallido}`,
          `${COMMON.producto.registro.titulo_guardado_fallido}`);
        this.spinner = false;
      }
    );

  }

  private getCotizacionValue(vp?: VendeProductos): any {
    const item = {
      cotizacion: undefined,
      tipoCotizacion: vp?.cotizacion?.tipoCotizacion ? vp?.cotizacion.tipoCotizacion?.id : this.tipoCotizaciones.find(x => x.id == 1).id ,
      precio: vp?.cotizacion?.precio ? vp?.cotizacion?.precio : vp?.precio,
      oferta: vp?.cotizacion?.oferta ? vp?.cotizacion?.oferta : vp?.oferta,
    };
    item.cotizacion = this.cotizaciones.find(x => x.tipoCotizacion?.id == item.tipoCotizacion).id;
    return item;
  }

  getTipoCotizacionItem(item: any): string {
    const type = item.get('cotizacion')?.get('tipoCotizacion')?.value || 1;
    return `(${this.tipoCotizaciones.find((x => x.id === type))?.nombre || ''})`;
  }

}
