<!--<div class="search-bar flex-grow-1">-->
<!--  <div class="position-relative search-bar-box" >-->
<!--    <input type="text" class="form-control search-control" placeholder="Ingresá lo que quieras encontrar"> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>-->
<!--    <span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>-->
<!--  </div>-->
<!--</div>-->
<div class="row">
  <div class="col-10">
    <div class="position-relative">
      <input type="text" class="form-control ps-5" placeholder="Ingresá lo que quieras encontra...">
      <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
    </div>
  </div>
</div>
