<div class="box-whatsapp mt-4 mb-5 hidden-desktop">
  <div class="d-inline-flex">
    <div>
      <a [href]="whatsapp" target="_blank" class="btn btn-success btn-whatsapp" >
        <i class='bx bxl-whatsapp'></i>
      </a>
    </div>
    <div class="p-1 ms-3 mt-2">
      <h4>{{title}}</h4>
      <p>{{description}} </p>
    </div>
  </div>
</div>

<div class="box-whatsapp mt-4 mb-5 hidden-mobile">
  <div class="d-inline-flex">
    <div class="icon">
      <a [href]="whatsapp" target="_blank" class="btn btn-success btn-whatsapp" >
        <i class='bx bxl-whatsapp'></i>
      </a>
    </div>
    <div class="p-1 ms-3 mt-2">
      <h6>{{title}}</h6>
    </div>

  </div>
  <p>{{description}} </p>
</div>

