import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  @Input() placeholder: string;
  @Input() css = '';
  @Output() searchByText = new EventEmitter<string>();
  textSubject: Subject<string> = new Subject();
  text: string;

  textChanged(event: any): void {
    this.textSubject.next(event);
  }

  constructor() {
    this.textSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
      this.text = value;
      this.searchByText.emit(value)
    });
  }

  ngOnInit(): void {
  }

}
