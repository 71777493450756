import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

// Service
import { FormService } from '../form.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Specialty } from 'src/app/services/especialidad.service';

// Interface
import { State } from 'src/app/shared/interfaces/state';
import { Curso, MisCursos } from './interface-my-courses';
import { Vendedor } from '../form-products-register/interface-products-register';
import {environment} from "../../../environments/environment";
import { COMMON } from '../../shared/utils/common-texts.utils';
import { Router } from '@angular/router';


@Component({
  selector: 'app-my-courses',
  templateUrl: './form-my-courses.component.html',
  styleUrls: ['./form-my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit {

  coursesForm: FormGroup;
  allCourses: Curso[];
  courses: Curso[];
  seller: Vendedor;
  states: State[];
  specialties: Specialty[];
  subscriptions: Subscription[];
  defaultNoImage = environment.defaultNoImage;
  listadoVacio = COMMON.listado_vacio.mesaje_miscursos;
  showAlertSeller: boolean;
  showMessageCourse: boolean;
  constructor( private fb: FormBuilder,
               private sharedService: SharedService,
               private router: Router,
               private formService: FormService ) {

    this.subscriptions = [];
    this.states = [
      {
        id: 1,
        name: 'TODOS'
      },
      {
        id: 2,
        name: 'PENDIENTE DE APR.',
        active: true,
        passed: false
      },
      {
        id: 3,
        name: 'PUBLICADO',
        active: true,
        passed: true
      },
      {
        id: 4,
        name: 'FINALIZADO',
        active: false
      }

    ];

    this.courses = [];
  }

  ngOnInit(): void {
    this.showMessageCourse = false;
    this.showAlertSeller = false;
    this.coursesForm = this.fb.group({
    });

      this.formService.getCourses().subscribe((resp: MisCursos) => {
        this.allCourses = resp.cursos;
        if(this.allCourses && this.allCourses.length){
          this.showMessageCourse = this.allCourses.filter((course) => course.aprobado === false).length ? true : false;
        }
        this.showAlertSeller = !resp.vendedor.aprobado;
        this.courses = resp.cursos;
        console.log(this.courses);
        this.seller = resp.vendedor;
      });

      this.formService.getSpecialties().subscribe((resp: Array<Specialty>) => {
        if (resp.length > 0) {

          this.specialties = resp;
          this.sharedService.closeLoading();
        }
      });

  }

  navigateAdd(): void{
    this.router.navigate(['/form/add-course']);
  }

  search(searchTerms: string): void{

    const textSearch: string = searchTerms.trim().toLocaleLowerCase();
    const statesSelected = [];
    const specialtiesFilter = [];

    if ( textSearch === '' && statesSelected.length === 0 && specialtiesFilter.length === 0 ) {
      this.courses = [ ...this.allCourses ];
    } else {

      const statesFilter = this.states.filter(state => statesSelected.indexOf(state.id) > -1 );

      this.courses = this.allCourses.filter((course: Curso) => {

        return (textSearch !== '' ? course.nombre.toLocaleLowerCase().includes(textSearch) : true)
          && (statesFilter.length > 0 ? statesFilter.find((state: any) => {
            let flag = false;

            switch (state.id) {
              case 1:
                flag = true;
                break;
              case 2:
                flag = state.active === course.activo && state.passed === course.aprobado;
                break
              case 3:
                flag = state.active === course.activo && state.passed === course.aprobado;
                break;
              case 4:
                flag = state.active === course.activo;
                break;
              default:
                flag = true;
                break;
            }

            return flag;

          }) : true)
          && (specialtiesFilter.length > 0 ? specialtiesFilter.indexOf(course.especialidad.id) > -1 : true);

      });
    }

  }

}
