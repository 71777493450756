import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { ProductosUpdate } from '../form/form-products-register/interface-products-register';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../../environments/environment';
import { map } from 'rxjs/operators';


export interface Favorite {
  id?: number,
  imagen: string,
  producto: string,
  categoria: string,
  tipo: string,
  marca: string,
  puntuacion?: number,
  puntuaciones?: any,
  variante?: string,
  cantidad?: number,
  //tipo?: TipoProducto,
}

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {

  favouritePath = '/usuariosapp/favoritos';
  constructor(private apiService: ApiService) { }

  saveFavourite(params): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.favouritePath}`, params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getAllFavorites(): Observable<Array<Favorite>> {
    return this.apiService.get(apiBaseUrl, this.favouritePath)
      .pipe(
        map((response) => response.payload));
  }


}
