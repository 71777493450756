import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderTable, ProductoVendedor, Seller } from '../../cart/main/cart-main.component';
import { Product } from '../../services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-product-detail-cart',
  templateUrl: './product-detail-cart.component.html',
  styleUrls: ['./product-detail-cart.component.scss']
})
export class ProductDetailCartComponent implements OnInit {
  @Output() deletePressed: EventEmitter<void> = new EventEmitter();
  @Output() savePressed: EventEmitter<any> = new EventEmitter();
  @Output() changeQuantityPressed: EventEmitter<void> = new EventEmitter();
  @Input() headerTable: Array<HeaderTable>;
  @Input() productoVendedor: ProductoVendedor;


  DEFAULT_NULL_PRICE = 9999999999999;

  headerVededor: Array<HeaderTable>;
  productosPorPrecio: Array<any>;
  product: any;

  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.headerVededor = this.productoVendedor.headerVededor;
    this.productosPorPrecio = this.productoVendedor.productosPorPrecio;
  }

  delete(product): void{
    this.deletePressed.emit(product);
  }

  sendBuy(content, contentModalLogin, contentModalEditData, product): void{
    this.product = product;
    const idToken = this.authService.loadStorage('idToken');
    const userProfile = this.authService.loadStorage('userProfile');
    if (idToken){
      this.open(userProfile.comprador ? content : contentModalEditData);
    }else{
      this.open(contentModalLogin);
    }
  }

  open(content): void{
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title' });
  }

  calculateTotalCost(selectedProduct?): void{
    this.changeQuantityPressed.emit(selectedProduct);
  }

  plus(p): void {
    p.cantidad += 1;
    this.calculateTotalCost(p);
  }

  subtract(p): void {
    if (p.cantidad > 1) {
      p.cantidad -= 1;
    }
    this.calculateTotalCost(p);
  }

  closeModal(): void{
    this.modalService.dismissAll();
  }

  sendPurchase(): void{
    this.closeModal();
    const product = [{
      cantidad: this.product.cantidad,
      id: Number(this.product.id),
      imagen: this.product.imagen,
      marca: this.product.marca,
      nombre: this.product.nombre,
      oferta: this.product.oferta,
      precio: this.product.precio,
      variante: this.product.variante,

    }];
    const seller = {
      idSeller: this.product.vendedormejorPrecio.id,
      image: this.product.vendedormejorPrecio.imagen,
      name: this.product.vendedormejorPrecio.usuario,
      paymentOptions: this.product.vendedormejorPrecio.opcionesPago,
      products: product,
      province: this.product.vendedormejorPrecio.localidad,
      shippingOptions: this.product.vendedormejorPrecio.opcionesEnvio,
      totalPrice: this.product.oferta,
    };
    /*   shippingType: "Envío a acordar",
        totalPrice: 100,*/

     this.savePressed.emit(seller);
  }

  goToProfile(): void{
    this.closeModal();
    this.router.navigate(['profile'], { queryParams: { url: 'cart'}});
  }

  goToLogin(): void{
    this.closeModal();
    this.router.navigate(['auth/sign-in'], { queryParams: { url: 'cart'  }});
  }

  goToRegister(): void{
    this.closeModal();
    this.router.navigate(['auth/sign-up/customer'], { queryParams: { url: 'cart' }});
  }
}
