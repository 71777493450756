import {Component, OnInit, ViewChild} from '@angular/core';
import {Cotizacion, ProductsRegister, TipoCotizacion} from "../form/form-products-register/interface-products-register";
import {Marca, ProductService} from "../services/product.service";
import {ToastrService} from "ngx-toastr";
import {RecomendarProductoComponent} from "../product/recomendar-producto/recomendar-producto.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VpPreciosComponent} from "./vp-precios/vp-precios.component";
import {VpProductosComponent} from "./vp-productos/vp-productos.component";
import {VpMarcasComponent} from "./vp-marcas/vp-marcas.component";

@Component({
  selector: 'app-vendeproductos',
  templateUrl: './vendeproductos.component.html',
  styleUrls: ['./vendeproductos.component.scss']
})
export class VendeproductosComponent implements OnInit {

  marcas: Marca[];
  tabs = ['Elige tus marcas', 'Elige tus productos', 'Coloca tus precios', 'Precarga un producto'];
  currentTab = 0;

  tabComponent: any;
  modalChanges = false;
  nextTab?: number;

  cotizaciones: Cotizacion[];
  tipoCotizaciones: TipoCotizacion[];

  info: ProductsRegister;
  spinner = false;
  ready = false;

  @ViewChild('PreciosRef') PreciosRef: VpPreciosComponent;
  @ViewChild('ProductosRef') ProductosRef: VpProductosComponent;
  @ViewChild('MarcasRef') MarcasRef: VpMarcasComponent;

  constructor(
    private productService: ProductService,
    private toastService: ToastrService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getnfoVendedor({});
    this.getInfo();
    this.getCotizaciones();
  }

  getnfoVendedor(params?: any): void {
    this.spinner = true;
    this.productService.getSellProducts(params).subscribe(
      (response: ProductsRegister) => {
        this.spinner = false;
        this.info = response;
        this.initMarcasSeleccionadas();
      }, (error => {
        this.spinner = false;
        console.error(error)
      }));
  }

  initMarcasSeleccionadas(): void {
    (this.info?.vendedor?.marcas||'').split(',').forEach(x => {
      const marca = (this.marcas||[]).find(m => m.id === parseInt(x));
      if(marca) {
        marca.selected = true;
      }
    })

    this.ready =this.info?.vendedor && Array.isArray(this.marcas);
  }


  vpMarcas(marcas: string): void {
    this.info.vendedor.marcas = marcas;
    this.initMarcasSeleccionadas();
    this.currentTab++;
  }


  getInfo(): void {
    this.productService.getInfoProductos()
      .subscribe((info: any) => {
        this.marcas = info.marcas.sort((a, b) => a.nombre > b.nombre ? 1 : -1);
        this.initMarcasSeleccionadas();
      })
  }

  private async getCotizaciones(): Promise<void> {
    const response = await this.productService.getCotizaciones().toPromise();
    if(response.status?.value == 200 && response.payload) {
      this.cotizaciones = response.payload.cotizaciones;
      this.tipoCotizaciones = response.payload.tipos;
    } else {
      this.toastService.error('Estamos teniendo problemas para obtener las cotizaciones', `Error`, {timeOut: 6000});
    }
  }

  getComponent(id: number): any {
    switch (id) {
      case 0:
        return this.MarcasRef;
      case 1:
        return this.ProductosRef;
      case 2:
        return this.PreciosRef;
    }
    return null;
  }

  setTab(event: number): void {
      if(event === (this.tabs.length-1)) {
        this.recomendarProducto();
        return;
      }
      this.tabComponent = this.getComponent(this.currentTab);

      if(this.tabComponent && this.tabComponent.changes) {
        this.modalChanges = true;
        this.nextTab = event;
      } else {
        this.currentTab = event;
      }
  }

  closeChangesTab(confirm: boolean): void {
      this.modalChanges = false;
      if(confirm) {
        this.tabComponent.submit();
        setTimeout(() => {
          this.currentTab = this.nextTab;
        }, 200);
      } else {
        this.currentTab = this.nextTab;
      }
  }

  recomendarProducto(): void {
    const modalRef = this.modalService.open(RecomendarProductoComponent, {size: 'xl'});
  }

}
