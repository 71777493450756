import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum2string'
})
export class Enum2stringPipe implements PipeTransform {

  transform(value: any): string {
    const r0 = (value || '').toString();
    const r1 = r0.replace(/([A-Z])/g, " $1");
    const r2 = r1.charAt(0).toUpperCase() + r1.slice(1);

    let re = /_/gi;
    let r3 = r2.replace(re, " ");
    return r3;
  }

}
