import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Specialty } from '../../services/especialidad.service';

import { baseUrl } from '../../../environments/environment';

@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styleUrls: ['./especialidades.component.scss']
})
export class EspecialidadesComponent implements OnInit {
  @Input() specialty: Array<Specialty>;
  @Output() searchFilterSpecialty: EventEmitter<any> = new EventEmitter();

  baseUrl: string;

  constructor() {
  }

  ngOnInit(): void {
    this.baseUrl = baseUrl + '/web/uploads/especialidad/';
  }

  search(image: any): void {
    this.searchFilterSpecialty.emit( image  );
  }

}
