<div class="section-container" *ngIf="items && items.length">
  <div class="p-1">
    <app-title class="historial-title"
      textTransform="uppercase"
      title="{{type+'s'|titlecase}} vistos recientemente">
    </app-title>

    <div class="historial">
      <div *ngFor="let hist of items" class="card card-historial pointer bg-dark"
           [routerLink]="hist.item.url + hist.item.slug">
        <img [src]="hist.item.imagen ? hist.item.imagen : defaultNoImage" class="img-fluid" [alt]="hist.item.nombre">
        <p class="mt-1 mb-1 text-3l-dots p-2">{{hist.item.nombre}}</p>
      </div>
    </div>
  </div>
</div>
