<app-loading *ngIf="loading"></app-loading>

<ng-container *ngIf="!loading">
  <div class="box-marcas">
    <ng-container *ngFor="let m of marcas">
      <div class="marca" *ngIf="m.selected" [class.selected]="m.id == marca?.id">
        <img class="cursor-pointer" (click)="setMarca(m)" [src]="m.imagen" [alt]="m.nombre" (error)="logoError($event)">
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class="col col-12 col-md-3" >
      <app-searchbar placeholder="Buscar.." (searchByText)="setTexto($event)"></app-searchbar>
    </div>

  </div>

  <div class="row">
    <div class="col col-12" *ngIf="!productos?.length">
      <p class="mt-2 mb-2 text-center">Sin resultados</p>
    </div>
  </div>

  <div class="row mt-4">

    <div class="col col-12 col-md-6" *ngFor="let item of productos; let idx = index;">


      <ng-container *ngIf="item.producto">
        <div class="card bg-dark mb-4 p-3 gold-border" [class.card-selected]="item.selected">
          <div class="card-body">
            <div class="row">

              <div class="col col-6 col-md-2">
                <a [href]="item.producto.marca?.imagen" target="_blank">
                  <img
                    (error)="logoError($event)"
                    [src]="item.producto.marca?.imagen"
                    class="img-thumbnail mb-2 me-2"
                    [alt]="item.producto.marca?.nombre"/>
                </a>
              </div>
              <div class="col col-6 col-md-2">
                <a [href]="item.producto.imagen" target="_blank">
                  <img
                    (error)="logoError($event)"
                    [src]="item.producto.imagen"
                    class="img-thumbnail mb-2 me-2"
                    [alt]="item.producto.imagen">
                </a>
              </div>
              <div class="col col-12 col-md-6">

                <h5 class="mt-0" [innerText]="item.producto.nombre"></h5>
                <p
                  *ngIf="item.producto.marca?.nombre"
                  class="mb-0 font-13 text-secondary"
                  [innerText]="item.producto.marca.nombre"></p>
                <p
                  *ngIf="item.producto.tipoProducto"
                  class="mb-0 font-13 text-secondary"
                  [innerText]="item.producto.tipoProducto.nombre"></p>

                <p>
                  <small [innerText]="item.selected ? 'Vendo este producto' : 'No vendo o no tengo stock'">
                  </small>
                </p>
              </div>

              <div class="col col-12 col-md-2">
                <div class="mt-2 mb-2 form-check form-switch" style="float: right">
                  <input style="font-size: 32px" class="form-check-input" type="checkbox" [(ngModel)]="item.selected" (ngModelChange)="changes = true"/>
                </div>
              </div>

            </div>


          </div>
        </div>
      </ng-container>

    </div>

  </div>

  <div class="fixed-bottom floating-box">
    <button type="button" class="btn btn-primary px-5" (click)="submit()">
      Guardar Cambios
    </button>
  </div>

  <app-odonto-confirm
    *ngIf="modalChanges"
    (destroy)="closeConfirmChanges($event)"
    title="Tenés cambios sin guardar"
    description="¿Queres guardar y continuar?"
    cancel="CONTINUAR SIN GUARDAR"
    submit="GUARDAR Y CONTINUAR"
  ></app-odonto-confirm>

</ng-container>
