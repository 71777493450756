import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {Marca, ProductService, Response} from "../../services/product.service";
import {ToastrService} from "ngx-toastr";
import {COMMON} from "../../shared/utils/common-texts.utils";

@Component({
  selector: 'app-recomendar-producto',
  templateUrl: './recomendar-producto.component.html',
  styleUrls: ['./recomendar-producto.component.scss']
})
export class RecomendarProductoComponent implements OnInit {

  form: FormGroup;
  loading = 0;
  fileList: Array<File> = [];
  files: any[];
  step = 0;

  marcas: Marca[];
  marcasReady = true;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Descripción...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo']
    ]
  };

  singleSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'nombre',
    searchPlaceholderText: 'Buscar',
    unSelectAllText: 'Limpiar',
    allowSearchFilter: true,
    closeDropDownOnSelection: false,
    noDataAvailablePlaceholderText: 'Sin Resultados'
  };

  requireds = [
    {key: 'nombre'},
    {key: 'marca'},
  ];

  constructor(
    private toastService: ToastrService,
    public activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
    private productService: ProductService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.getMarcas();
    this.loadForm();
  }

  private loadForm(): void {
    this.form = this.formBuilder.group({
      marca: [null, [Validators.required]],
      nombre: ['', [Validators.required]],
      variantes: [null],
      imagenes: [null],
      web: [null],
      descripcion: [null],
    });
  }

  submitForm(): void {
    this.loading++;

    const form = this.form.value;
    const producto = {
      nombre: form.nombre,
      web: form.web,
      descripcion: form.descripcion,
      marca: form.marca?.[0]?.id,
      imagenes: this.files,
      variantes: (form.variantes||[]).map(x => x.value).join(', '),
    }
    console.log(producto);
    this.productService.recomendarProducto(producto)
      .subscribe((info: any) => {
        this.step = 1;
        this.loading--;
      }, (error => {
        this.loading--;
        this.toastService.error(COMMON.errores.general, `Error`);
        console.error(error);
      }))


  }

  fileChange(event): void {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; ++i) {
        const file = event.target.files[i];
        this.fileList.push(file);
      }
    }
    this.convertBase64();
  }

  convertBase64(): void {
    this.files = [];
    for (const image of this.fileList) {
      const myReader: FileReader = new FileReader();
      const file: File = image;
      myReader.onloadend = (e) => {
        const prueba = myReader.result as string;
        this.files.push(prueba);
      };
      myReader.readAsDataURL(file);
    }
  }

  remove(deleteImage): void {
    if (this.fileList && this.fileList.length) {
      this.fileList = this.fileList.filter(image => image !== deleteImage);
    }
  }

  getMarcas(): void {
    this.productService.getInfoProductos()
      .subscribe((info: any) => {
        this.marcas = info.marcas.sort((a, b) => a.nombre > b.nombre ? 1 : -1);
      })
  }

  defaultMarca(): void {
    const otra = this.marcas.find(x => !!x.requerir)
    this.form.get('marca')?.setValue([otra]);
  }

}
