<!--<div class="row">-->
<!--  <div class="col-12">-->
<div class="row align-items-center " style="margin-left: 1px;">
  <div class="col-12 px-0">
    <form class="float-lg-star">
      <div class="row g-2 justify-content-between">

        <div class="col col-10 position-relative mb-2" [class.col-md-12]="!showMyProducts"
             [class.col-md-2]="showMyProducts">
          <input type="text" class="form-control ps-5 "
                 [(ngModel)]="nombre" (ngModelChange)="buscar($event)" [ngModelOptions]="{standalone: true}">
          <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <div class="col col-2 d-md-none">
          <div class="btn-group" role="group">
            <div class="btn-group" role="group">
              <button id="btnGroupDrop1" type="button"
                      class="btn btn-white dropdown-toggle dropdown-toggle-nocaret px-1" data-bs-toggle="dropdown"
                      aria-expanded="false">
                <i class='bx bx-slider'></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="btnGroupDrop1">
                <div *ngIf="chips && chips.length">
                  <h6>{{filtros_seleccionados}}</h6>
                  <div *ngFor="let chip of chips let i=index;" class="mr-b" title="{{chip.nombre}}">
                    <div class="chip-filter">
                      <span class="name">{{chip.nombre}}</span>
                      <span class="closebtn" *ngIf="i == (chips.length-1)" (click)="deleteSelected(chip)"
                            onclick="this.parentElement.style.display='none'">×</span>
                    </div>
                  </div>
                </div>


                <!-- Categorias -->
                <app-sidebar-collapse-filter
                  *ngIf="categorias?.length"
                  name="Categorías"
                  [items]="categorias"
                  (onSelect)="selected('categoria', $event)">
                </app-sidebar-collapse-filter>


                <!-- Tipo de productos -->
                <app-sidebar-collapse-filter
                  *ngIf="tipoProductos?.length"
                  name="Tipo de Producto"
                  [items]="tipoProductos"
                  (onSelect)="selected('tipoProducto', $event)">
                </app-sidebar-collapse-filter>

                <!-- Marcas -->
                <app-sidebar-collapse-filter
                  *ngIf="marcas?.length"
                  name="Marcas"
                  [items]="marcas"
                  (onSelect)="selected('marca', $event)">
                </app-sidebar-collapse-filter>

                <!-- Especialidades -->
                <app-sidebar-collapse-filter
                  *ngIf="especialidades?.length"
                  name="Especialidades"
                  [items]="especialidades"
                  (onSelect)="selected('especialidad', $event)">
                </app-sidebar-collapse-filter>

              </ul>
            </div>
          </div>
        </div>

        <div class="col col-12 col-md-6 mx-0 px-0 d-flex justify-content-end" *ngIf="showMyProducts">
          <button
            type="button"
            class="btn btn-gold me-md-2"
            (click)="recomendarProducto()">
            PRECARGAR UN PRODUCTO
          </button>

          <button
            *ngIf="!showingMyProducts"
            type="button"
                  class="btn btn-gold"
            (click)="this.selected('precio', {id: 1, nombre: 'Mis Productos a la Venta'});">
            VER MIS PRODUCTOS A LA VENTA
          </button>
        </div>

      </div>
    </form>
  </div>
</div>
<!--  </div>-->
<!--</div>-->
