import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {FooterComponent} from "./footer/footer.component";
import {NavbarComponent} from "./navbar/navbar.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {ColorSwitcherComponent} from './color-switcher/color-switcher.component';
import {SearchComponent} from './search/search.component';
import {EspecialidadesComponent} from './especialidades/especialidades.component';

import {ProductCardComponent} from './product-card/product-card.component';
import {FilterComponent} from './filter/filter.component';
import {SellerCardComponent} from './seller-card/seller-card.component';
import {ProductDetailCartComponent} from './product-detail-card/product-detail-cart.component';
import {CourseFilterComponent} from './course-filter/course-filter.component';
import {ThanksPurchaseComponent} from './thanks-purchase/thanks-purchase.component';
import {StarsComponent} from './stars/stars.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingComponent} from './loading/loading.component';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n} from "./datepicker-adapter";
import {HistorialUsuarioComponent} from './historial-usuario/historial-usuario.component';
import {AlertComponent} from './alert/alert.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {NotificationsModule} from "../notifications/notifications.module";
import {CtaWhatsappComponent} from './cta-whatsapp/cta-whatsapp.component';
import {FavoriteProductComponent} from './favorite-product/favorite-product.component';
import {RelatedToProductComponent} from './related-to-product/related-to-product.component';
import {ToShareComponent} from './to-share/to-share.component';
import {CardSingleProductPurchaseComponent} from './card-single-product-purchase/card-single-product-purchase.component';
import {HeaderComponent} from './header/header.component';
import {ModalSharedComponent} from './modal-shared/modal-shared.component';
import {ModalLinkComponent} from './modal-link/modal-link.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FooterSellerComponent} from './footer-seller/footer-seller.component';
import {SafeHTMLPipe} from "./pipes/safeHTML.pipe";
import {Format2datePipe} from "./pipes/format2date.pipe";
import {SafeURLPipe} from "./pipes/safeURL.pipe";
import {Enum2stringPipe} from "./pipes/enum2string.pipe";
import { HeaderMainComponent } from './components/header-main/header-main.component';
import { SocialComponent } from './components/social/social.component';
import { CardHomeComponent } from './components/card-home/card-home.component';
import { TitleComponent } from './components/title/title.component';
import { RecommendedComponent } from "./components/carousel/recommended/recommended.component";
import { BannerWithBlinkingComponent } from './components/banner-with-blinking/banner-with-blinking.component';
import { HomeSellerCardComponent } from './components/home-seller-card/home-seller-card.component';
import { FilterProductComponent } from './filter-product/filter-product.component';
import { ButtonScrollComponent } from './button-scroll/button-scroll.component';
import { MenuLineComponent } from './components/menu-line/menu-line.component';
import { StyleCardProductComponent } from './components/style-card-product/style-card-product.component';
import { CartComponent } from './cart/cart.component';
import { SidebarProductComponent } from './components/sidebar-product/sidebar-product.component';
import { IframeUrlComponent } from './iframe-url/iframe-url.component';
import { SendWhatsappComponent } from './send-whatsapp/send-whatsapp.component';
import {ClickStopPropagation} from "./pipes/click-stop-propagation";
import {CardsComponent} from "../components/cards/cards.component";
import {CarouselComponent} from "../components/carousel/carousel.component";
import {ListGroupsComponent} from "../components/list-groups/list-groups.component";
import {MediaObjectsComponent} from "../components/media-objects/media-objects.component";
import {NavsComponent} from "../components/navs/navs.component";
import {RateModalComponent} from "../components/rate-modal/rate-modal.component";
import {SpinnersComponent} from "../components/spinners/spinners.component";
import {FilterGoogleMapsComponent} from "../components/filter-google-maps/filter-google-maps.component";
import {SidebarCollapseFilterComponent} from "../components/sidebar-collapse-filter/sidebar-collapse-filter.component";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import {AboutUsComponent} from "../about-us/about-us.component";
import { OdontoTabsComponent } from './components/odonto-tabs/odonto-tabs.component';
import { OdontoAccordionComponent } from './components/odonto-accordion/odonto-accordion.component';
import { ProductItemListComponent } from './components/product-item-list/product-item-list.component';
import { AddProductCartComponent } from './components/add-product-cart/add-product-cart.component';
import {SearchbarComponent} from "./searchbar/searchbar.component";
import { OdontoConfirmComponent } from './odonto-confirm/odonto-confirm.component';
import {QuillModule} from "ngx-quill";
import {RichTextComponent} from "../components/rich-text/rich-text.component";
import {PriceCardSellerXComponent} from "../components/price-card-seller-x/price-card-seller-x.component";
import {PriceCardSellerYComponent} from "../components/price-card-seller-y/price-card-seller-y.component";
import {GoogleAutocomplete} from "../components/google-autocomplete/google-autocomplete.component";
import {FormImageComponent} from "./form-image/form-image.component";
import {CursoLinksComponent} from "../components/curso-links/curso-links.component";
import {CursoFormLinkComponent} from "../components/curso-form-link/curso-form-link.component";
import {PricePipe} from "./pipes/price.pipe";


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    NgbModule,
    SearchComponent,
    EspecialidadesComponent,
    ProductCardComponent,
    FilterComponent,
    SellerCardComponent,
    ProductDetailCartComponent,
    CourseFilterComponent,
    ThanksPurchaseComponent,
    StarsComponent,
    LoadingComponent,
    HistorialUsuarioComponent,
    AlertComponent,
    CtaWhatsappComponent,
    FavoriteProductComponent,
    RelatedToProductComponent,
    ToShareComponent,
    HeaderComponent,
    FooterSellerComponent,
    SafeHTMLPipe,
    Format2datePipe,
    SafeURLPipe,
    Enum2stringPipe,
    ClickStopPropagation,
    HeaderMainComponent,
    CardHomeComponent,
    TitleComponent,
    RecommendedComponent,
    BannerWithBlinkingComponent,
    HomeSellerCardComponent,
    FilterProductComponent,
    ButtonScrollComponent,
    MenuLineComponent,
    SidebarProductComponent,
    SendWhatsappComponent,
    CardsComponent,
    CarouselComponent,
    ListGroupsComponent,
    MediaObjectsComponent,
    NavsComponent,
    NavbarComponent,
    RateModalComponent,
    SpinnersComponent,
    RateModalComponent,
    FilterGoogleMapsComponent,
    SidebarCollapseFilterComponent,
    AboutUsComponent,
    OdontoTabsComponent,
    OdontoAccordionComponent,
    ProductItemListComponent,
    AddProductCartComponent,
    SearchbarComponent,
    OdontoConfirmComponent,
    QuillModule,
    RichTextComponent,
    PriceCardSellerXComponent,
    PriceCardSellerYComponent,
    GoogleAutocomplete,
    FormsModule,
    FormImageComponent,
    CursoFormLinkComponent,
    CursoLinksComponent,
    PricePipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    PerfectScrollbarModule,
    IvyCarouselModule,
    FormsModule,
    NotificationsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    GooglePlaceModule,
    AgmCoreModule,
    QuillModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    SearchComponent,
    EspecialidadesComponent,
    ProductCardComponent,
    FilterComponent,
    SellerCardComponent,
    ProductDetailCartComponent,
    CourseFilterComponent,
    ThanksPurchaseComponent,
    StarsComponent,
    LoadingComponent,
    HistorialUsuarioComponent,
    AlertComponent,
    FeedbackComponent,
    CtaWhatsappComponent,
    FavoriteProductComponent,
    RelatedToProductComponent,
    ToShareComponent,
    CardSingleProductPurchaseComponent,
    HeaderComponent,
    ModalSharedComponent,
    ModalLinkComponent,
    FooterSellerComponent,
    SafeHTMLPipe,
    Format2datePipe,
    SafeURLPipe,
    Enum2stringPipe,
    ClickStopPropagation,
    HeaderMainComponent,
    SocialComponent,
    CardHomeComponent,
    TitleComponent,
    RecommendedComponent,
    BannerWithBlinkingComponent,
    HomeSellerCardComponent,
    FilterProductComponent,
    ButtonScrollComponent,
    MenuLineComponent,
    StyleCardProductComponent,
    CartComponent,
    SidebarProductComponent,
    IframeUrlComponent,
    SendWhatsappComponent,
    CardsComponent,
    CarouselComponent,
    ListGroupsComponent,
    MediaObjectsComponent,
    NavsComponent,
    NavbarComponent,
    RateModalComponent,
    SpinnersComponent,
    RateModalComponent,
    FilterGoogleMapsComponent,
    SidebarCollapseFilterComponent,
    AboutUsComponent,
    OdontoTabsComponent,
    OdontoAccordionComponent,
    ProductItemListComponent,
    AddProductCartComponent,
    SearchbarComponent,
    OdontoConfirmComponent,
    RichTextComponent,
    PriceCardSellerXComponent,
    PriceCardSellerYComponent,
    GoogleAutocomplete,
    FormImageComponent,
    CursoLinksComponent,
    CursoFormLinkComponent,
    PricePipe,
  ],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ],
})
export class SharedModule {
}
