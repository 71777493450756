import {Component, Input, OnInit} from '@angular/core';

import Quill from 'quill';
import * as QuillEmoji from 'quill-emoji';
import {FormControl, FormGroup} from "@angular/forms";

Quill.register('modules/emoji', QuillEmoji);

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() name: string;
  @Input() placeholder: string;

  emojiConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['code-block'],
      [{header: 1}, {header: 2}], // custom button values
      [{list: 'ordered'}, {list: 'bullet'}],
      // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{indent: '-1'}, {indent: '+1'}], // outdent/indent
      [{direction: 'rtl'}], // text direction
      [{size: ['small', false, 'large', 'huge']}], // custom dropdown
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{align: []}],
      ['clean'], // remove formatting button
      ['link', 'image', 'video'],
      ['emoji']
    ],
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
