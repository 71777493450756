import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product, Variante } from '../../services/product.service';
import { Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { COMMON } from "../utils/common-texts.utils";
import { Cart, StorageService } from "../../services/storage.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Output() addProdcutCart: EventEmitter<any> = new EventEmitter();
  @Output() removeProductFavorite: EventEmitter<any> = new EventEmitter();
  @Output() refreshProduct: EventEmitter<any> = new EventEmitter();

  @Input() slug: string;
  @Input() urlImg: any;
  @Input() params: any;
  @Input() id: any;
  @Input() product: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() action: boolean;
  @Input() courses: boolean;
  @Input() isProduct: boolean;
  @Input() precio: string;
  @Input() puntuacion: number;
  @Input() preciodescuento: string;
  @Input() marca: string = null;
  @Input() isFavorite = false;
  @Input() precioDesde = null;
  @Input() vendedores = null;
  @Input() vendedorCurso = null;
  @Input() cursoPadre = null;
  @Input() fechaInicio = null;

  @Input() vendedorId: number|null = null;


  variantes: Array<Variante> = [];
  quantity = 1;

  constructor(
    private router: Router,
    private toastService: ToastrService,
    private storageService: StorageService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  addProdcut(): void{
  }

  removeFavorite(): void{
    ;
    this.removeProductFavorite.emit(this.id);
  }
  refresh(){
    ;
    this.refreshProduct.emit();
  }

  goToProductDetail(): void{
    sessionStorage.setItem('chips', JSON.stringify(this.params));
    this.router.navigate(['product/', this.slug ? this.slug : this.id]);
  }
  open(content): void {
    ;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });
  }

  addCart(content, action): void {
    ;
    let variantesSelect = '';
    if (this.variantes && this.variantes.length) {
      this.variantes.map((v: Variante) => {
        if (v.selected) {
          variantesSelect = v.value;
        }
      });
      if (variantesSelect){
        this.setCart(content, variantesSelect ,action);
      }else{
        this.toastService.warning(`${COMMON.producto.detalle.mensaje_variante}`, `${COMMON.producto.detalle.titulo_mensaje_variante}`);
      }
    }else{
      this.setCart(content, variantesSelect ,action);
    }
  }

  setCart(content, variantesSelect,action ){
    let allCart: Array<Cart> = [];
    allCart = this.storageService.getCartStorage();
    // chequear variantes
    const productExists = allCart.filter((cart: Cart) => Number(cart.id) === this.product.id && cart.variante === variantesSelect );

    if (productExists.length === 0) {
      allCart.push({
        id: this.product.id.toString(),
        tipo: this.product.tipoProducto,
        marca: this.product.marca,
        imagen: this.product.imagen,
        variante: variantesSelect.trim(),
        cantidad: this.quantity,
        producto: this.product,
      });
      this.storageService.setCarritoStorage(allCart);
      this.open(content);
      // this.toastService.success(`${COMMON.producto.mensaje_gurdado_exitoso}`, `${COMMON.producto.titulo_guardado_exitoso}`);
      this.variantes.map((v: Variante) => {
        v.selected = false;
      });
      this.quantity = 1;
      this.storageService.newInfoCart();
    } else {
      // this.closeModal();
      this.open(content);
    }
  }
  closeModal(): void {
    this.modalService.dismissAll();
  }
  redirection(action){
    this.closeModal();
    this.router.navigate([action]);
  }

  getPrecioDesde(): number {
    let precio = this.precioDesde;
    if(this.vendedorId) {
      const vend = this.vendedores.find(x => x.id === this.vendedorId);
      if(vend) {
        precio = vend.oferta ? vend.oferta : vend.precio;
      }
    }
    return precio;
  }
}
