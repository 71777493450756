import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Categoria, Marca, TipoProducto} from '../../services/product.service';
import {Specialty} from '../../services/especialidad.service';
import {COMMON} from '../utils/common-texts.utils';
import {ActivatedRoute, Route, UrlSegment} from "@angular/router";
import {RecomendarProductoComponent} from "../../product/recomendar-producto/recomendar-producto.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {

  @Input() categorias: Array<Categoria>;
  @Input() especialidades: Array<Specialty>;
  @Input() chips: Array<any>;
  @Input() marcas: Array<Marca>;
  @Input() tipoProductos: Array<TipoProducto>;
  @Output() search: EventEmitter<boolean> = new EventEmitter();
  @Output() searchFilter: EventEmitter<any> = new EventEmitter();
  @Output() deleteChip: EventEmitter<any> = new EventEmitter();
  private debounceTimer?: NodeJS.Timeout;
  idCategory = 0;
  idSpecialty = 0;
  idMark = 0;

  text: '';
  nombre: '';

  oferta = COMMON.filtro.oferta;

  filtros_seleccionados = COMMON.filtro.filtros_seleccionados;
  seleccionado = COMMON.filtro.seleccionado;
  seleccionada = COMMON.filtro.seleccionada;
  categoria = COMMON.filtro.categorias;
  marca = COMMON.filtro.marcas;
  tipo_producto = COMMON.filtro.tipo_producto;

  showMyProducts = false;
  showingMyProducts = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.showMyProducts = !!this.activatedRoute.snapshot.url.find(x => x.path === 'products-register');
  }

  deleteSelected(chipSelected): void {
    this.deleteChip.emit(chipSelected);
  }

  buscar(ev: any): void {
    if (ev) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.selected('texto', ev);
      }, 1000);
    } else {
      this.chips = this.chips.filter(chip => chip.keyType !== 'texto');
      this.searchFilter.emit(this.chips);
    }
    // if (value === undefined || (value.length < 2)) { return value; }
    // setTimeout(() => {
    //   this.selected('texto', value);
    // }, 900);
  }

  selected(ev, value): void {
    if (ev === 'categoria') {
      this.chips = this.chips.filter(chip => chip.keyType === 'categoria' && chip.id !== value.id);
      this.chips.map((chip) => {
        chip.filter = false;
      });
      this.chips.push({
        id: value.id,
        tipo: 'Categoria',
        keyType: 'categoria',
        nombre: value.nombre,
        filter: true,
      });
    }

    if (ev === 'tipoProducto') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'tipoProducto');
      this.chips.push({
        id: value.id,
        tipo: 'Tipo producto',
        keyType: 'tipoProducto',
        nombre: value.nombre,
      });
    }

    if (ev === 'marca') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'marca');
      this.chips.push({
        id: value.id,
        tipo: 'Marca',
        keyType: 'marca',
        nombre: value.nombre,
      });
    }

    if (ev === 'especialidad') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'especialidad');
      this.chips.push({
        id: value.id,
        tipo: 'Especialidad',
        keyType: 'especialidad',
        nombre: value.nombre,
      });
    }

    if (ev === 'texto') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'texto');
      this.chips.push({
        id: 0,
        tipo: 'Texto',
        keyType: 'texto',
        nombre: value,
      });
    }

    if (ev === 'precio') {
      this.chips = this.chips.filter(chip => chip.keyType !== 'precio');
      this.chips.push({
        id: value.id,
        tipo: 'precio',
        keyType: 'precio',
        nombre: value.nombre,
      });
    }
    this.searchFilter.emit(this.chips);
  }

  ngOnChanges(): void {
    this.showingMyProducts = !! this.chips.find(chip => chip.keyType === 'precio');
  }

  recomendarProducto(): void {
    const modalRef = this.modalService.open(RecomendarProductoComponent, {size: 'xl'});
  }

}
