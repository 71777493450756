import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sub-course',
  templateUrl: './sub-course.component.html',
  styleUrls: ['./sub-course.component.scss']
})
export class SubCourseComponent implements OnInit, OnChanges {

  @Input() items;
  @Input() idCourse;
  @Input() edit = false;
  defaultNoImage = environment.defaultNoImage;
  // son los grupos de las fechas de intracongreso
  groups: string[] = [];

  constructor(private router: Router) {
  }

  ngOnInit(): void {

  }

  redirection(item): void {
    if (this.edit) {
      if (this.idCourse) {
        this.router.navigate([`form/my-courses/${this.idCourse}/${item.id}`]);
      } else {
        this.router.navigate([`form/courses/${item.id}`]);
      }
    } else {
      this.router.navigate([`/courses/${item.slug}`]);
    }
  }

  ngOnChanges(): void {
    this.groups = (this.items || []).map(x => x.inicio)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

}
