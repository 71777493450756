import { Injectable } from '@angular/core';
import { apiBaseUrl } from '../../environments/environment';
import { ApiService } from '../core/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Specialty {
  id: number;
  nombre: string;
  imagen: string;
  visible?: boolean;
  selected?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class EspecialidadService {

  constructor() { }
}
