import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductService} from '../../services/product.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer-seller',
  templateUrl: './footer-seller.component.html',
  styleUrls: ['./footer-seller.component.scss']
})
export class FooterSellerComponent implements OnInit {
  @Input() vendedores = null;
  @Input() css = '';
  @Input() redirect = true;


  constructor(
    private productService: ProductService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getSeller();
  }

  getSeller(): void {
    if (!this.vendedores) {
      this.productService.getSellers().subscribe((response: Array<any>) => {
        this.vendedores = response;
      }, (errorResponse: HttpErrorResponse) => {
        console.error(errorResponse);
      });
    }
  }

  search(vendedor): void {
    if(this.redirect) {
      this.router.navigate(['/' + vendedor.buscador], {queryParams: {vendedor: vendedor.id, nombre: vendedor.razonSocial}});
    }
  }

}
