import { Injectable } from '@angular/core';
import { apiBaseUrl } from 'src/environments/environment';
import { ApiService } from '../core/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Specialty } from '../services/especialidad.service';
import { Province } from '../form/form.service';

export interface Usuario {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  telefono: string;
  comprador?: true,
  vendedor?: boolean;
  matricula?: string;
  especialidad?: [];
  cp?: number;
  idProvinciaCliente?: number;
  localidadCliente?: string;
  direccionCliente?: string;
  idProvinciaVendedor?: number;
  localidadVendedor?: string;
  direccionVendedor?: string;
  telefonoVendedor?: string;
  particular?: number;
  cuit?: string;
  razonSocial?: string;
  logo?: string;
  montoEnvioGratis?: string;
  opcionesPago?: string;
  opcionesEnvio?: string;
  comisionVenta?: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  url: string;
  userPath = '/usuariosapp';
  userPathLogo = '/imagenes';

  constructor(private apiService: ApiService) {
    this.url = apiBaseUrl;
  }

  getUserProfile(): Observable<Array<Usuario>> {
    return this.apiService.post(this.url, `${this.userPath}/user`, {})
      .pipe(
        map((response) => {
          return response.payload;
        }));
  }

  getSpecialties(): Observable<Array<Specialty>>  {
    return this.apiService.get(this.url, `${this.userPath}/especialidades`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }
  getProvinces(): Observable<Array<Province>> {
    return this.apiService.get(this.url, `${this.userPath}/provincias`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  registerAsBuyerSeller(userData: any): Observable<Array<Usuario>> {
    return this.apiService.post(this.url, `${this.userPath}/basica`, userData);
  }

  updateLogo(logo: any, tipo: string): Observable<any>{
    const url = tipo === 'logoUsuario' ? '/perfil' : '/logo';
    return this.apiService.post(this.url, `${this.userPath}${this.userPathLogo}${url}`, logo)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getHistorial(type: string): Observable<any> {
    return this.apiService.get(this.url, '/odontologo/historial/' + type);
  }

  getArbolCategorias(): Observable<any> {
    return this.apiService.get(this.url, '/productos/arbol-categorias');
  }


}
