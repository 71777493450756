import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
export const VALIDATION_MESSAGES_TRASLATE = {
  name: 'nombre',
  fullName: 'apellido',
  email: 'email',
  phoneNumber: 'telefono',
  sellerPhone: 'telefono del vendedor',
  customerAddress: 'direccion',
  specialty: 'especialidad',
  particular: 'particular',
  customerProvince: 'provincia',
  customerPostalCode: 'codigo postal',
  customerLocation: 'ciudad',
  tuition: 'matricula',
  idProvinceSeller: 'provincia del vendedor',
  sellerLocation: 'ciudad del vendedor',
  sellerAddress: 'direccion del vendedor',
  sellerZipCode: 'codigo postal del vendedor',
  shippingCost: 'costo de envio',
  paymentOptions: 'opcion de pago',
  shippingOptions: 'opcion de envio',
  businessName: 'cuit',
  razonSocial: 'razon social',
};
export const VALIDATION_MESSAGES_PROFILE = {
  name: {
    required: 'EL nombre es campo requerido.',
  },
  fullName: {
    required: 'EL apellido es campo requerido.',
  },
  email: {
    required: 'EL email es campo requerido.',
    pattern: 'Please provide valid Email ID'
  },
  phoneNumber: {
    required: 'EL telefono es campo requerido.',
  },
  sellerPhone: {
    required: 'EL telefono es campo requerido.',
  },
  customerAddress: {
    required: 'La direccion es campo requerido.',
  },
  specialty: {
    required: 'La especialidad es campo requerido.',
  },
  particular: {
    required: 'Este campo es requerido.',
  },
  customerProvince: {
    required: 'La provincia es campo requerido.',
  },
  customerPostalCode: {
    required: 'EL codigo postal es campo requerido.',
  },
  customerLocation: {
    required: 'La ciudad es campo requerido.',
  },
  tuition: {
    required: 'La matricula es campo requerido.',
  },
  idProvinceSeller: {
    required: 'La provincia es campo requerido.',
  },
  sellerLocation: {
    required: 'La ciudad es campo requerido.',
  },
  sellerAddress: {
    required: 'La direccion es campo requerido.',
  },
  sellerZipCode: {
    required: 'El codigo postal es campo requerido.',
  },
  shippingCost: {
    required: 'El costo de envio es campo requerido.',
  },
  shippingOptions: {
    required: 'Las opciones de envio son campo requerido.',
  },
  paymentOptions: {
    required: 'La opcion de pago es campo requerido.',
  },
  businessName: {
    required: 'El cuit es campo requerido.',
  },
  razonSocial: {
    required: 'El Nombre Público es campo requerido.',
  }
};

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public regex = {
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
  };

  getValidationErrors(group: FormGroup, validationMessages: Object): any {
    let formErrors = {};

    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      formErrors[key] = '';
      if (abstractControl && !abstractControl.valid &&
        (abstractControl.touched || abstractControl.dirty)) {

        const messages = validationMessages[key];

        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            formErrors[key] += messages[errorKey] + ' ';
          }
        }
      }

      if (abstractControl instanceof FormGroup) {
        const groupError = this.getValidationErrors(abstractControl, validationMessages);
        formErrors = { ...formErrors, ...groupError };
      }

    });
    return formErrors;
  }
}
