<div class="modal-content card gold-border bg-dark">
  <div class="modal-header " style="display: block">
    <h5 class="modal-title">Recomendar un nuevo Link {{nombreCategoria ? ' a ' + nombreCategoria : ''}}</h5>
    <p class="m-0">El mismo será verificado por nuestros administradores.</p>
  </div>
  <div class="modal-body">
    <form [formGroup]="linkForm">
      <div class="row">
        <div class="col-lg-12">
          <div class=" pd-sides">
            <div class="row g-3">
<!--              <div class="col-md-4">-->
<!--                <label  class="form-label">Nombre de usuario </label>-->
<!--                <div class="input-group">-->
<!--                  <input type="text" class="form-control" formControlName="username" disabled />-->
<!--                </div>-->
<!--              </div>-->

              <div class="col-md-4">
                <label  class="form-label">Titulo</label>
                <div class="input-group">
                  <input type="text" formControlName="title" class="form-control"  placeholder="Ingresé un titulo">
                </div>
              </div>
              <div class="col-md-4">
                <label  class="form-label">Link</label>
                <div class=" input-group">
                  <input type="text" class="form-control" formControlName="link" placeholder="Ingresé una Link" >
                </div>
              </div>
              <div class="col-md-2">
                <label  class="form-label">Idioma</label>
                <div class="input-group">
                  <input type="text"
                         formControlName="idiom"
                         class="form-control"  placeholder="Ingresé un idioma">
                </div>
              </div>
              <div class="col-md-8">
                <div class="col-md-12">
                  <label  class="form-label">Descripcion</label>
                  <div class="input-group">
                    <input type="text"
                           formControlName="description"
                           class="form-control" placeholder="Ingresé una descripcion">
                  </div>
                </div>
                <div class="row mt-md-3">
                  <div class="col-md-8">
                    <label  class="form-label">Especialidades</label>
                    <ng-multiselect-dropdown
                      *ngIf="specialties && specialties.length"
                      [placeholder]="'Elija las especialidades'"
                      formControlName="specialty"
                      [settings]="multipleSettings"
                      [data]="specialties"

                    >
                    </ng-multiselect-dropdown>
                  </div>


<!--                  <div class="col-md-4">-->
<!--                    <label  class="form-label">¿Expira?</label>-->
<!--                    <div class=" input-group">-->
<!--                      <input type="date" class="form-control" id="inputFromDate" formControlName="expiration" >-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label">¿Expira?</label>

                      <input class="form-control" placeholder="dd-mm-yyyy" formControlName="expiration"
                             (focus)="d.open()" (dateSelect)="d.close();"
                             id="courseStart"
                             ngbDatepicker #d="ngbDatepicker">
                      <div  class="invalid-feedback">Ingrese fecha</div>
                    </div>
                  </div>

                  <div class="col-md-6" hidden>
                    <label  class="form-label">Categorias</label>
                    <ng-multiselect-dropdown
                      *ngIf="specialties && specialties.length"
                      [placeholder]="'Elija una categoria'"
                      formControlName="category"
                      [(ngModel)]="selected"
                      [settings]="singleSettings"
                      [data]="categories"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label  class="form-label">Imagen</label>
                <div class="rounded class-car text-center">
                  <div class="radius-15 button-profile" >
                    <img *ngIf="!imagen" id="file-preview" [src]="defaultImage" class="rounded-circle shadow" alt="">
                    <img *ngIf="imagen" [src]="domSanitizer.bypassSecurityTrustUrl(imagen)"
                         class="rounded-circle shadow" alt="">
                    <div class="d-grid button-file">
                      <input #file type="file" accept="image/*" formControlName="images"
                             (change)="changeListener($event, 'logoUsuario')">
                      <a #upload (click)="file.click()" class="btn btn-outline-primary radius-15">Actualizar imagen</a>
                    </div>
                  </div>
                </div>
              </div>

           </div>
          </div>
        </div>
      </div><!--end row-->
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()" >Cancelar</button>
    <button type="button" class="btn btn-outline-primary" (click)="send()" [disabled]="!linkForm.valid">Recomendar</button>
  </div>
</div>
