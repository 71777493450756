<div class="accordion" id="sidebarAccordion"  *ngIf="marcas && marcas.length">
  <!-- Marcas -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="marcasHeading">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#marcasCollapse" aria-expanded="true" aria-controls="marcasCollapse">
        Marcas
      </button>
    </h2>
    <div id="marcasCollapse" class="accordion-collapse collapse show" aria-labelledby="marcasHeading" data-bs-parent="#sidebarAccordion">
      <div class="accordion-body">
        <ul class="list-unstyled">
          <li *ngFor="let item of marcas">
            <input class="form-check-input check-size" type="checkbox" value="{{item.id}}"/>
            <span class="ms-1 font-12">{{item.nombre}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Especialidades -->
  <div class="accordion-item" *ngIf="especialidades && especialidades.length">
    <h2 class="accordion-header" id="especialidadesHeading">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#especialidadesCollapse" aria-expanded="true" aria-controls="especialidadesCollapse">
        Especialidades
      </button>
    </h2>
    <div id="especialidadesCollapse" class="accordion-collapse collapse show" aria-labelledby="especialidadesHeading" data-bs-parent="#sidebarAccordion">
      <div class="accordion-body">
        <ul class="list-unstyled">
          <li *ngFor="let item of especialidades">
            <input class="form-check-input check-size" type="checkbox" value="{{item.id}}"/>
            <span class="ms-1 font-12">{{item.nombre}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Categorias -->
  <div class="accordion-item" *ngIf="categorias && categorias.length">
    <h2 class="accordion-header" id="categoriasHeading">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#categoriasCollapse" aria-expanded="true" aria-controls="categoriasCollapse">
        Categorías
      </button>
    </h2>
    <div id="categoriasCollapse" class="accordion-collapse collapse show" aria-labelledby="categoriasHeading" data-bs-parent="#sidebarAccordion">
      <div class="accordion-body">
        <ul class="list-unstyled">
          <li *ngFor="let item of categorias">
            <input class="form-check-input check-size" type="checkbox" value="{{item.id}}"/>
            <span class="ms-1 font-12">{{item.nombre}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
