import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  @Input() customCss: string
  @Input() position: string
  width:any
  constructor() { }

  ngOnInit(): void {
    console.log(this.position);


  }

}
