import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() backPressed: EventEmitter<void> = new EventEmitter();
  @Output() removePressed: EventEmitter<void> = new EventEmitter();
  @Output() addPressed: EventEmitter<void> = new EventEmitter();
  @Input() title: string;
  @Input() iconTitle: string;
  @Input() description: string;
  @Input() showButtonBack: boolean;
  @Input() showButtonRemove: boolean;
  @Input() showButton: boolean;
  @Input() action: string;
  @Input() iconButton: string;
  @Input() icon: string;
  constructor() { }

  ngOnInit(): void {
  }

  addCourse(): void {
    this.addPressed.emit();
  }

  backCourse(): void {
    this.backPressed.emit();
  }

  removeCart(): void {
    this.removePressed.emit();
  }

}
