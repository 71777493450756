import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cta-whatsapp',
  templateUrl: './cta-whatsapp.component.html',
  styleUrls: ['./cta-whatsapp.component.scss']
})
export class CtaWhatsappComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() whatsapp: string;


  constructor() { }

  ngOnInit(): void {
  }

}
