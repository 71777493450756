import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DndDropEvent} from 'ngx-drag-drop';
import {COMMON} from '../../shared/utils/common-texts.utils';
import {HttpErrorResponse} from '@angular/common/http';
import {CurseService} from '../../services/curse.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-course-sort-images',
  templateUrl: './course-sort-images.component.html',
  styleUrls: ['./course-sort-images.component.scss']
})
export class CourseSortImagesComponent implements OnInit, OnChanges {

  @Input() id: number;
  course: any;

  draggable: any;
  items: any[];

  constructor(
    public activeModal: NgbActiveModal,
    private curseService: CurseService,
    private toastService: ToastrService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.course = undefined;
    if (this.id) {
      this.loadCourse();
    }
  }

  ngOnInit(): void {
    if (this.id) {
      this.loadCourse();
    }
  }


  loadCourse(): void {
    this.curseService.getCourseById(this.id?.toString()).subscribe(
      (response: any) => {
        if (response && typeof response === 'object') {
          this.course = response.curso;
          this.loadDnD();
        }
      }, (error: HttpErrorResponse) => {
        this.toastService.error(`${COMMON.curso.mensaje_curso_no_encontrado}`, `${COMMON.curso.titulo_curso_no_encontrado}`);
      }
    );
  }

  loadDnD(): void {
    this.items = this.course.imagenes.map(elem => {
      return {
        id: elem.id,
        content: {
          id: elem.id,
          nombre: elem.nombre,
          realpath: elem.realpath,
        },
        effectAllowed: 'move',
        disable: false,
        handle: false,
      };
    });
  }

  onDragover(event: DragEvent): void {
    // console.log('dragover', JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, list: any[]): void {
    let index = event.index;
    console.log(event);
    if (typeof index === 'undefined') {
      index = list.length;
    }

    list.splice(index, 0, event.data);
  }

  onDragEnd(event: DragEvent, imagenes: any[]): void {
  }

  save(): void {
    const items = [];
    this.items.forEach((item, index) => {
      items.push(Object.assign({}, {orden: index}, item.content));
    });
    this.curseService.sortImages(this.course.id, items).subscribe((resp) => {
        if (resp.status?.value === 200) {
          this.toastService.success(`El curso guardado con exito.`, 'Curso guardado con exito');
        } else {
          console.error(resp);
          this.toastService.error(COMMON.errores.general, 'Error');
        }
      }, (error: HttpErrorResponse) => {
        this.toastService.error(COMMON.errores.general, 'Error');
      },
    );
  }

  onDraggableMoved(event: DragEvent, list: any[], item: any): void {
    const index = list.indexOf(item);
    list.splice(index, 1);
  }

}
