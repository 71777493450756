import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormGroup } from '@angular/forms';
import {AuthService} from "../../auth/auth.service";
import {Usuario} from "../../services/auth.service";

@Component({
  selector: 'app-filter-google-maps',
  templateUrl: './filter-google-maps.component.html',
  styleUrls: ['./filter-google-maps.component.scss']
})
export class FilterGoogleMapsComponent implements OnInit {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  @Output() searchFilter: EventEmitter<any> = new EventEmitter();
  longitude: number;
  latitude: number;

  @Input() courseForm: FormGroup;
  @Input() showMap: boolean;
  @Input() helper = false;
  @Input() eventEnabled: boolean;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() inputLabel: string;

  zoom: any;
  dir: string;
  options = {
    types: [],
    language: 'es',
    componentRestrictions: { country: 'ar' }
  };

  currentUser?: Usuario;

  constructor(
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.loadStorage('userProfile');
  }

  ngOnInit(): void {
    this.setCurrentLocation();
  }

  handleAddressChange(address: Address): void {
    this.longitude = address.geometry.location.lng();
    this.latitude = address.geometry.location.lat();
    console.log(address);
    const name = address.name ? `${address.name}, ${address.formatted_address}` : address.formatted_address;
    this.courseForm.get('ubicacionDir').setValue(name);
    this.courseForm.get('ciudad').setValue(name);
    this.courseForm.get('longitude').setValue(this.longitude);
    this.courseForm.get('latitude').setValue(this.latitude);
    console.log(address, this.courseForm.value);
    //eventEnabled
    if (this.eventEnabled){
      this.searchFilter.emit();
    }
  }

  setCurrentLocation(): void{

    if ('geolocation' in navigator){
      if (this.courseForm.value.latitude && this.courseForm.value.longitude){
        this.longitude = this.courseForm.value.longitude;
        this.latitude = this.courseForm.value.latitude;
        this.dir = this.courseForm.value.ciudad;
      }else{
        navigator.geolocation.getCurrentPosition((position) => {
          this.longitude = position.coords.longitude;
          this.latitude = position.coords.latitude;
          this.dir = '';
          this.courseForm.get('longitude').setValue(this.longitude);
          this.courseForm.get('latitude').setValue(this.latitude);

        } );
      }
      this.zoom = 16;

    }
  }

}
