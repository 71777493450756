<div class="alert border-0 border-start mr-l-10 mr-r-10 border-5 border-warning  fade show py-2" *ngIf="showMessageCourse">
  <div class="d-flex align-items-center" >
    <div class="font-30 text-warning"><i class='bx bx-info-circle'></i>
    </div>
    <div class="ms-3">
      <h6 class="mb-0 text-warning">Los cursos no aprobados no se mostrarán al publico en general.</h6>
      <div> Solo los podrás ver vos y los administradores del sistema.</div>
    </div>
  </div>
</div>
<div class="alert border-0 border-start mr-l-10 mr-r-10 border-5 border-warning  fade show py-2" *ngIf="showAlertSeller">
  <div class="d-flex align-items-center" >
    <div class="font-30 text-warning"><i class='bx bx-info-circle'></i>
    </div>
    <div class="ms-3 mt-3">
      <h6 class="mb-0 text-warning">Tu cuenta como vendedor aún no fue aprobada o fue suspendida.</h6>
      <p>Pronto recibirás novedades de parte de los administradores de Todo-Odonto y se visualizarán tus productos y cursos</p>
    </div>
  </div>
</div>
