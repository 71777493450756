import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  shoppingCartPath = '/carrito/ver';
  orderCoursePath = '/compra/guardarordencurso';

  constructor(private apiService: ApiService) { }

 commercialSeller(carrito): Observable<any> {
    return this.apiService.post(apiBaseUrl, this.shoppingCartPath, {carrito} )
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  orderCourse(carrito): Observable<any> {
    return this.apiService.post(apiBaseUrl, this.orderCoursePath, carrito )
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }


  saveCart(seller: number, cart: any): Observable<any> {
    return this.apiService.post(apiBaseUrl, '/wv/carrito', {cart, seller});
  }

}
