<div class="pb-4">
  <app-banner-with-blinking [type]="'VENDEPRODUCTOS_SUPERIOR'"></app-banner-with-blinking>
</div>

<div class="row pd-l-t">
  <h1 [innerText]="tabs[currentTab]"></h1>
</div>

<app-loading *ngIf="spinner"></app-loading>

<ng-container *ngIf="!spinner && ready">
  <app-alert *ngIf="!info.vendedor.aprobado" [showAlertSeller]=!info.vendedor.aprobado></app-alert>

  <app-odonto-tabs [tabs]="tabs" [current]="currentTab" (onTabChange)="setTab($event)"></app-odonto-tabs>

  <app-vp-marcas
    #MarcasRef
    *ngIf="marcas && info && currentTab == 0" [marcas]="marcas"
    [info]="info"
    (destroy)="vpMarcas($event)"></app-vp-marcas>

  <app-vp-productos
    #ProductosRef
    *ngIf="marcas && info && currentTab == 1" [marcas]="marcas"
    [info]="info"></app-vp-productos>

  <app-vp-precios
    #PreciosRef
    *ngIf="marcas && info && currentTab == 2" [marcas]="marcas"
    [cotizaciones]="cotizaciones" [tipoCotizaciones]="tipoCotizaciones"
    [info]="info"></app-vp-precios>


  <app-odonto-confirm
    *ngIf="modalChanges"
    (destroy)="closeChangesTab($event)"
    title="Tenés cambios sin guardar"
    description="¿Queres guardar y continuar?"
    cancel="CONTINUAR SIN GUARDAR"
    submit="GUARDAR Y CONTINUAR"
  ></app-odonto-confirm>

</ng-container>

<div class="pt-4">
  <app-banner-with-blinking [type]="'VENDEPRODUCTOS_INFERIOR'"></app-banner-with-blinking>
</div>
