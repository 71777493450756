import {Component, OnInit, AfterContentChecked, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';

// Services
import {FormService} from '../form.service';
import {Specialty} from 'src/app/services/especialidad.service';
import {SharedService} from 'src/app/shared/shared.service';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AvalesModalidades} from './interface-add-course';
import {COMMON} from '../../shared/utils/common-texts.utils';
import {ToastrService} from 'ngx-toastr';
import {Curso, MisCursos} from '../form-my-courses/interface-my-courses';
import {environment} from '../../../environments/environment';
import {DATE_FORMAT, DATE_FORMAT_COURSE, DATE_PUBLIC_FORMAT, formatDate} from '../../shared/utils/date.utils';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {HttpErrorResponse} from '@angular/common/http';
import {CurseService} from '../../services/curse.service';
import {Product, ProductService, Response} from '../../services/product.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CourseSortImagesComponent} from "../../courses/course-sort-images/course-sort-images.component";
import {isNumeric} from "rxjs/internal-compatibility";
import {Usuario} from "../../services/auth.service";
import {AuthService} from "../../auth/auth.service";

export interface Imagenes {
  id?: number;
  nombre?: string;
  realpath?: string;
}

@Component({
  selector: 'app-add-course',
  templateUrl: './form-add-course.component.html',
  styleUrls: ['./form-add-course.component.scss']
})
export class FormAddCourseComponent implements OnInit {
  private debounceTimer?: NodeJS.Timeout;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsAval: IDropdownSettings = {};
  dropdownSettingsProduct: IDropdownSettings = {};
  fileList: Array<File> = [];
  fileListEdit: Array<Imagenes> = [];
  products: Array<Product>;
  selectedProduct: Array<any>;
  physicalAddress: string;
  placeholderText: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingresar texto...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold',
        'italic',
        'insertImage',
        'link',
        'unlink',
        'insertImage',
        'insertVideo']
    ]
  };

  defaultNoImage = environment.defaultNoImage;
  courseForm: FormGroup;
  ejecutoScript = false;
  subscriptions: Subscription[];
  pruebaArrayB64: Array<any>;

  specialties: Specialty[];
  avales: any[];
  modalidades: any[];

  deleteImages: any[];
  url = URL;
  imageReady = false;

  base64Image: string | ArrayBuffer;
  id: number;
  idPadre: string;
  idCursoIntracongresos: number;
  addCursoIntracongresos: boolean;
  precurso: Curso;
  defaultLogo: string;
  dir: string;
  modId: string;
  showComponent: boolean;
  showButtonCourses: boolean;
  showImages: boolean;
  showValueInput: boolean;
  requireds = [
    {key: 'nombre'},
    {key: 'descripcion', text: 'Descripción'},
    {key: 'inicio'},
    {key: 'horaInicio', text: 'Hora de Inicio'},
    {key: 'especialidad', array: true},
    {key: 'cargaHoraria', text: 'Carga Horaria'},
    {key: 'precio'},
    {key: 'dictantes'},
    {key: 'institucion'},
  ];

  currentUser?: Usuario;

  constructor(
    private sharedService: SharedService, private formService: FormService,
    private fb: FormBuilder, private productService: ProductService,
    private curseService: CurseService, private sanitizer: DomSanitizer,
    private domSanitizer: DomSanitizer, private toastService: ToastrService,
    private modalService: NgbModal,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute, private router: Router) {

    this.currentUser = this.authService.loadStorage('userProfile');
    this.subscriptions = [];
    this.createForm();
    this.base64Image = '';
    this.activatedRoute.queryParams.subscribe(params => {
      this.addCursoIntracongresos = Boolean(params.addCursoIntracongresos);
      if (params.modId === '2') {
        // solo los input que quedan cargad del padre
        this.resetForm();
        if (this.modalidades && this.modalidades.length) {
          this.modalidades = this.modalidades.filter(item => item.id !== Number(params.modId));
        }
      } else {
        this.showValueInput = false;
      }
    });
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = (Number(params.id) >= 0) ? params.id : null;
      this.idCursoIntracongresos = params.idSubcurso;
      this.showComponent = false;
    });

  }

  openSortImagesModal(id: number): void {
    const modalRef = this.modalService.open(CourseSortImagesComponent, {size: 'xl'});
    modalRef.componentInstance.id = id;
  }


  createForm(): void {
    this.courseForm = this.fb.group({
      id: [],
      aprobado: [],
      activo: [true],
      nombre: ['', [Validators.required, Validators.minLength(2)]],
      descripcion: ['', [Validators.required, Validators.minLength(2)]],
      inicio: ['', Validators.required],
      horaInicio: ['', Validators.required],
      institucion: [this.currentUser.razonSocial, Validators.required],
      ciudad: [''],
      ubicacionDir: [''],
      claves: [''],
      imagen: [''],
      dictantes: ['', Validators.required],
      especialidad: [{value: '', disabled: false}, Validators.required],
      aval: [''],
      relatedProducts: [''],
      modalidad: [{value: '', disabled: false}],
      cargaHoraria: ['', Validators.required],
      precio: ['', [Validators.required, Validators.pattern('^[0-9]*')]],
      precioComentario: [''],
      videos: [''],
      longitude: ['', Validators.required],
      latitude: ['', Validators.required],
      physicalAddress: [''],
    });
    this.courseForm.get('physicalAddress').setValue(false);
    this.changeAddress();
  }

  getCourse(): void {
    this.fileListEdit = [];
    this.fileList = [];
    const id = this.idCursoIntracongresos && this.id ? this.idCursoIntracongresos : this.id;
    this.curseService.getCourseById(String(id)).subscribe((resp: any) => {
      this.precurso = (resp.curso) ? resp.curso : null;

      if (this.precurso) {
        const inicio = this.precurso.inicio?.replace('/', '-').replace('/', '-');
        this.courseForm.get('id').setValue(this.precurso.id || null);
        this.courseForm.get('activo').setValue(this.precurso.activo || true);
        this.courseForm.get('nombre').setValue(this.precurso.nombre || '');
        this.courseForm.get('descripcion').setValue(this.precurso.descripcion || '');
        this.courseForm.get('inicio').setValue(inicio);
        this.courseForm.get('institucion').setValue(this.precurso.institucion || '');
        this.courseForm.get('cargaHoraria').setValue(this.precurso.cargaHoraria || '');
        this.courseForm.get('horaInicio').setValue(this.precurso.hora || '');
        this.courseForm.get('claves').setValue(this.precurso.claves || '');
        if (this.precurso.ubicacion.ubicacionLng && this.precurso.ubicacion.ubicacionLat) {
          this.courseForm.get('ciudad').setValue(this.precurso.ciudad || '');

          this.courseForm.get('longitude').setValue(this.precurso.ubicacion.ubicacionLng);
          this.courseForm.get('latitude').setValue(this.precurso.ubicacion.ubicacionLat);
          this.courseForm.get('physicalAddress').setValue(true);
        }
        this.dir = this.precurso.ciudad ? this.precurso.ciudad : '';
        this.courseForm.get('dictantes').setValue(this.precurso.dictantes || '');

        if (this.precurso.cursoPadre) {
          this.idPadre = this.precurso.cursoPadre.id ? String(this.precurso.cursoPadre.id) : null;
        }
        if (this.precurso.aval) {
          this.courseForm.get('aval').setValue([this.precurso.aval], {
            onlySelf: true
          });
        }

        if (this.precurso.modalidad) {

          this.showButtonCourses = this.precurso.modalidad.id === 2 ? true : false;
          this.courseForm.get('modalidad').setValue([this.precurso.modalidad], {
            onlySelf: true
          });
        }
        if (this.precurso.productos) {
          this.selectedProduct = [];
          this.precurso.productos.map((catSuc) => {
            this.selectedProduct.push({
              id: catSuc.id,
              nombre: catSuc.nombre
            });
          });

          this.courseForm.get('relatedProducts').setValue(this.selectedProduct);
        }

        if (this.precurso.especialidad) {
          this.courseForm.get('especialidad').setValue(this.precurso.especialidad, {
            onlySelf: true
          });
        }

        this.courseForm.get('cargaHoraria').setValue(this.precurso.cargaHoraria || '');
        this.courseForm.get('precio').setValue(this.precurso.precio || 0);
        this.courseForm.get('precioComentario').setValue(this.precurso.precioComentario || '');
        this.courseForm.get('aprobado').setValue(this.precurso.aprobado || false);

        this.defaultLogo = this.precurso.realpath !== null ? this.precurso.realpath : environment.defaultAvatar;


        const videos = (this.precurso.videos || '').split(',').map(x => x.trim()).filter(x => x != '');
        this.courseForm.get('videos').setValue(videos);
        console.log('videos', this.precurso.videos, videos);

        this.courseForm.updateValueAndValidity();
        this.showComponent = true;
        if (this.precurso.imagenes) {
          this.precurso.imagenes.map((img) => {
            this.fileListEdit.push(img);
          });
        }
      }
    });
  }

  getFormValidationErrors(): void {
    let totalErrors = 0;
    Object.keys(this.courseForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.courseForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  ngOnInit(): void {
    this.currentUser = this.authService.loadStorage('userProfile');
    this.showComponent = false;
    this.deleteImages = [];
    // const params = {
    //   paginate: true
    // };
    // this.getProduct(params);
    this.getProduct();
    this.getSpecialties();
    this.getModalidad();
    this.onFilterChange();

    if (this.id) {
      this.getCourse();
    } else {
      this.dir = '';
      this.showComponent = true;
    }
    $.getScript('./assets/plugins/Drag-And-Drop/imageuploadify.min.js');
    $.getScript('./assets/js/add-new-product-image-upload.js');
    this.defaultLogo = environment.defaultNoImage;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsProduct = {
      singleSelection: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: true,
      itemsShowLimit: 10000,
      limitSelection: 5,
      allowSearchFilter: true,
      allowRemoteDataSearch: true

    };
    this.dropdownSettingsAval = {
      singleSelection: true,
      idField: 'id',
      textField: 'nombre',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  getProduct(params?): void {
    this.products = [];
    this.productService.getProduct(params).subscribe(
      (response: Response) => {
        this.products = response.productos;
      });
  }

  onFilterChange(ev?): void {
    if (ev !== undefined) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        const params = {
          texto: ev
        };
        this.getProduct(params);
      }, 1000);
    }


  }

  getModalidad(): void {
    this.curseService.getGuaranteesModalities().subscribe((resp: AvalesModalidades) => {
      if (resp.avales.length > 0) {
        this.avales = resp.avales;
      }
      if (resp.modalidades.length > 0) {
        this.modalidades = resp.modalidades;
      }

    });
  }

  getSpecialties(): void {
    this.formService.getSpecialties().subscribe((resp: Array<Specialty>) => {
      if (resp.length > 0) {
        this.specialties = resp;
      }
    });
  }

  changeAddress(): void {
    if (this.courseForm.get('physicalAddress').value) {
      this.physicalAddress = 'Direccion de dictado del curso';
      this.placeholderText = 'Ingrese la direccion';
    } else {
      this.physicalAddress = 'Ciudad';
      this.placeholderText = 'Ingrese la ciudad';
    }
  }

  onItemSelect(item: any): void {
    if (item.id === 2) {
      this.showButtonCourses = true;
    } else {
      this.showButtonCourses = false;
    }
  }

  loadImage(e: any): void {
    this.readThis(e.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();
    this.imageReady = false;
    myReader.onloadend = (e) => {
      this.base64Image = myReader.result;
      this.imageReady = true;
    };
    myReader.readAsDataURL(file);
  }

  readThis2(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.base64Image = myReader.result;
      this.imageReady = true;
    };
  }

  /******************************************************************/
  /************************** Validaciones **************************/

  /******************************************************************/

  public nombreNoValido(): boolean {
    return this.courseForm.get('nombre').invalid && this.courseForm.get('nombre').touched;
  }

  public descripcionNoValida(): boolean {
    return this.courseForm.get('descripcion').invalid && this.courseForm.get('descripcion').touched;
  }

  public fechaNoValida(): boolean {
    return this.courseForm.get('inicio').invalid && this.courseForm.get('inicio').touched;
  }


  public institucionNoValida(): boolean {
    return this.courseForm.get('institucion').invalid && this.courseForm.get('institucion').touched;
  }

  public ciudadNoValida(): boolean {
    return this.courseForm.get('ciudad').invalid && this.courseForm.get('ciudad').touched;
  }

  public imagenNoValida(): boolean {
    return this.courseForm.get('imagen').invalid && this.courseForm.get('imagen').touched;
  }

  public dictantesNoValido(): boolean {
    return this.courseForm.get('dictantes').invalid && this.courseForm.get('dictantes').touched;
  }

  public especialidadesNoValida(): boolean {
    return this.courseForm.get('especialidad').invalid && this.courseForm.get('especialidad').touched;
  }

  public avalesNoValida(): boolean {
    return this.courseForm.get('aval').invalid && this.courseForm.get('aval').touched;
  }

  public modalidadesNoValida(): boolean {
    return this.courseForm.get('modalidad').invalid && this.courseForm.get('modalidad').touched;
  }

  public cargaHorariaNoValida(): boolean {
    return this.courseForm.get('cargaHoraria').invalid && this.courseForm.get('cargaHoraria').touched;
  }

  public precioNoValido(): boolean {
    return this.courseForm.get('precio').invalid && this.courseForm.get('precio').touched;
  }

  public precioDescNoValido(): boolean {
    return this.courseForm.get('precioDescuento').invalid && this.courseForm.get('precioDescuento').touched;
  }

  /******************************************************************/
  /******************************************************************/

  /******************************************************************/

  changeListener($event): void {
    this.readThis($event.target);
  }

  navigateBack(): void {
    this.router.navigate(['/form/my-courses']);
  }


  convertBase64(): void {
    this.pruebaArrayB64 = [];
    for (const image of this.fileList) {
      const myReader: FileReader = new FileReader();
      const file: File = image;
      myReader.onloadend = (e) => {
        const prueba = myReader.result as string;
        this.pruebaArrayB64.push(prueba);
      };
      myReader.readAsDataURL(file);
    }
  }

  async save(sortImages?: boolean): Promise<void> {
    let course: any;
    const relatedProducts = [];
    const especialidad = [];

    if (this.courseForm.value.especialidad && this.courseForm.value.especialidad.length) {
      this.courseForm.value.especialidad.map((es) => {
        especialidad.push(es.id);
      });
    }

    if (this.courseForm.value.relatedProducts && this.courseForm.value.relatedProducts.length) {
      this.courseForm.value.relatedProducts.map((es) => {
        relatedProducts.push(es.id);
      });
    }

    // ver esto que no esta andando bien
    // caso 1: sea nuevo y quiera agregar un subcurso
    // id y idPadre = '', el servicio retorna el id del idPadre y se pasa como parametro
    // caso 2: sea uno existente y quiera editar y agregar un subcurso
    // se envia id con el id del curso e idPadre ='', luego pasamos como parametro el padre
    let id: any;
    id = this.idCursoIntracongresos && (this.addCursoIntracongresos || this.idCursoIntracongresos) ? this.idCursoIntracongresos :
      this.addCursoIntracongresos ? '' : this.id;
    course = {
      idPadre: this.id && (this.addCursoIntracongresos || this.idCursoIntracongresos) ? this.id : this.idPadre ? this.idPadre : '',
      id,
      aprobado: this.courseForm.value.aprobado,
      activo: this.courseForm.value.activo,
      nombre: this.courseForm.value.nombre,
      descripcion: this.courseForm.value.descripcion,
      inicio: this.courseForm.value.inicio,
      hora: this.courseForm.value.horaInicio,
      claves: this.courseForm.value.claves,
      institucion: this.courseForm.value.institucion,
      ciudad: this.courseForm.value.ciudad,
      ubicacionDir: this.courseForm.value.ubicacionDir === '' ? this.courseForm.value.ciudad : '',
      imagenes: this.pruebaArrayB64,
      eliminadas: this.deleteImages,
      ubicacionFis: this.courseForm.value.physicalAddress,
      dictantes: this.courseForm.value.dictantes,
      especialidad,
      productos: relatedProducts,
      aval: this.courseForm.value.aval ? this.courseForm.value.aval[0].id : '',
      modalidad: this.courseForm.value.modalidad ? this.courseForm.value.modalidad[0].id : '',
      cargaHoraria: this.courseForm.value.cargaHoraria,
      precio: this.courseForm.value.precio,
      precioComentario: this.courseForm.value.precioComentario,
      ubicacionLng: this.courseForm.value.longitude,
      ubicacionLat: this.courseForm.value.latitude,
      videos: (this.courseForm.value.videos || []).map(x => x.value ? x.value : x).toString(),
    };

    this.curseService.postCourse(course).subscribe((resp) => {
        if (resp && typeof resp === 'object') {
          this.toastService.success(`El curso guardado con exito.`, 'Curso guardado con exito');

          if (sortImages && resp.id) {
            this.openSortImagesModal(resp.id);
            return;
          }

          if (this.addCursoIntracongresos && resp.id && !course.idPadre) {
            this.courseForm.reset();
            this.fileListEdit = [];
            this.dir = '';
            this.id = resp.id;
            const idMod = this.courseForm.value.modalidad[0].id === 2 ? this.courseForm.value.modalidad[0].id : '';
            this.router.navigate(['form/my-courses', resp.id], {
              queryParams: {
                addCursoIntracongresos: true,
                modId: idMod
              }
            });
          } else {
            this.router.navigate(['form/my-courses']);
          }
        } else {
          console.log(resp);
          this.toastService.error(COMMON.errores.general, 'Error');
        }
      }, (error: HttpErrorResponse) => {
        this.toastService.error(COMMON.errores.general, 'Error');
      },
    );
  }

  resetForm(): void {
    this.courseForm.get('id').setValue('');
    this.courseForm.get('aprobado').setValue('');
    this.courseForm.get('activo').setValue(true);
    this.courseForm.get('nombre').setValue('');
    this.courseForm.get('descripcion').setValue('');
    this.courseForm.get('claves').setValue('');
    this.courseForm.get('horaInicio').setValue('');
    this.courseForm.get('institucion').setValue('');
    this.courseForm.get('imagen').setValue('');
    this.courseForm.get('especialidad').setValue('');
    this.courseForm.get('relatedProducts').setValue('');
    this.courseForm.get('modalidad').setValue('');
    this.courseForm.get('cargaHoraria').setValue('');
    this.courseForm.get('precio').setValue('');
    this.courseForm.get('precioComentario').setValue('');

    // this.courseForm.get('physicalAddress').setValue(false);
    // this.changeAddress();


  }

  addSubcourse(): void {
    this.addCursoIntracongresos = true;
    if (this.id === undefined) {
      this.save();
    } else {
      const id = this.courseForm.value.modalidad[0].id === 2 ? this.courseForm.value.modalidad[0].id : '';
      // this.courseForm.reset();
      this.fileListEdit = [];
      // this.dir = '';

      this.router.navigate(['form/my-courses', this.id], {queryParams: {addCursoIntracongresos: true, modId: id}});
    }
  }

  fileChange(event): void {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; ++i) {
        const file = event.target.files[i];
        this.fileList.push(file);
      }
    }
    this.convertBase64();
  }

  remove(deleteImage): void {
    if (this.fileList && this.fileList.length) {
      this.fileList = this.fileList.filter(image => image !== deleteImage);
    }
    if (this.fileListEdit && this.fileListEdit.length) {
      this.fileListEdit = this.fileListEdit.filter(image => image !== deleteImage);
      this.deleteImages.push(deleteImage.id);
    }
  }

  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  modalDuplicar(template: any): void {
    this.modalService.open(template, {ariaLabelledBy: 'modal-basic-title'}).result
      .then(() => {})
  }

  cancelarDuplicar(): void {
    this.modalService.dismissAll();
  }

  async duplicarCurso(): Promise<void> {

    this.curseService.duplicarCurso(this.id).subscribe(async (resp) => {
      this.modalService.dismissAll();
        if (resp?.id) {
          this.toastService.success(`Duplicaste tu curso.`, '¡Listo!');
          await this.router.navigate(['form/my-courses', resp.id]);
          window.location.reload();
        } else {
          this.toastService.error(COMMON.errores.general, 'Error');
        }
      }, (error: HttpErrorResponse) => {
        this.toastService.error(COMMON.errores.general, 'Error');
      },
    );


  }

}
