import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesMainComponent } from './main/courses-main.component';
import { DetailCourseComponent } from './detail/detail-course.component';

const routes: Routes = [
  { path: '' , component: CoursesMainComponent},
  { path: ':idCourse' , component: DetailCourseComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }
